<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>添加创投机构</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="left"
               class="demo-ruleForm">
        <el-form-item label="机构名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="机构logo" prop="img">

          <el-upload
              class="avatar-uploader"
              :action="uploadImageUrl"
              :show-file-list="false"
              :on-success="uploadImageSuccess"
              :on-preview="handlePictureCardPreview">
            <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>


        </el-form-item>
        <el-form-item label="机构性质" prop="nature">
          <el-select v-model="ruleForm.nature" multiple placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="偏好投资轮次" prop="round">
          <el-select v-model="ruleForm.round" multiple placeholder="请选择">
            <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="成立时间" prop="establish_at">
          <el-date-picker
              v-model="ruleForm.establish_at"
              type="year"
              placeholder="选择成立时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="办公地址" prop="address">
          <el-input v-model="ruleForm.address"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input v-model="ruleForm.introduction" type="textarea" :rows="6"></el-input>
        </el-form-item>
        <el-form-item label="投资行业" prop="investment">
          <el-input v-model="ruleForm.investment" type="textarea" :rows="6"></el-input>
        </el-form-item>
        <el-form-item label="投资偏好" prop="preference">
          <el-input v-model="ruleForm.preference" type="textarea" :rows="6"></el-input>
        </el-form-item>
        <el-form-item label="行业细分说明" prop="explain">
          <el-input v-model="ruleForm.explain" type="textarea" :rows="6"></el-input>
        </el-form-item>
        <el-form-item label="发布状态" prop="explain">
          <el-radio v-model="ruleForm.status" :label="1">草稿</el-radio>
          <el-radio v-model="ruleForm.status" :label="2">发布</el-radio>
        </el-form-item>
      </el-form>
    </el-card>
    <div>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: "MechanismAdd",
  data() {
    return {
      ruleForm: {
        name: '',
        img: '',
        nature: '',
        establish_at: '',
        introduction: '',
        address: '',
        investment: '',
        preference: '',
        explain: '',
        round:'',
        status:1,
      },
      rules: {
        name: [
          {required: true, message: '请输入机构名称', trigger: 'blur'},
        ],
        nature: [
          {required: true, message: '请选择机构性质', trigger: 'blur'}
        ],
        round: [
          {required: true, message: '请选择偏好投资轮次', trigger: 'blur'}
        ],
        establish_at: [
          {required: true, message: '请输入成立时间', trigger: 'blur'}
        ],
        introduction: [
          {required: true, message: '请输入机构简介', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入机构办公地址', trigger: 'blur'}
        ],
        investment: [
          {required: true, message: '请输入机构投资行业', trigger: 'blur'}
        ],
        preference: [
          {required: true, message: '请输入机构投资偏好', trigger: 'blur'}
        ],
        explain: [
          {required: true, message: '请输入机构投资细分说明', trigger: 'blur'}
        ],
        img: [
          {required: true, message: '请上传公司logo', trigger: 'change'}
        ]
      },
      activityTypes: [],
      editorObj: null,
      uploadImageUrl: process.env.VUE_APP_BASE_URL + '/image',
      options:[
        {
          value:'VC'
        },
        {
          value:'PE'
        },
        {
          value:'VC-PE'
        },
        {
          value:'天使投资'
        },
        {
          value:'战略投资'
        },
        {
          value:'母基金'
        },
      ],
      options1:[
        {
          value:'种子轮'
        },
        {
          value:'天使轮'
        },
        {
          value:'A轮'
        },
        {
          value:'B轮'
        },
        {
          value:'B轮 以上'
        },

      ]
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendHttpRequest();
        } else {
          return false;
        }
      });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadImageSuccess(response) {
      this.ruleForm.img = response
    },
    sendHttpRequest() {
      this.ruleForm.nature = this.ruleForm.nature.join(",");
      this.ruleForm.round = this.ruleForm.round.join(",");
      this.axios.post('/mechanism', {
        data: this.ruleForm
      }).then(response => {
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$router.push('/mechanism');
          }
        })
      })
    },
    getActivityDetail() {
      const id = this.$route.query.id
      this.axios.get('/mechanism/' + id).then(response => {
        console.log(response);
        this.ruleForm=response.data;
        this.ruleForm.nature=this.ruleForm.nature.split(",");
        this.ruleForm.round=this.ruleForm.round.split(",");
        console.log(this.ruleForm.nature);
      })
    }
  },
  created() {
    this.getActivityDetail()
  },

}
</script>

<style scoped>


.box-card {
  margin-bottom: 30px;
}
.avatar{
  width: 100px;
  height: 100px;
}
.el-icon-plus{
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 1px dashed #333;
}
.el-select{
  width: 100%;
}
</style>

<style>

</style>
