<!--自定义表单（弃用）-->
<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>机构认证操作</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left"
               class="demo-ruleForm">
        <el-form-item label="认证名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="认证图标" prop="picture">
          <el-upload
              class="avatar-uploader"
              :action="upload.host"
              :data="upload.formData"
              :show-file-list="false"
              :on-change="uploadChange"
              :auto-upload="false">
            <div class="upload-file">
              <img v-if="ruleForm.picture" :src="ruleForm.picture" class="avatar" alt="meeting main img">
              <i v-else class="el-icon-plus"></i>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="状态" prop="state">
          <el-radio-group v-model="ruleForm.state">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>认证表单设置</span>
      </div>
      <div>
        <fc-designer ref="designer" v-model="fapi" height="800px"/>
      </div>
    </el-card>
    <div>
      <el-button @click="submitForm('ruleForm')">提交</el-button>
    </div>
  </div>

</template>

<script>
import uploadFileToAliOss from "@/utils/uploadFileToAliOss";
import formCreate from "@form-create/element-ui";
import myImage from '../../utils/myImage'

export default {
  name: "add",

  data() {
    return {
      fapi:{},
      ruleForm:{
        title:'',
        picture:'https://file-stor.globaltalent.net/activity/images/202301/09/kwtfmzx2s4lqUF9S8aIFp9gHz6zy9z41DIS3IeLg.png',
        state:0,
      },
      rules:{
        title: [
          { required: true, message: '请输入认证名称', trigger: 'blur' },
        ],
        picture: [
          { required: true, message: '请上传图标', trigger: 'change' },
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ],
      },
      uploadImageUrl: '',
      dialogImageUrl:{},
      upload: {
        host: '',
        formData: {}
      },
      id:0,

    };


  },
  created(){
    this.id = this.$route.query.id;

  },
  mounted() {
    //删除不必要的组件
    this.$refs.designer.removeMenu('aide');
    this.$refs.designer.removeMenu('layout');
    this.$refs.designer.removeMenuItem('colorPicker');
    this.$refs.designer.removeMenuItem('slider');
    this.$refs.designer.removeMenuItem('rate');
    this.$refs.designer.removeMenuItem('el-transfer');
    this.$refs.designer.removeMenuItem('tree');
    //更新组件
    this.$refs.designer.addComponent(myImage)
    this.$refs.designer.appendMenuItem('main', {
      icon: myImage.icon,
      name: myImage.name,
      label: myImage.label,
    })


    this.detail();
  },
  methods: {
    detail(){
      if (this.id){
        this.axios.get('/formAgency/' + this.id).then(response => {
          if (response.data.code===200){
            this.ruleForm=response.data.data;
            if (this.ruleForm.form_filed){
              console.log(formCreate.parseJson(this.ruleForm.form_filed));
              this.$refs.designer.setRule(formCreate.parseJson(this.ruleForm.form_filed));
            }
            if (this.ruleForm.form_option){
              this.$refs.designer.setOption(formCreate.parseJson(this.ruleForm.form_option));
            }
          }
          console.log(response)
        })
      }
    },
    async submitForm(formName) {
      console.log(JSON.stringify(this.$refs.designer.getRule()));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.oss_img()
          this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    async oss_img() {
      if (this.ruleForm.picture_file instanceof File) {
        this.ruleForm.picture = await uploadFileToAliOss.uploadFile(this.ruleForm.picture_file, 'agency/');
        this.ruleForm.picture_file = '';
      }
    },
    submit(){
      //表单生成规则
      this.ruleForm['form_filed'] = JSON.stringify(this.$refs.designer.getRule());
      //表单生成配置
      this.ruleForm['form_option'] = JSON.stringify(this.$refs.designer.getOption());
      this.axios.post('/formAgency',this.ruleForm).then(response => {
         if (response.data.code===200){
           this.$message({
             message: '操作成功',
             type: 'success',
             duration: 2000,
             onClose: () => {
               this.$router.push('/formAgency/index');
             }
           })
         }else{
           this.$message({
             message: response.data.msg,
             type: 'error',
             duration: 2000
           })
         }
      })
    },
    uploadChange(file) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 5242880) {
        this.$message.error('请上传文件大小不超过5MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.ruleForm.picture = url;
      this.ruleForm.picture_file = file.raw;
    },
  }

}
</script>

<style scoped>

</style>
