<template>
  <div>
    <div class="tinymce-box">
      <!--      <el-button @click="clickHandler">上传视频</el-button>-->
      <Editor
          v-model="contentValue"
          :init="init"
          :disabled="disabled"
          @onClick="onClick"
      />
    </div>
  </div>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";

import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";

// 编辑器插件
import "tinymce/icons/default/icons";
import "tinymce/plugins/advlist"; //高级列表
import "tinymce/plugins/anchor"; //锚点
import "tinymce/plugins/autolink"; //自动链接
import "tinymce/plugins/autoresize"; //编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import "tinymce/plugins/autosave"; //自动存稿
import "tinymce/plugins/charmap"; //特殊字符
import "tinymce/plugins/code"; //编辑源码
import "tinymce/plugins/codesample"; //代码示例
import "tinymce/plugins/directionality"; //文字方向
import "tinymce/plugins/emoticons"; //表情
import "tinymce/plugins/fullpage"; //文档属性
import "tinymce/plugins/fullscreen"; //全屏
import "tinymce/plugins/help"; //帮助
import "tinymce/plugins/hr"; //水平分割线
import "tinymce/plugins/importcss"; //引入css
import "tinymce/plugins/insertdatetime"; //插入日期时间
import "tinymce/plugins/link"; //超链接
import "tinymce/plugins/lists"; //列表插件
import "tinymce/plugins/media"; //插入编辑媒体
import 'tinymce/plugins/image'; // 插入图片
import "tinymce/plugins/nonbreaking"; //插入不间断空格
import "tinymce/plugins/pagebreak"; //插入分页符
import "tinymce/plugins/paste"; //粘贴插件
import "tinymce/plugins/preview"; //预览
import "tinymce/plugins/save"; //保存
import "tinymce/plugins/searchreplace"; //查找替换
import "tinymce/plugins/table"; //表格
import "tinymce/plugins/textcolor"; //文字颜色
import "tinymce/plugins/visualblocks"; //显示元素范围
import "tinymce/plugins/visualchars"; //显示不可见字符
import "tinymce/plugins/wordcount"; //字数统计
import "@npkg/tinymce-plugins/letterspacing";
//字间距
// import "tinymce/plugins/print"; //打印
// import "tinymce/plugins/quickbars"; //快速工具栏
// import "tinymce/plugins/textpattern"; //快速排版
// import "tinymce/plugins/toc"; //目录生成器
// import "tinymce/plugins/template"; //内容模板
// import 'tinymce/plugins/spellchecker'  //拼写检查，未加入汉化，不建议使用
// import "tinymce/plugins/tabfocus"; //切入切出，按tab键切出编辑器，切入页面其他输入框中
import uploadToFileToAliOss from "@/utils/uploadFileToAliOss";

export default {
  name: "TEditor",
  components: {
    Editor,
  },
  props: {
    height: {
      type: [Number],
      default: 500
    },
    upload_dir: {
      type: String,
      default: 'default/',
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
          "help link formatpainter preview searchreplace autolink directionality visualblocks visualchars fullscreen code" +
          " codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists image media wordcount autosave letterspacing ",
    },
    toolbar: {
      type: [String, Array],
      default:
          "undo redo restoredraft formatpainter | cut copy paste | forecolor backcolor bold italic underline strikethrough link anchor letterspacing image|\
           alignleft aligncenter alignright alignjustify outdent indent | \
                  styleselect formatselect fontselect fontsizeselect | bullist numlist fullscreen ",
    },
  },
  data() {
    return {
      dialogVisible: false,
      video: {},
      init: {
        language_url: "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/tinymce/skins/ui/oxide",
        plugins: this.plugins,
        toolbar: this.toolbar,
        fontsize_formats: "12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px", //字体大小
        font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;", //字体样式
        lineheight_formats: "0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5", //行高配置
        letterspacing: "0em 0.25em 0.5em 0.75em 1em 1.25em 1.5em 1.75em 2em 2.25em 2.5em 2.75em 3em",

        height: this.height,
        placeholder: "在这里输入文字",
        branding: false, //tiny技术支持信息是否显示
        resize: "both", //编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可
        elementpath: false, //元素路径是否显示

        content_style: "img {max-width:100%;}", //直接自定义可编辑区域的css样式
        paste_data_images: true, //图片是否可粘贴

        images_upload_handler: (blobInfo, success, failure) => {
          if (blobInfo.blob().size / 1024 / 1024 > 10) {
            failure("上传失败，图片大小请控制在 10M 以内");
          } else {
            uploadToFileToAliOss.uploadFile(blobInfo.blob(), this.upload_dir, function (url) {
              success(url);
            });
          }
        },
      },
      contentValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.contentValue = newValue;
    },
    contentValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  created() {
  },
  mounted() {
    tinymce.init({});
  },
  methods: {

    /**获取文件 */
    getFile(event) {
      this.video = event.target.files;
      this.$message.success("成功选择文件");
    },
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    //清空内容
    clear() {
      this.contentValue = "";
    },
  },
};
</script>

<style lang="less">
.tox-notifications-container {
  display: none;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.tox .tox-menu {
  z-index: 1104 !important;
}
</style>

