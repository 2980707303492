<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>新增会议</span>
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left"
                     class="demo-ruleForm">
                <div style="margin-bottom: 20px;">
                    <span style="font-weight: bold;">基本信息</span>
                </div>
                <div style="margin-left: 15px;">
                    <el-form-item label="会议名称" prop="sub_title">
                        <el-input v-model="ruleForm.sub_title"></el-input>
                    </el-form-item>
                    <el-form-item label="会议时间" prop="date">
                        <div class="block">
                            <el-date-picker
                                    v-model="ruleForm.date"
                                    type="datetimerange"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :default-time="['08:00:00','17:00:00']"
                                    format="yyyy-MM-dd HH:mm"
                            >
                            </el-date-picker>
                        </div>
                    </el-form-item>

                    <el-form-item label="会议地点" prop="sub_address">
                        <el-input v-model="ruleForm.sub_address"></el-input>
                    </el-form-item>

                    <el-form-item label="会议形式" prop="mode">
                        <el-select v-model="ruleForm.mode" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="行业分类" prop="industry_type">
                        <el-select v-model="ruleForm.industry_type" placeholder="请选择">
                            <el-option
                                    v-for="item in industry"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="主办方" prop="sub_organizer">
                        <el-input v-model="ruleForm.sub_organizer"></el-input>
                    </el-form-item>

                    <el-form-item label="会议类型" prop="type_id">
                        <el-cascader
                                :options="activityTypes"
                                v-model="ruleForm.type_id"
                                :props="{ multiple: true }"
                                expand-trigger="hover"
                                clearable></el-cascader>
                    </el-form-item>

                    <el-form-item label="会议图片" prop="event_banner">
                        <el-upload
                                class="avatar-uploader"
                                :action="upload.host"
                                :data="upload.formData"
                                :show-file-list="false"
                                :on-change="(file) => {uploadChange(file,'event_banner')}"
                                :auto-upload="false">
                            <div class="upload-file">
                                <img v-if="ruleForm.event_banner" :src="ruleForm.event_banner" class="avatar"
                                     style="width: 150px;">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                        </el-upload>
                    </el-form-item>


                    <el-form-item label="轮播图片" prop="banner_img">
                        <el-upload
                                class="avatar-uploader"
                                :action="upload.host"
                                :data="upload.formData"
                                :show-file-list="false"
                                :on-change="(file) => {uploadChange(file,'banner_img')}"
                                :auto-upload="false">
                            <div class="upload-file">
                                <img v-if="ruleForm.banner_img" :src="ruleForm.banner_img" class="avatar"
                                     style="width: 150px;">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="会议详情" prop="sub_activity_content">
                        <tinymce upload_dir="roadshow/" v-model="ruleForm.sub_activity_content" ref="editor"
                                 :height="300"></tinymce>
                    </el-form-item>

                    <el-form-item label="大会网址" prop="href">
                        <el-input v-model="ruleForm.href"></el-input>
                    </el-form-item>
                </div>

                <div style="margin-bottom: 20px;margin-top: 40px;">
                    <span style="font-weight: bold;">会场信息</span>
                    <span style="float: right;color: #409EFF;cursor: pointer"
                          @click="venueHandle('add')">添加会场</span>
                </div>
                <div style="margin-left: 15px;">
                    <el-table
                            :key="venueTableKey"
                            border
                            :data="ruleForm.venue"
                            style="width: 100%">
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table border :data="props.row.video" style="width: 100%" :key="videoTableKey">
                                    <el-table-column prop="title" label="视频标题" width="180">
                                    </el-table-column>
                                    <el-table-column label="视频封面" width="180">
                                        <template slot-scope="video">
                                            <img style="height: 30px;" :src="video.row.img_url" alt="">
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="record_url" label="视频播放地址" width="180">
                                    </el-table-column>
                                    <el-table-column label="是否开启直播">
                                        <template slot-scope="video">
                                            <span v-if="video.row.is_live === 1">已开启</span>
                                            <span v-else>未开启</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="开始时间">
                                        <template slot-scope="video">
                                            {{ timestampToDateTime(video.row.start_time) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="结束时间">
                                        <template slot-scope="video">
                                            {{ timestampToDateTime(video.row.end_time) }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作">
                                        <template slot-scope="video">
                                            <el-button
                                                    size="mini"
                                                    @click="videoHandle('edit',video.$index, video.row,props.row)">编辑
                                            </el-button>
                                            <el-button
                                                    size="mini"
                                                    type="danger"
                                                    @click="videoHandle('del',video.$index, video.row,props.row)">删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="venue_name"
                                label="会场名称"
                                width="180">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button
                                        size="mini"
                                        @click="videoHandle('add',scope.$index, null,scope.row)">添加视频
                                </el-button>
                                <el-button
                                        size="mini"
                                        @click="venueHandle('edit',scope.$index, scope.row)">修改会场信息
                                </el-button>
                                <el-button
                                        size="mini"
                                        type="danger"
                                        @click="venueHandle('del',scope.$index, scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>


            </el-form>
        </el-card>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>报名表单设置</span>
            </div>
            <div>
                <fc-designer ref="designer" height="800px" :menu="menu"/>
            </div>
        </el-card>

        <div>
            <el-button type="primary" @click="submitForm('ruleForm')">提交数据</el-button>
        </div>


        <el-dialog title="会场信息" :visible.sync="venueDialog" :close-on-click-modal="false">
            <el-form :model="venueDialogData" label-width="100px" :rules="venueDialogDataRule" ref="venueDialog">
                <el-form-item label="会场名称" prop="venue_name">
                    <el-input v-model="venueDialogData.venue_name" placeholder="请填写当前会场名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="venueDialog = false">取 消</el-button>
                <el-button type="primary" @click="venueSave">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="视频信息" :visible.sync="videoDialog" :close-on-click-modal="false">
            <el-form :model="videoDialogData" label-width="110px" :rules="videoDialogDataRule" ref="videoDialog">
                <el-form-item label="视频标题" prop="title">
                    <el-input v-model="videoDialogData.title" placeholder="请填写视频标题"></el-input>
                </el-form-item>
                <el-form-item label="视频时间" prop="date">
                    <el-date-picker
                            v-model="videoDialogData.date"
                            type="datetimerange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['08:00:00','17:00:00']"
                            value-format="timestamp"
                    >
                    </el-date-picker>
                    <el-tooltip placement="top" style="margin-left: 10px;">
                        <div slot="content">
                            视频或直播的开始时间与结束时间
                        </div>
                        <i class="el-icon-info" style="color: #237BCD"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="是否开启直播" prop="is_live">
                    <el-radio v-model="videoDialogData.is_live" :label="1">开启</el-radio>
                    <el-radio v-model="videoDialogData.is_live" :label="0">关闭</el-radio>
                </el-form-item>
                <el-form-item label="会议封面图" prop="img_url">
                    <el-upload
                            class="avatar-uploader"
                            :action="upload.host"
                            :data="upload.formData"
                            :show-file-list="false"
                            :on-change="(file) => {uploadChange(file,'img_url')}"
                            :auto-upload="false">
                        <div class="upload-file">
                            <img v-if="videoDialogData.img_url" :src="videoDialogData.img_url" class="avatar"
                                 alt="meeting main img">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </div>
                    </el-upload>
                    <el-input v-model="videoDialogData.img_url" placeholder="输入网络图片地址。以上传图片优先级最高，如果有上传图片，则以上传的图片为准"></el-input>
                </el-form-item>
                <el-form-item label="视频地址" prop="record_url">
                    <el-input v-model="videoDialogData.record_url"
                              placeholder="如果未开启直播，视频地址为必填项"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="videoDialog = false">取 消</el-button>
                <el-button type="primary" @click="videoSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {changeTagsToListString} from "@/utils/tags";
import tinymce from "../tinymce";
import uploadFileToAliOss from "@/utils/uploadFileToAliOss";
import formCreate from "@form-create/element-ui";
export default {
    name: "activityForm",
    components: {tinymce},
    data() {
        let checkVenueDitto = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请填写会场名称'));
            }
            this.ruleForm.venue.forEach(function (item) {
                if (item.venue_name === value) {
                    return callback(new Error('当前会场名称已存在'));
                }
            });
        };
        let checkVideoRecordUrl = (rule, value, callback) => {
            if (this.videoDialogData.is_live === 0 && !value) {
                return callback(new Error('请填写视频地址'));
            }
        };
        return {
            upload: {
                host: '',
                formData: {}
            },
            uploadData: {},
            ruleForm: {
                id: '',
                sub_title: '',
                sub_address: '',
                date: '',
                is_login: 1,
                sub_organizer: '',
                event_banner: '',
                sub_activity_content: '',
                type_id: '',
                activity_type: '',
                sign_up_href: '',
                href: '',
                mode: '',
                is_live: '0',
                industry_type: '',
                banner_img: '',
                venue: [],

            },
            rules: {
                sub_title: [
                    {required: true, message: '请输入会议名称', trigger: 'blur'},

                ],
                sub_address: [
                    {required: true, message: '请输入会议地点', trigger: 'blur'},

                ],
                type_id: [
                    {required: true, message: '请选择会议类型', trigger: 'change'}
                ],
                date: [
                    {required: true, message: '请选择会议开始和结束日期', trigger: 'blur'}
                ],
                sub_organizer: [
                    {required: true, message: '请输入主办方名称', trigger: 'blur'},

                ],
                event_banner: [
                    {required: true, message: '请上传会议图片', trigger: 'change'}
                ],
                sub_activity_content: [
                    {required: true, message: '请输入会议详情', trigger: 'change'}
                ],

                mode: [
                    {required: true, message: '请选择会议形式', trigger: 'change'}
                ],
                industry_type: [
                    {required: true, message: '请选择行业分类', trigger: 'change'}
                ]
            },
            dialogImageUrl: '',
            dialogVisible: false,
            menu: [
                {
                    title: '基本信息',
                    name: 'group1',
                    list: [
                        {
                            'icon': 'icon-input',
                            'name': 'input',
                            'label': '输入框'
                        }
                    ]
                }
            ],
            activityTypes: [],
            types: {},
            key: '',
            options: [
                {
                    value: '1',
                    label: '线下'
                },
                {
                    value: '2',
                    label: '线上'
                },
                {
                    value: '3',
                    label: '线上线下同步'
                },
            ],
            industry: {},
            keys: '',

            venueTableKey: false,
            venueDialog: false,
            venueDialogData: {},
            venueDialogDataRule: {
                venue_name: [
                    {validator: checkVenueDitto, trigger: ['blur', 'change']}
                ],
            },

            videoTableKey: false,
            videoDialog: false,
            videoDialogData: {},
            videoDialogDataRule: {
                title: [{required: true, message: '请填写视频标题', trigger: 'blur'}],
                is_live: [{required: true, message: '请选择是否开启直播功能', trigger: 'blur'}],
                img_url: [{required: true, message: '请上传会议封面图', trigger: 'blur'}],
                date: [{required: true, message: '请选择开始时间', trigger: 'blur'}],
                record_url: [{validator: checkVideoRecordUrl, message: '请填写视频地址', trigger: 'blur'}],
            },
        };
    },
    methods: {
        projectType() {
            this.axios.get('/projectType').then(response => {
                this.industry = response.data
            })
        },
        venueHandle(handle, index, row) {
            let init = {
                venue_name: '主会场',
                sort: 0,
                index: '',
                is_add: false,
                id: '',
                video: [],
            };
            if (handle === 'add') {
                init.index = this.ruleForm.venue.length;
                init.is_add = true;
                this.venueDialogData = init;
                this.venueDialog = true;
            } else if (handle === 'edit') {
                let venue = this.ruleForm.venue[index];
                for (let i in init) {
                    init[i] = venue[i];
                }
                init.is_add = false;
                init.index = index;
                this.venueDialogData = init;
                this.venueDialog = true;
            } else {
                let index = row.index;
                this.ruleForm.venue.splice(index, 1);
                for (let i in this.ruleForm.venue) {
                    this.ruleForm.venue[i].index = Number(i);
                    if (this.ruleForm.venue[i].video) {
                        for (let j in this.ruleForm.venue[i].video) {
                            this.ruleForm.venue[i].video[j].venue_index = Number(i);
                        }
                    }
                }
                this.venueTableKey = !this.venueTableKey;
            }
        },
        venueSave() {
            let validateFlag = true;
            this.$refs.venueDialog.validate((valid) => {
                if (!valid) {
                    validateFlag = false;
                }
            });
            if (!validateFlag) {
                return;
            }
            let init = JSON.parse(JSON.stringify(this.venueDialogData));
            if (init.is_add) {
                this.ruleForm.venue.push(init);
            } else {
                this.ruleForm.venue[init.index] = init;
            }
            this.venueTableKey = !this.venueTableKey;
            this.venueDialog = false;
            this.$refs.venueDialog.resetFields();

        },
        videoHandle(handle, index, row, venueRow) {
            let init = {
                venue_index: venueRow.index,
                title: '',
                is_live: 0,
                img_url: '',
                start_time: 0,
                end_time: 0,
                date: [],
                record_url: '',
                index: '',
                is_add: false,
                id: '',
            };
            if (handle === 'add') {
                init.is_add = true;
                init.index = venueRow.video.length;
                this.videoDialogData = init;
                this.videoDialog = true;
            } else if (handle === 'edit') {
                for (let i in init) {
                    if (row[i]) {
                        init[i] = row[i];
                    }
                }
                init.date = [init.start_time * 1000, init.end_time * 1000];
                init.is_add = false;
                init.index = index;
                this.videoDialogData = init;
                this.videoDialog = true;
            } else {
                let index = row.index;
                this.ruleForm.venue[venueRow.index].video.splice(index, 1);
                for (let i in this.ruleForm.venue[venueRow.index].video) {
                    this.ruleForm.venue[venueRow.index].video[i].index = Number(i);
                }
                this.videoTableKey = !this.videoTableKey;
            }
        },
        videoSave() {
            let validateFlag = true;
            this.$refs.videoDialog.validate((valid) => {
                if (!valid) {
                    validateFlag = false;
                }
            });
            if (!validateFlag) {
                return;
            }
            let file = this.videoDialogData.img_url_file ? this.videoDialogData.img_url_file : '';
            let init = JSON.parse(JSON.stringify(this.videoDialogData));
            init.img_url_file = file;
            init.start_time = parseInt(init.date[0] / 1000);
            init.end_time = parseInt(init.date[1] / 1000);

            if (init.is_add) {
                this.ruleForm.venue[init.venue_index].video.push(init);
            } else {
                this.ruleForm.venue[init.venue_index].video[init.index] = init;
            }
            this.videoTableKey = !this.videoTableKey;
            this.videoDialog = false;
            this.$refs.videoDialog.resetFields();
        },


        submitForm(formName) {
            this.ruleForm['type_id'] = changeTagsToListString(this.ruleForm['type_id']);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sendHttpRequest();
                } else {
                    return false;
                }
            });
        },

        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        async sendHttpRequest() {
            let that = this;
            let rule = this.$refs.designer.getRule();
            for (let i = 0; i < rule.length; i++) {
                rule[i]['field'] = rule[i]['title']
                if (rule[i]['validate']) {
                    rule[i]['validate'][0]['message'] = '请输入您的' + rule[i]['title']
                }
            }
            //上传图片

            this.ruleForm['sub_form_filed'] = JSON.stringify(rule);
            this.ruleForm['sub_form_option'] = JSON.stringify(this.$refs.designer.getOption());

            // 上传文件
            if (that.uploadData.banner_img && that.uploadData.banner_img instanceof File) {
                that.ruleForm.banner_img = await uploadFileToAliOss.uploadFile(that.uploadData.banner_img, 'activity/images/');
                that.uploadData.banner_img = '';
            }
            if (that.uploadData.event_banner && that.uploadData.event_banner instanceof File) {
                that.ruleForm.event_banner = await uploadFileToAliOss.uploadFile(that.uploadData.event_banner, 'activity/images/');
                that.uploadData.event_banner = '';
            }
            if (that.ruleForm.venue) {
                for (let i in that.ruleForm.venue) {
                    if (that.ruleForm.venue[i].video.length) {
                        for (let j in that.ruleForm.venue[i].video) {
                            if (that.ruleForm.venue[i].video[j].img_url_file instanceof File) {
                                that.ruleForm.venue[i].video[j].img_url =
                                    await uploadFileToAliOss.uploadFile(that.ruleForm.venue[i].video[j].img_url_file, 'activity/images/');
                                that.ruleForm.venue[i].video[j].img_url_file = '';
                            }
                        }
                    }
                }
            }
            this.axios.post('/activities/saveData', this.ruleForm).then(response => {
                this.$message({
                    message: response.data.msg,
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                        this.$router.push('/activities');
                    }
                })
            })
        },

        getAllTypes() {
            this.axios.get('/types').then(response => {
                this.activityTypes = response.data
            })
        },

        uploadImageSuccess(response) {
            this.ruleForm.event_banner = response
        },
        bannerSuccess(response) {
            this.ruleForm.banner_img = response
        },

        uploadChange(file, type) {
            if (!/.(jpg|jpeg|png)$/.test(file.name)) {
                this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
                return '';
            }
            if (file.size > 5242880) {
                this.$message.error('请上传文件大小不超过5MB的图片');
                return;
            }
            let url = '';
            if (window.createObjectURL !== undefined) {
                url = window.createObjectURL(file.raw);
            } else if (window.URL !== undefined) {
                url = window.URL.createObjectURL(file.raw);
            } else if (window.webkitURL !== undefined) {
                url = window.webkitURL.createObjectURL(file.raw);
            }
            if (type === 'banner_img' || type === 'event_banner') {
                this.ruleForm[type] = url;
                this.uploadData[type] = file.raw;
            } else if (type === 'img_url') {//会议图片
                this.videoDialogData.img_url = url;
                this.videoDialogData.img_url_file = file.raw;
                this.$forceUpdate();
            }
        },

        timestampToDateTime(timestamp) {
            let date;
            if (timestamp.toString().length === 10) {
                date = new Date(timestamp * 1000);
            } else if (timestamp.toString().length === 13) {
                date = new Date(timestamp);
            } else {
                return "Invalid timestamp";
            }

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },

        getDetail() {
            let that = this;
            let param = that.$route.query;
            if (param.id) {
                let id = param.id;
                this.axios({
                    url: '/activities/getDetail',
                    data:{id:id},
                    method: 'post',
                }).then(function (response) {
                    if (response.data.code === 1) {
                        that.ruleForm = response.data.data.detail;
                        if (that.ruleForm.sub_form_filed){
                            that.$refs.designer.setRule(formCreate.parseJson(that.ruleForm.sub_form_filed));
                        }
                        if (that.ruleForm.sub_form_option){
                            if (that.ruleForm.sub_form_option === '{"form":[]}') {
                                that.$refs.designer.setOption({form:{}});
                            }else{
                                that.$refs.designer.setOption(formCreate.parseJson(that.ruleForm.sub_form_option));
                            }
                        }
                        const tagIdList = (that.ruleForm.type_id).split(',')
                        const tagIdIem = tagIdList.map(item => {
                            return item.split('-')
                        })
                        that.ruleForm.type_id = []
                        that.ruleForm.type_id.push(...tagIdIem)
                        that.ruleForm.mode = String(that.ruleForm.mode);
                    }

                });
            }
        },
    },
    created() {
        this.getAllTypes();
        this.projectType();
        this.getDetail();
    },
    mounted() {
        const rule = [
            {
                "type": "input",
                "field": "姓名",
                "title": "姓名",
                "info": "",
                "_fc_drag_tag": "input",
                "hidden": false,
                "display": true,
                "validate": [
                    {
                        "trigger": "blur",
                        "mode": "required",
                        "message": "请输入您的姓名",
                        "required": true,
                        "type": "string"
                    }
                ]
            },
            {
                "type": "input",
                "field": "单位",
                "title": "单位",
                "info": "",
                "_fc_drag_tag": "input",
                "hidden": false,
                "display": true,
                "validate": [
                    {
                        "trigger": "blur",
                        "mode": "required",
                        "message": "请输入您的单位",
                        "required": true,
                        "type": "string"
                    }
                ]
            },
            {
                "type": "input",
                "field": "职务",
                "title": "职务",
                "info": "",
                "_fc_drag_tag": "input",
                "hidden": false,
                "display": true,
                "validate": [
                    {
                        "trigger": "blur",
                        "mode": "required",
                        "message": "请输入您的职务",
                        "required": true,
                        "type": "string"
                    }
                ]
            },
            {
                "type": "input",
                "field": "手机",
                "title": "手机",
                "info": "",
                "_fc_drag_tag": "input",
                "hidden": false,
                "display": true,
                "validate": [
                    {
                        "trigger": "blur",
                        "mode": "required",
                        "message": "请输入您的手机",
                        "required": true,
                        "type": "string"
                    }
                ]
            },
            {
                "type": "input",
                "field": "邮箱",
                "title": "邮箱",
                "info": "",
                "_fc_drag_tag": "input",
                "hidden": false,
                "display": true,
                "validate": [
                    {
                        "trigger": "blur",
                        "mode": "required",
                        "message": "请输入您的邮箱",
                        "required": true,
                        "type": "string"
                    }
                ]
            },
            {
                "type": "input",
                "field": "微信",
                "title": "微信",
                "info": "",
                "_fc_drag_tag": "input",
                "hidden": false,
                "display": true,
                "validate": [
                    {
                        "trigger": "blur",
                        "mode": "required",
                        "message": "请输入您的微信",
                        "required": true,
                        "type": "string"
                    }
                ]
            },
        ]
        this.$refs.designer.setRule(rule)


    }
}
</script>

<style scoped>

.box-card {
    margin-bottom: 30px;
}
</style>

<style>
.el-select-dropdown {
    z-index: 100000 !important;
}
</style>
