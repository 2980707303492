<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right"
                   style="margin-bottom: 30px;font-size: 16px;font-weight: normal;">
      <el-breadcrumb-item :to="{ path: '/bang' }">揭榜挂帅</el-breadcrumb-item>
      <el-breadcrumb-item>揭榜人员</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="bangPeople" style="width: 100%" empty-text="暂无揭榜人员">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="用户姓名">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="用户手机号">
              <span>{{ props.row.mobile }}</span>
            </el-form-item>
            <el-form-item label="用户邮箱">
              <span>{{ props.row.email }}</span>
            </el-form-item>
            <el-form-item label="用户单位">
              <span>{{ props.row.company }}</span>
            </el-form-item>
            <el-form-item label="研究背景">
              <span v-html="props.row.research_background"></span>
            </el-form-item>
            <el-form-item label="研究目标">
              <span v-html="props.row.research_objectives"></span>
            </el-form-item>
            <el-form-item label="技术路线">
              <span v-html="props.row.technology_roadmap"></span>
            </el-form-item>
            <el-form-item label="预期结果">
              <span v-html="props.row.expected_results"></span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="用户姓名"></el-table-column>
      <el-table-column align="center" prop="mobile" label="用户手机号"></el-table-column>
      <el-table-column align="center" prop="email" label="用户邮箱"></el-table-column>
      <el-table-column align="center" prop="company" label="用户单位"></el-table-column>
      <el-table-column align="center" prop="created_at" label="揭榜时间" width="170" min-width="150"></el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>


</template>

<script>


export default {
  name: "Bang",
  data() {
    return {
      bangPeople: [],
      currentPage: 1,
      perPage: 8,
      total: 0,
    }
  },
  methods: {
    getAllBangsUser() {
      let that = this;
      let id = this.$route.query.id;
      this.axios.get(`/bangPeople?id=${id}&page=${this.currentPage}`).then(response => {
        let responseData = response.data;
        that.bangPeople = responseData.data.list;
        that.total = responseData.data.count;
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllBangsUser()
    },
    showNote(note) {
      if (!note) {
        return;
      }
      this.$alert('<div style="white-space: pre-wrap;">' + note + '</div>', '备注信息', {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        closeOnClickModal: true,
        closeOnPressEscape: true,
      });
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 20) {
        return value.slice(0, 20) + "...";
      }
      return value;
    }
  },
  created() {
    this.getAllBangsUser()
  },
  components: {}
}
</script>
<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
