<template>
  <div >
    <p class="xs-title">职位直播专场</p>
    <el-table :data="positions">
      <el-table-column prop="id" label="ID" sortable ></el-table-column>
      <el-table-column prop="name" label="直播专场名称"></el-table-column>
      <el-table-column prop="created_at" label="添加时间"></el-table-column>
      <el-table-column label="职位顺序">
        <template slot-scope="scope">
          <div @click="position(scope.row.id)" style="cursor:pointer;">
            查看
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleSee(scope.row)">查看推流地址</el-button>
          <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>

    <el-dialog
        title="直播推流地址"
        :visible.sync="dialogVisible"
        width="30%">
      <span>{{alive_url}}</span>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "positionLive",
  data() {
    return {
      positions: [],
      meta: [],
      currentPage: 0,
      dialogVisible:false,
      alive_url:''
    }
  },
  methods: {
    getAllPositions() {
      this.axios.get('/positionLive?page=' + this.currentPage).then(response => {
        console.log(response)
        this.positions = response.data.data
        this.meta['total'] = response.data.total*1
        this.meta['per_page'] = response.data.per_page*1
        this.meta['current_page'] = response.data.current_page*1
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllPositions()
    },
    handleEdit(id){
      this.$router.push({
        path: '/positionLive/form',
        query: {
          id
        }
      })
    },
    handleSee(row){
      this.alive_url=row.alive_url;
      this.dialogVisible=true;
    },
    handleDelete(d_id){
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.axios.post('/positionLiveDel',{d_id:d_id}).then(response => {
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      console.log(d_id)
    },
    position(id){
      this.$router.push({
        path: '/positionLive/position',
        query: {
          id
        }
      })
    }
  },
  created() {
    this.getAllPositions()
  }
}
</script>

<style scoped>

</style>
