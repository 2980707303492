import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Home from '../views/Home';
import Activity from "../components/activity/index";
import ActivityAdd from '../components/activity/add'
import ActivityEdit from '../components/activity/edit'
import ActivityForm from '../components/activity/form'
import Position from '../components/position/index'
import PositionAdd from '../components/position/add'
import PositionEdit from '../components/position/edit'
import positionAuditing from '../components/position/auditing'

import Resume from '../components/resume/index'
import ResumeAdd from '../components/resume/add'
import Project from '../components/project/index'
import ProjectEdit from '../components/project/edit'
import Topic from '../components/topic/index'
import TopicCheck from '../components/topic/check'
import User from '../components/user/index'
import UserEmail from '../components/user/email'
import ActivitySignUp from '../components/activity/signup/index'

import News from '../views/News'
import NewsList from '../components/news/index'
import NewsAdd from '../components/news/add'
import NewsDetail from '../components/news/detail'
import NewsEdit from '../components/news/edit'
import NewsTags from '../components/news/tagsIndex'
import NewsTagsAdd from '../components/news/tagAdd'
import NewsApproval from '../components/news/approval'
import NewsApprovalSee from '../components/news/approval_see'
import newsTopic from '../components/news/topic'
import newsTopicAdd from '../components/news/topicAdd'
import newsTopicEdit from '../components/news/topicEdit'

import Policy from '../views/policy'
import PolicyList from '../components/policy/index'
import PolicyAdd from '../components/policy/add'
import PolicyDetail from '../components/policy/detail'
import PolicyEdit from '../components/policy/edit'
import PolicyTags from '../components/policy/tagsIndex'
import PolicyTagsAdd from '../components/policy/tagAdd'

import Bang from '../components/bang'
import BangForm from '../components/bang/form'
import BangPeople from '../components/bang/people'
import bangApproval from '../components/bang/approval'
import bangApprovalSee from '../components/bang/approval_see'

import Enterprise from '../components/enterprise'
import EnterpriseAuditing from '../components/enterprise/auditing'

import Label from '../components/label'
import LabelAdd from '../components/label/labelAdd'
import LabelPosition from '../components/label/position'
import LabelPositionAdd from '../components/label/positionAdd'
import LabelEnterprise from '../components/label/enterprise'
import LabelEnterpriseAdd from '../components/label/enterpriseAdd'


import Special from '../components/special'
import SpecialAdd from '../components/special/add'
import SpecialEdit from '../components/special/edit'
import SpecialData from '../components/special/dataList'

import Mechanism from '../components/mechanism'
import MechanismAdd from '../components/mechanism/add'
import MechanismEdit from '../components/mechanism/edit'
import MechanismCaseList from '../components/mechanism/caseList'
import MechanismIncubation from '../components/mechanism/incubation'
import MechanismRotation from '../components/mechanism/rotation'
import MechanismSettled from '../components/mechanism/settled'


import Case from '../components/case'
import CaseAdd from '../components/case/add'
import CaseEdit from '../components/case/edit'

import Feedback from '../components/feedback'
import FeedbackNew from '../components/feedback/feedbackNew'


import Expert from '../components/expert'
import ExpertForm from '../components/expert/form'

import Projectlib from '../components/projectlib'
import ProjectlibForm from '../components/projectlib/form'
import ProjectlibDetail from '../components/projectlib/detail'

import Roadshow from '../components/roadshow'
import RoadshowForm from '../components/roadshow/form'
import RoadshowSign from '../components/roadshow/signup'
import RoadshowCollect from '../components/roadshow/collect'


import PositionLive from '../components/positionLive'
import PositionLiveFrom from '../components/positionLive/form'
import LivePosition from '../components/positionLive/position'

import Recommend from '../components/commonRecommend/index'
import ExRecommend from '../components/recommend/e_recommend'
import PxRecommend from '../components/recommend/p_recommend'
import JxRecommend from '../components/recommend/j_recommend'
import XxRecommend from '../components/recommend/x_recommend'
import LxRecommend from '../components/recommend/l_recommend'
import HxRecommend from '../components/recommend/h_recommend'
import KxRecommend from '../components/recommend/k_recommend'
import ImgRecommend from '../components/recommend/img_recommend'

import CXRecommend from '../components/c_recommend/c_x_recommend'
import CZRecommend from '../components/c_recommend/c_z_recommend'
import CCRecommend from '../components/c_recommend/c_c_recommend'

import ZZRecommend from '../components/z_recommend/z_z_recommend'
import ZQRecommend from '../components/z_recommend/z_q_recommend'
import ZLRecommend from '../components/z_recommend/z_list_recommend'
import LQRecommend from '../components/z_recommend/list_q_recommend'

import RecommendImg from '../components/commonRecommend/recommendImg'
import PositionsImg from '../components/position/positionsImg'

import formAgency from '../components/formAgency/index'
import formAgencySave from '../components/formAgency/save'
import formAgencyMyImage from '../components/formAgency/myImage'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/activities',
        children: [
            {
                path: 'activities',
                name: 'activity',
                component: Activity
            },
            {
                path: 'activities/add',
                name: 'activityAdd',
                component: ActivityAdd,
            },
            {
                path: 'activities/edit',
                name: 'activityEdit',
                component: ActivityEdit,
            },
            {
                path: 'activities/form',
                name: 'activityForm',
                component: ActivityForm,
            },
            {
                path: 'activities/sign-up',
                name: 'activitySignUp',
                component: ActivitySignUp,
            },
            {
                path: 'positions',
                name: 'position',
                component: Position,
            },
            {
                path: 'positions/add',
                name: 'positionAdd',
                component: PositionAdd,
            },
            {
                path: 'positions/edit',
                name: 'positionEdit',
                component: PositionEdit,
            },
            {
                path: 'position/auditing',
                name: 'positionAuditing',
                component: positionAuditing,
            },
            {
                path: 'positionLive',
                name: 'PositionLive',
                component: PositionLive,
            },
            {
                path: 'positionLive/form',
                name: 'PositionLiveForm',
                component: PositionLiveFrom,
            },
            {
                path: 'positionLive/position',
                name: 'LivePosition',
                component: LivePosition,
            },
            {
                path: 'resumes',
                name: 'resume',
                component: Resume,
            },
            {
                path: 'resumes/add',
                name: 'resumeAdd',
                component: ResumeAdd,
            },
            {
                path: 'projects',
                name: 'project',
                component: Project,
            },
            {
                path: 'projects/edit',
                name: 'projectEdit',
                component: ProjectEdit,
            },
            {
                path: 'topics',
                name: 'topic',
                component: Topic,
            },
            {
                path: 'topics/check',
                name: 'topicCheck',
                component: TopicCheck,
            },
            {
                path: 'users',
                name: 'user',
                component: User,
            },
            {
                path: 'users/email',
                name: 'userEmail',
                component: UserEmail,
            },
            {
                path: 'news',
                name: 'news',
                component: News,
                redirect: '/news/list',
                children: [
                    {
                        path: 'list',
                        name: 'newsList',
                        component: NewsList,
                    },
                    {
                        path: 'add',
                        name: 'newsAdd',
                        component: NewsAdd,
                    },
                    {
                        path: 'detail',
                        name: 'newsDetail',
                        component: NewsDetail,
                    },
                    {
                        path: 'edit',
                        name: 'newsEdit',
                        component: NewsEdit,
                    },
                    {
                        path: 'tags',
                        name: 'newsTags',
                        component: NewsTags,
                    },
                    {
                        path: 'tags/add',
                        name: 'newsTagsAdd',
                        component: NewsTagsAdd,
                    },
                    {
                        path: 'topic',
                        name: 'newsTopic',
                        component: newsTopic,
                    },
                    {
                        path: 'topicAdd',
                        name: 'newsTopicAdd',
                        component: newsTopicAdd,
                    },
                    {
                        path: 'topicEdit',
                        name: 'newsTopicEdit',
                        component: newsTopicEdit,
                    },

                ]
            },
            {
                path: 'policy',
                name: 'policy',
                component: Policy,
                redirect: '/policy/list',
                children: [
                    {
                        path: 'list',
                        name: 'policyList',
                        component: PolicyList,
                    },
                    {
                        path: 'add',
                        name: 'policyAdd',
                        component: PolicyAdd,
                    },
                    {
                        path: 'detail',
                        name: 'policyDetail',
                        component: PolicyDetail,
                    },
                    {
                        path: 'edit',
                        name: 'policyEdit',
                        component: PolicyEdit,
                    },
                    {
                        path: 'tags',
                        name: 'policyTags',
                        component: PolicyTags,
                    },
                    {
                        path: 'tags/add',
                        name: 'policyTagsAdd',
                        component: PolicyTagsAdd,
                    }
                ]
            },
            {
                path: 'bang',
                name: 'bang',
                component: Bang,
            },
            {
                path: 'bang/form',
                name: 'bangForm',
                component: BangForm,
            },
            {
                path: 'bang/people',
                name: 'bangForm',
                component: BangPeople,
            },
            {
                path: 'bang/approval',
                name: 'bangApproval',
                component: bangApproval,
            },
            {
                path: 'bang/approval_see',
                name: 'bangApprovalSee',
                component: bangApprovalSee,
            },
            {
                path: 'enterprise',
                name: 'enterprise',
                component: Enterprise,
            },
            {
                path: 'label',
                name: 'label',
                component: Label,
            },
            {
                path: 'label/labelAdd',
                name: 'labelAdd',
                component: LabelAdd,
            },
            {
                path: 'label/position',
                name: 'LabelPosition',
                component: LabelPosition,
            },
            {
                path: 'label/positionAdd',
                name: 'LabelPositionAdd',
                component: LabelPositionAdd,
            },
            {
                path: 'special',
                name: 'special',
                component: Special,
            },
            {
                path: 'special/add',
                name: 'SpecialAdd',
                component: SpecialAdd,
            },
            {
                path: 'special/edit',
                name: 'SpecialEdit',
                component: SpecialEdit,
            },
            {
                path: 'mechanism',
                name: 'Mechanism',
                component: Mechanism,
            },
            {
                path: 'mechanism/add',
                name: 'MechanismAdd',
                component: MechanismAdd,
            },
            {
                path: 'mechanism/edit',
                name: 'MechanismEdit',
                component: MechanismEdit,
            },
            {
                path: 'mechanism/caseList',
                name: 'MechanismCaseList',
                component: MechanismCaseList,
            },
            {
                path: 'mechanism/incubation',
                name: 'MechanismIncubation',
                component: MechanismIncubation,
            },
            {
                path: 'case',
                name: 'Case',
                component: Case,
            },
            {
                path: 'case/add',
                name: 'CaseAdd',
                component: CaseAdd,
            },
            {
                path: 'case/edit',
                name: 'CaseEdit',
                component: CaseEdit,
            },
            {
                path: 'news/approval',
                name: 'NewsApproval',
                component: NewsApproval,
            },
            {
                path: 'news/approval_see',
                name: 'NewsApprovalSee',
                component: NewsApprovalSee,
            },
            {
                path: 'feedback',
                name: 'Feedback',
                component: Feedback,
            },
            {
                path: 'FeedbackNew',
                name: 'FeedbackNew',
                component: FeedbackNew,
            },

            {
                path: 'expert',
                name: 'Expert',
                component: Expert,
            },
            {
                path: 'expert/form',
                name: 'ExpertForm',
                component: ExpertForm,
            },
            {
                path: 'special/dataList',
                name: 'SpecialData',
                component: SpecialData,
            },
            {
                path: 'projectlib',
                name: 'Projectlib',
                component: Projectlib,
            },
            {
                path: 'projectlib/form',
                name: 'projectlibForm',
                component: ProjectlibForm,
            },
            {
                path: 'projectlib/detail',
                name: 'ProjectlibDetail',
                component: ProjectlibDetail,
            },
            {
                path: 'roadshow',
                name: 'roadshow',
                component: Roadshow,
            },
            {
                path: 'roadshow/form',
                name: 'RoadshowForm',
                component: RoadshowForm,
            },
            {
                path: 'commonRecommend/index',
                name: 'Recommend',
                component: Recommend,
            },
            {
                path: 'recommend/e_recommend',
                name: 'ExRecommend',
                component: ExRecommend,
            },
            {
                path: 'recommend/p_recommend',
                name: 'PxRecommend',
                component: PxRecommend,
            },
            {
                path: 'recommend/j_recommend',
                name: 'JxRecommend',
                component: JxRecommend,
            },
            {
                path: 'recommend/x_recommend',
                name: 'XxRecommend',
                component: XxRecommend,
            },
            {
                path: 'recommend/l_recommend',
                name: 'LxRecommend',
                component: LxRecommend,
            },
            {
                path: 'recommend/h_recommend',
                name: 'HxRecommend',
                component: HxRecommend,
            },
            {
                path: 'recommend/k_recommend',
                name: 'KxRecommend',
                component: KxRecommend,
            },
            {
                path: 'c_recommend/c_x_recommend',
                name: 'CXRecommend',
                component: CXRecommend,
            },
            {
                path: 'c_recommend/c_z_recommend',
                name: 'CZRecommend',
                component: CZRecommend,
            },
            {
                path: 'c_recommend/c_c_recommend',
                name: 'CCRecommend',
                component: CCRecommend,
            },
            {
                path: 'z_recommend/z_z_recommend',
                name: 'ZZRecommend',
                component: ZZRecommend,
            },
            {
                path: 'z_recommend/z_q_recommend',
                name: 'ZQRecommend',
                component: ZQRecommend,
            },
            {
                path: 'commonRecommend/recommendImg',
                name: 'RecommendImg',
                component: RecommendImg,
            },
            {
                path: 'position/positionsImg',
                name: 'PositionsImg',
                component: PositionsImg,
            },
            {
                path: 'label/enterprise',
                name: 'LabelEnterprise',
                component: LabelEnterprise,
            },
            {
                path: 'label/enterpriseAdd',
                name: 'LabelEnterpriseAdd',
                component: LabelEnterpriseAdd,
            },
            {
                path: 'z_recommend/z_list_recommend',
                name: 'ZLRecommend',
                component: ZLRecommend,
            },
            {
                path: 'z_recommend/list_q_recommend',
                name: 'LQRecommend',
                component: LQRecommend,
            },
            {
                path: 'mechanism/rotation',
                name: 'MechanismRotation',
                component: MechanismRotation,
            },
            {
                path: 'mechanism/settled',
                name: 'MechanismSettled',
                component: MechanismSettled,
            },
            {
                path: 'roadshow/sign-up',
                name: 'RoadshowSign',
                component: RoadshowSign,
            },
            {
                path: 'roadshow/collect',
                name: 'RoadshowCollect',
                component: RoadshowCollect,
            },
            {
                path: 'recommend/img_recommend',
                name: 'ImgRecommend',
                component: ImgRecommend,
            },
            {
                path: 'formAgency/index',
                name: 'formAgency',
                component: formAgency,
            },
            {
                path: 'formAgency/save',
                name: 'formAgencySave',
                component: formAgencySave,
            },
            {
                path: 'formAgency/myImage',
                name: 'formAgencyMyImage',
                component: formAgencyMyImage,
            },

            {
                path: 'enterprise/auditing',
                name: 'EnterpriseAuditing',
                component: EnterpriseAuditing,
            },

        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const token = Vue.$cookies.get('token');
    if (token == null && to.path != '/login') {
        next('/login');
        document.title = '环球英才网后台-登录'
    } else if (token != null && to.path == '/login') {
        next('/');
    } else if (token == null && to.path == '/login') {
        document.title = '环球英才网后台-登录';
        next()
    } else {
        document.title = '环球英才网后台'
        next()
    }
    // next();
})

export default router
