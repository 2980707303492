<template>
  <div class="xs-container">
    <p class="xs-title">专题中心数据</p>
<!--    <el-button size="mini" @click="whole()">全部下载</el-button>-->
    <el-table :data="positions" style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>
      <el-table-column prop="education" label="学历"></el-table-column>
      <el-table-column prop="major" label="专业"></el-table-column>

      <el-table-column label="简历附件">
        <template slot-scope="scope">
          <el-button size="mini" @click="download(scope.row.enclosure)">查看</el-button>

        </template>
      </el-table-column>

<!--      <el-table-column prop="enclosure" label="简历附件"></el-table-column>-->
      <el-table-column prop="t_id" label="专题ID"></el-table-column>
      <el-table-column prop="company" label="招聘单位"></el-table-column>
      <el-table-column prop="create_at" label="添加时间"></el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Special",
  data() {
    return {
      positions: [],
      meta: [],
      currentPage: 0
    }
  },
  methods: {
    getAllPositions() {
      this.axios.get('/specialData?page=' + this.currentPage).then(response => {
        console.log(response)
        this.positions = response.data.data
        this.meta['total'] = response.data.total*1
        this.meta['per_page'] = response.data.per_page*1
        this.meta['current_page'] = response.data.current_page*1
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllPositions()
    },
    download(src){
      window.location.href=src;
    },
    whole(){
      window.location.href=process.env.VUE_APP_BASE_URL+'/specialFile';

    }

  },
  created() {
    this.getAllPositions()
  }
}
</script>

<style scoped>
.xs-container {
  height: 100vh;
}

.xs-title {
  margin-bottom: 30px;
}


</style>
