<!--自定义表单（弃用）-->
<!-- CustomImage.vue -->
<template>
  <div class="custom-image-container">
    <el-upload
        class="avatar-uploader"
        :action=action
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
      <img v-if="imageData" :src="imageData" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    案例
    <el-image :src="imgUrl" :preview-src-list="[imgUrl]" class="since-img">
    </el-image>
    <button @click="download">下载</button>
  </div>
</template>

<script>
export default {
  name: 'myImage',
  props: {
    imgUrl: String,
    value:String,
    uploadUrl:String,
    downloadUrl:String,
  },
  data() {
    return {
      imageData: '' // 使用一个新的属性来存储图片数据
    };
  },
  computed: {
    action(){
      return this.uploadUrl;
    }
  },
  methods: {
    handleAvatarSuccess(res, ) {
      this.imageData = res; // 更新 imageData 而不是 value
      this.$emit('input', this.imageData);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return  isLt2M;
    },
    download(){
      console.log(this.downloadUrl)
    }

  },
};
</script>
<style>
.since-img{
  width: 100px;
  height: 100px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.custom-image-container{
  display: flex;
}
</style>
