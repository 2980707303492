<template>
    <div>
        <p class="xs-title">项目列表</p>
        <div style="margin-bottom: 20px">
            <el-input v-model="search.name" placeholder="项目名称" class="search-item"></el-input>
            <el-select v-model="search.industry_id" placeholder="项目领域" class="search-item">
                <el-option v-for="item in industry_title" :label="item.name" :value="item.id"
                           :key="item.id"></el-option>
            </el-select>
            <el-select v-model="search.follow_status" placeholder="跟进状态" class="search-item">
                <el-option v-for="item in follow_status" :label="item.title" :value="item.id"
                           :key="item.id"></el-option>
            </el-select>
            <el-cascader
                    class="search-item"
                    placeholder="请选择项目类型"
                    v-model="search.stage"
                    :options="stageList"
                    :props="{ expandTrigger: 'hover' ,value:'id',children: 'children',label:'title'}"></el-cascader>
            <div style="height: 5px;"></div>
            <el-select v-model="search.source" placeholder="项目上传来源" class="search-item">
                <el-option v-for="item in sourceList" :label="item.title" :value="item.id"
                           :key="item.id"></el-option>
            </el-select>
            <el-select v-model="search.site" placeholder="项目同步站点" class="search-item">
                <el-option v-for="item in siteList" :label="item.title" :value="item.id"
                           :key="item.id"></el-option>
            </el-select>

            <el-select v-model="search.is_expert" placeholder="是否有关联院士" class="search-item">
                <el-option label="关联" :value="1"></el-option>
                <el-option label="未关联" :value="0"></el-option>
            </el-select>
            <el-select v-model="search.status" placeholder="用户上传项目审核状态" class="search-item">
                <el-option label="待审核" :value="0"></el-option>
                <el-option label="审核通过" :value="1"></el-option>
                <el-option label="审核未通过" :value="2"></el-option>
            </el-select>
            <el-button style="margin-left: 20px;" type="primary" @click="searchData">筛选</el-button>
            <span style="font-size: 14px;margin-left: 10px;margin-top: 10px;vertical-align: bottom;cursor: pointer;"
                  @click="resetSearch">重置筛选条件</span>
        </div>
        <el-table :data="projects" style="width: 100%">
            <!--      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>-->
            <el-table-column prop="name" label="项目名称" width="400" :show-overflow-tooltip="true"></el-table-column>
            <!--      <el-table-column align="center" prop="tags" label="创孵首页推荐" width="100" min-width="60" show-overflow-tooltip>-->
            <!--        <template slot-scope="scope">-->
            <!--          <el-switch-->
            <!--              v-model="scope.row.is_incubation"-->
            <!--              active-color="#13ce66"-->
            <!--              inactive-color="#ff4949"-->
            <!--              @change="recommend(scope.row.id)"-->
            <!--              :active-value="1"-->
            <!--              :inactive-value="0"-->
            <!--          >-->
            <!--          </el-switch>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
            <el-table-column prop="industry_id" label="所属领域" width="150"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="s_industry" label="细分行业" width="150"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="follow_status" label="跟进状态" width="100"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="company_name" label="公司名称" width="250"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="main_team" label="创始人" width="100"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="round" label="当前融资阶段" width="150"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="status" label="状态" width="150"
                             :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 0">待审核</span>
                    <span v-if="scope.row.status === 1">审核通过</span>
                    <span v-if="scope.row.status === 2">未通过</span>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.source !== 0" size="mini" @click="showDetail(scope.row.id)">审核
                    </el-button>
                    <el-button v-else size="mini" @click="showDetail(scope.row.id)">查看</el-button>
                    <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="deleteProject(scope.$index, scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="limit"
                       :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
        </el-pagination>

    </div>
</template>

<script>
export default {
    name: "projectlib",
    data() {
        return {
            search: {
                name: '',
                industry_id: '',
                follow_status: '',
                stage: 0,
                source: '',
                is_expert: '',
                site: '',
                status: '',
            },
            projects: [],
            total: 0,
            currentPage: 1,
            limit: 10,
            industry_title: [],//专业领域
            stageList: [],
            follow_status: [{id: 0, title: '待跟进'}, {id: 1, title: '跟进中'}, {id: 2, title: '已跟进'}],
            sourceList: [
                {id: 5, title: '项目库'},
                {id: 1, title: '环球英才网'},
                {id: 2, title: '俄罗斯工程院中国中心'},
                {id: 3, title: '非洲科学院中国中心'},
                {id: 4, title: '环球英才交流促进会'},
            ],
            siteList: [
                {id: 1, title: '环球英才网'},
                {id: 2, title: '俄罗斯工程院中国中心'},
                {id: 3, title: '非洲科学院中国中心'},
                {id: 4, title: '环球英才交流促进会'},
            ],
        }
    },
    methods: {
        showDetail(id) {
            this.$router.push({
                path: `projectlib/detail?id=${id}`,
            });
        },
        resetSearch() {
            this.search = {
                name: '',
                industry_id: '',
                follow_status: '',
                stage: 0,
                source: '',
                is_expert: '',
                site: '',
                status: '',
            };
            this.getAllProjects();
        },
        searchData() {
            this.currentPage = 1;
            this.getAllProjects();
        },
        getIndustryTitle() {
            let that = this;
            this.axios({
                method: 'get',
                url: '/project/type',
            }).then(response => {
                if (response.data.code === 200) {
                    that.industry_title = response.data.data.list;
                }
            });
        },
        getStage() {
            let that = this;
            this.axios({
                method: 'get',
                url: '/project/stage',
            }).then(response => {
                if (response.data.code === 200) {
                    that.stageList = response.data.data.list;
                }
            });
        },
        getAllProjects() {
            let projectSearch = JSON.parse(JSON.stringify(this.search));
            sessionStorage.setItem('project_lib_search',JSON.stringify(projectSearch));
            let stage = this.search.stage;
            if (stage) {
                stage = stage[stage.length - 1]
            }
            let source = this.search.source;
            if (source === 5) {
                source = 0;
            }
            this.axios.get('/projectlib?page=' +
                this.currentPage +
                '&limit=' + this.limit +
                '&name=' + this.search.name +
                '&industry_id=' + this.search.industry_id +
                '&follow_status=' + this.search.follow_status +
                '&stage=' + stage +
                '&source=' + source +
                '&is_expert=' + this.search.is_expert +
                '&site=' + this.search.site +
                '&status=' + this.search.status
            ).then(response => {
                this.projects = response.data.data.list;
                this.total = response.data.data.count;
            })
        },
        getCurrentData(val) {
            this.currentPage = val;
            this.getAllProjects()
        },
        handleEdit(id) {
            this.$router.push({
                path: `projectlib/form?id=${id}`,
            });
        },
        deleteProject(index, id) {
            this.$confirm('是否确定删除该项目', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.post('/projectlib/delete', {id: id}).then(response => {
                    let type = 'success';
                    if (response.data.code !== 200) {
                        type = 'error';
                    }
                    this.$message({
                        message: response.data.msg,
                        type: type,
                        duration: 1000,
                        onClose: () => {
                            this.$router.go(0);
                        }
                    })
                })
            });
        },
        recommend(id) {
            this.axios.get('/projectlib/recommend?id=' + id).then(response => {
                console.log(response);
                let type = 'success';
                if (response.data.code !== 1) {
                    type = 'error';
                }
                this.$message({
                    message: response.data.msg,
                    type: type,
                    duration: 1000,
                    onClose: () => {
                        this.$router.go(0);
                    }
                })
            })
        }
    },
    created() {
        let search = sessionStorage.getItem('project_lib_search');
        if (search) {
            this.search = JSON.parse(search);
        }
        this.getIndustryTitle();
        this.getStage();
        this.getAllProjects()
    }
}
</script>

<style>

.xs-title {
    margin-bottom: 30px;
}

.el-pagination {
    margin-top: 30px;
}

a {
    text-decoration: none;
    color: inherit;
}

.search-item {
    width: 200px !important;
    /*margin-bottom: 5px;*/
    margin-right: 5px;
}
</style>
