<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <span>添加院士/专家</span>
      </div>
      <div class="expert-form">
        <el-form :model="expert" ref="expert" :rules="rules" label-width="100px" label-position="left">
          <div style="font-weight: bold;margin-bottom: 10px;">基本信息</div>
          <div style="margin-left: 15px;">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="expert.name"></el-input>
            </el-form-item>
            <el-form-item label="学术身份" prop="type">
              <el-select v-model="expert.type" placeholder="请选择学术身份" style="width: 100%;">
                <el-option label="院士" :value="1"></el-option>
                <el-option label="专家" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
              <el-radio-group v-model="expert.gender">
                <el-radio :label="1" border>男</el-radio>
                <el-radio :label="2" border>女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="国籍" prop="nationality">
              <el-input v-model="expert.nationality"></el-input>
            </el-form-item>
            <el-form-item label="籍贯" prop="native_place">
              <el-input v-model="expert.native_place"></el-input>
            </el-form-item>
            <el-form-item label="工作地址" prop="work_address">
              <el-input v-model="expert.work_address"></el-input>
            </el-form-item>
            <el-form-item label="出生日期" prop="birthday">
              <el-date-picker
                  style="width: 100%;"
                  v-model="expert.birthday"
                  type="month"
                  value-format="timestamp"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="证件照" prop="avatar">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="(file) => {basicPreview(file,'avatar')}"
                  :auto-upload="false">
                <div class="upload-file">
                  <img v-if="expert.avatar" :src="expert.avatar" class="avatar"
                       style="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="生活照">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="(file) => {basicPreview(file,'life_img')}"
                  :auto-upload="false">
                <div class="upload-file">
                  <img v-if="expert.life_img" :src="expert.life_img" class="avatar"
                       style="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="背景图片">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="(file) => {basicPreview(file,'bg_img')}"
                  :auto-upload="false">
                <div class="upload-file">
                  <img v-if="expert.bg_img" :src="expert.bg_img" class="avatar"
                       style="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="人物经历">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="basicVideo"
                  :auto-upload="false">
                <div class="upload-file">
                 <span v-if="expert.experience_video">{{expert.experience_video}}</span>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="expert.mobile"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="expert.email"></el-input>
            </el-form-item>
            <el-form-item label="专业领域" prop="industry">
              <el-select v-model="expert.industry" placeholder="请选择专业领域" style="width: 100%;" :multiple="true">
                <el-option v-for="item in industry_title" :label="item.name" :value="item.name"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="头衔" prop="title">
              <el-input v-model="expert.title"
                        placeholder="如：国家科技部新材料领域专家，如有多个请用英文逗号','分割填写；国家科技部新材料领域专家,新能源领域专家"></el-input>
            </el-form-item>
            <el-form-item label="学术称号" prop="academic_title">
              <el-select v-model="expert.academic_title" placeholder="请选择学术称号" style="width: 100%;" :multiple="true">
                <el-option v-for="item in academic_title" :label="item.value" :value="item.value"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="兴趣爱好" prop="hobby">
              <el-input v-model="expert.hobby" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="家庭背景" prop="family">
              <el-input v-model="expert.family" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="个人简介" prop="resume">
              <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="500"
                  placeholder="请输入个人简介"
                  v-model="expert.resume">
              </el-input>
            </el-form-item>
            <el-form-item label="人生格言">
              <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="500"
                  placeholder="请输入人生格言"
                  v-model="expert.life_motto">
              </el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="500"
                  placeholder="请输入备注信息"
                  v-model="expert.remark">
              </el-input>
            </el-form-item>
          </div>
          <div style="margin-bottom: 10px;">
            <span style="font-weight: bold;">学习经历</span>
            <span style="float: right;color: #409EFF;cursor: pointer" @click="addSchoolItem()">添加学习经历</span>
          </div>
          <div style="margin-left: 15px;">
            <div v-for="(item,index) in expert.school" :key="index" class="school">
              <i class="el-icon-error delete-item" v-if="expert.school.length > 1" @click="delSchoolItem(index)"></i>
              <div>
                <el-form-item label="开始时间" :prop="'school.' + index + '.start_time'" :rules="rules.school_start_time">
                  <el-date-picker
                      v-model="item.start_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="开始时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="学校Icon" :prop="'school.' + index + '.school_icon'" :rules="rules.school_icon">
                  <el-upload
                      class="avatar-uploader"
                      :action="upload.host"
                      :data="upload.formData"
                      :show-file-list="false"
                      :on-change="(file) => {schoolIconPreview(file,index)}"
                      :auto-upload="false">
                    <div class="upload-file">
                      <img v-if="item.school_icon" :src="item.school_icon" class="avatar"
                           style="">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                  </el-upload>
                </el-form-item>

              </div>
              <div>
                <el-form-item label="结束时间" prop="school_end_time">
                  <el-date-picker
                      v-model="item.end_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="结束时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="主修专业" :prop="'school.' + index + '.major'" :rules="rules.school_major">
                  <el-input v-model="item.major"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="学校名称" :prop="'school.' + index + '.school_name'" :rules="rules.school_name">
                  <el-input v-model="item.school_name"></el-input>
                </el-form-item>
                <el-form-item label="学历" :prop="'school.' + index + '.education'" :rules="rules.school_education">
                  <el-input v-model="item.education"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <div style="margin-bottom: 10px;margin-top: 20px;">
            <span style="font-weight: bold;">主要经历</span>
            <span style="float: right;color: #409EFF;cursor: pointer" @click="addExpItem('mainExp')">添加主要经历</span>
          </div>
          <div style="margin-left: 15px;">
            <div v-for="(item,index) in expert.mainExp" :key="index" class="school">
              <i class="el-icon-error delete-item" v-if="expert.mainExp.length > 1"
                 @click="delExpItem('mainExp',index)"></i>
              <div>
                <el-form-item label="开始时间" :prop="'mainExp.' + index + '.start_time'" :rules="rules.mainExp_start_time">
                  <el-date-picker
                      v-model="item.start_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="开始时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="就职单位" :prop="'mainExp.' + index + '.unit'" :rules="rules.mainExp_unit">
                  <el-input v-model="item.unit"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="结束时间" prop="mainExp_end_time">
                  <el-date-picker
                      v-model="item.end_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="结束时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="职位" :prop="'mainExp.' + index + '.position'" :rules="rules.mainExp_position">
                  <el-input v-model="item.position"></el-input>
                </el-form-item>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>

          <div style="margin-bottom: 10px;margin-top: 20px;">
            <span style="font-weight: bold;">主要任职</span>
            <span style="float: right;color: #409EFF;cursor: pointer" @click="addExpItem('jobs')">添加主要任职</span>
          </div>
          <div style="margin-left: 15px;">
            <div v-for="(item,index) in expert.jobs" :key="index" class="school">
              <i class="el-icon-error delete-item" v-if="expert.jobs.length > 1" @click="delExpItem('jobs',index)"></i>
              <div>
                <el-form-item label="开始时间" :prop="'jobs.' + index + '.start_time'" :rules="rules.jobs_start_time">
                  <el-date-picker
                      v-model="item.start_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="开始时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="就职单位" :prop="'jobs.' + index + '.unit'" :rules="rules.jobs_unit">
                  <el-input v-model="item.unit"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="结束时间" prop="jobs_end_time">
                  <el-date-picker
                      v-model="item.end_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="结束时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="职位" :prop="'jobs.' + index + '.position'" :rules="rules.jobs_position">
                  <el-input v-model="item.position"></el-input>
                </el-form-item>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>

          <div style="margin-bottom: 10px;margin-top: 20px;">
            <span style="font-weight: bold;">科技奖项</span>
            <span style="float: right;color: #409EFF;cursor: pointer" @click="addExpItem('awards')">添加科技奖项</span>
          </div>
          <div style="margin-left: 15px;">
            <div v-for="(item,index) in expert.awards" :key="index" class="school">
              <i class="el-icon-error delete-item" v-if="expert.awards.length > 1"
                 @click="delExpItem('awards',index)"></i>
              <div>
                <el-form-item label="获奖时间" :prop="'awards.' + index + '.start_time'" :rules="rules.awards_start_time">
                  <el-date-picker
                      v-model="item.start_time"
                      type="year"
                      value-format="timestamp"
                      placeholder="获奖时间">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="奖项名称" :prop="'awards.' + index + '.unit'" :rules="rules.awards_unit">
                  <el-input v-model="item.unit"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="奖项等级" :prop="'awards.' + index + '.position'" :rules="rules.awards_position">
                  <el-input v-model="item.position"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>


          <div style="margin-bottom: 10px;margin-top: 20px;">
            <span style="font-weight: bold;">荣誉称号</span>
            <span style="float: right;color: #409EFF;cursor: pointer" @click="addExpItem('honor')">添加荣誉称号</span>
          </div>
          <div style="margin-left: 15px;">
            <div v-for="(item,index) in expert.honor" :key="index" class="school">
              <i class="el-icon-error delete-item" v-if="expert.honor.length > 1"
                 @click="delExpItem('honor',index)"></i>
              <div>
                <el-form-item label="获得时间" :prop="'honor.' + index + '.start_time'" :rules="rules.honor_start_time">
                  <el-date-picker
                      v-model="item.start_time"
                      type="year"
                      value-format="timestamp"
                      placeholder="获得时间">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="荣誉称号" :prop="'honor.' + index + '.unit'" :rules="rules.honor_unit">
                  <el-input v-model="item.unit"></el-input>
                </el-form-item>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>


          <div style="margin-bottom: 10px;margin-top: 20px;">
            <span style="font-weight: bold;">学术成就</span>
            <span style="float: right;color: #409EFF;cursor: pointer" @click="addExpItem('academic')">添加学术成就</span>
          </div>
          <div style="margin-left: 15px;">
            <div v-for="(item,index) in expert.academic" :key="index" class="school">
              <i class="el-icon-error delete-item" v-if="expert.honor.length > 1"
                 @click="delExpItem('academic',index)"></i>
              <div>
                <el-form-item label="时间" :prop="'academic.' + index + '.start_time'" :rules="rules.academic_start_time">
                  <el-date-picker
                      v-model="item.start_time"
                      type="month"
                      value-format="timestamp"
                      placeholder="时间">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="学术成就" :prop="'academic.' + index + '.unit'" :rules="rules.academic_unit">
                  <el-input v-model="item.unit"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="成就类型" :prop="'academic.' + index + '.position'" :rules="rules.academic_position">
                  <el-select v-model="item.position" placeholder="请选择成就类型">
                    <el-option label="著作" value="著作"></el-option>
                    <el-option label="论文" value="论文"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>


          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="submit('expert')" :loading="subStatus" :disabled="subStatus">保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "expertForm",
  data() {

    return {
      subStatus: false,
      expert: {
        id: '',
        name: '',
        gender: '',
        nationality: '',
        native_place: '',
        work_address:'',
        birthday: '',
        mobile: '',
        email: '',
        title: '',
        hobby: '',
        family: '',
        resume: '',
        life_motto: '',
        remark: '',
        avatar: '',
        life_img: '',
        bg_img: '',
        experience_video: '',
        industry: [],
        academic_title: [],
        school: [
          // {start_time: '', end_time: '', school_name: '', major: '', education: '', school_icon: ''}
        ],
        mainExp: [
          // {start_time: '', end_time: '', position: '', unit: ''}
        ],
        jobs: [
          // {start_time: '', end_time: '', position: '', unit: ''}
        ],
        awards: [
          // {start_time: '', position: '', unit: ''}
        ],
        honor: [
          // {start_time: '', unit: ''}
        ],
        academic: [
          // {start_time: '', position: '', unit: ''}
        ],
      },
      rules: {
        name: {required: true, message: '请输入姓名', trigger: 'blur'},
        type: {required: true, message: '请选择类别', trigger: 'blur'},
        gender: {required: true, message: '请选择性别', trigger: 'blur'},
        nationality: {required: true, message: '请填写国籍', trigger: 'blur'},
        native_place: {required: true, message: '请填写籍贯', trigger: 'blur'},
        work_address: {required: true, message: '请填写工作地址', trigger: 'blur'},
        birthday: {required: true, message: '请选择出生日期', trigger: 'blur'},
        avatar: {required: true, message: '请上传证件照', trigger: 'change'},
        life_img: {required: true, message: '请上传生活照', trigger: 'change'},
        bg_img: {required: true, message: '请上传背景图片', trigger: 'change'},
        // mobile: {required: true, message: '请填写手机号', trigger: 'blur'},
        // email: {required: true, message: '请填写邮箱', trigger: 'blur'},
        industry: {required: true, message: '请选择专业领域', trigger: 'blur'},
        // title: {required: true, message: '请填写头衔', trigger: 'blur'},
        // academic_title: {required: true, message: '请选择学术称号', trigger: 'blur'},
        // hobby:{required: true, message: '请填写兴趣爱好', trigger: 'blur'},
        // family:{required: true, message: '请填写家庭背景', trigger: 'blur'},
        resume: {required: true, message: '请填写个人简介', trigger: 'blur'},
        life_motto: {required: true, message: '请填写人生格言', trigger: 'blur'},
        // remark:{required: true, message: '请填写备注信息', trigger: 'blur'},
        school_start_time: {required: true, message: '请选择学习开始时间', trigger: 'blur'},
        school_icon: {required: true, message: '请上传学校icon', trigger: 'change'},
        school_major: {required: true, message: '请输入主修专业', trigger: 'blur'},
        school_name: {required: true, message: '请输入学校名称', trigger: 'blur'},
        school_education: {required: true, message: '请输入学历', trigger: 'blur'},
        mainExp_start_time: {required: true, message: '请选择开始时间', trigger: 'blur'},
        mainExp_unit: {required: true, message: '请输入单位名称', trigger: 'blur'},
        mainExp_position: {required: true, message: '请输入职位名称', trigger: 'blur'},
        jobs_start_time: {required: true, message: '请选择开始时间', trigger: 'blur'},
        jobs_unit: {required: true, message: '请输入单位名称', trigger: 'blur'},
        jobs_position: {required: true, message: '请输入职位名称', trigger: 'blur'},
        awards_start_time: {required: true, message: '请选择获奖时间', trigger: 'blur'},
        awards_unit: {required: true, message: '请填写奖项名称', trigger: 'blur'},
        awards_position: {required: true, message: '请填写奖项等级', trigger: 'blur'},
        honor_start_time: {required: true, message: '请选择时间', trigger: 'blur'},
        honor_unit: {required: true, message: '请填写荣誉称号', trigger: 'blur'},
        academic_start_time: {required: true, message: '请选择时间', trigger: 'blur'},
        academic_unit: {required: true, message: '学术成就', trigger: 'blur'},
        academic_position: {required: true, message: '请选择成就类型', trigger: 'blur'},
      },
      academic_title: [
        {value: '中国工程院院士', id: 1},
        {value: '俄罗斯工程院外籍院士', id: 2},
        {value: '中国科学院院士', id: 3},
        {value: '美国国家科学院外籍院士', id: 4},
        {value: '国际欧亚科学院院士', id: 5},
        {value: '发展中国家科学院院士', id: 6},
      ],
      industry_title: [
        {name: '新一代信息技术', id: 1},
        {name: '人工智能', id: 2},
        {name: '软件和信息服务', id: 3},
        {name: '集成电路', id: 4},
        {name: '医药健康', id: 5},
        {name: '智能装备', id: 6},
        {name: '节能环保', id: 7},
        {name: '化学化工', id: 8},
        {name: '新能源', id: 9},
        {name: '新材料', id: 10},
        {name: '现代农业', id: 11},
        {name: '教育文化', id: 12},
        {name: '科技服务', id: 13},
        {name: '商务和金融服务', id: 14},
      ],
      upload: {
        host: '',
        formData: {}
      },
      uploadData: {
        school_icon: [],
        avatar: '',
        life_img: '',
        bg_img: '',
        experience_video:'',

      },
      aliOss: {},


    }

  },
  methods: {
    getAcademicTitle(){
      let that = this;
      this.axios({
        method: 'get',
        url: '/expert/expertTitle',
      }).then(response => {
        if (response.data.code === 200) {
          that.academic_title = response.data.data.list;
          that.getDetail();
        }
      });
    },
    getDetail() {
      let that = this;
      let param = this.$route.query;
      if (param.id) {
        this.axios({
          method: 'get',
          url: '/expert/detail?id=' + param.id,
        }).then(response => {
          if (response.data.code === 200) {
            that.expert = response.data.data.detail;
          }

        });
      }
    },
    type() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/projectType'
      }).then(response => {
        if (response.data) {
          that.industry_title = response.data;
        }

      });
    },
    async submit() {
      this.subStatus = true;

      let validateFlag = true;
      await this.$refs.expert.validate((valid) => {
        if (!valid) {
          validateFlag = false;
        }
      });
      if (!validateFlag) {
        this.subStatus = false;
        return false;
      }
      let that = this;
      await this.uploadFile();
      this.axios({
        method: 'post',
        url: '/expert/saveData',
        data: that.expert
      }).then(response => {
        that.subStatus = false;
        let type = 'success';
        if (response.data.code !== 200) {
          type = 'error';
        }
        that.$message({
          message: response.data.msg,
          type: type,
          duration: 1000,
          onClose: () => {
            this.$router.push('/expert');
          }
        })
      });
    },
    addSchoolItem() {
      this.expert.school.push({
        start_time: '',
        end_time: '',
        school_name: '',
        major: '',
        education: '',
        school_icon: ''
      });
    },
    delSchoolItem(index) {
      this.expert.school.splice(index, 1);
      this.uploadData.school_icon.splice(index, 1);
    },
    addExpItem(type) {
      if (type === 'mainExp' || type === 'jobs') {
        this.expert[type].push({start_time: '', end_time: '', position: '', unit: ''});
      }
      if (type === 'awards' || type === 'academic') {
        this.expert[type].push({start_time: '', position: '', unit: ''});
      }
      if (type === 'honor') {
        this.expert.honor.push({start_time: '', unit: ''});
      }
    },
    delExpItem(type, index) {
      this.expert[type].splice(index, 1);
    },
    schoolIconPreview(file, index) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.expert.school[index].school_icon = url;
      this.uploadData.school_icon[index] = file.raw;
    },
    basicPreview(file, type) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.expert[type] = url;
      this.uploadData[type] = file.raw;
    },
    basicVideo(file){
      let that = this;
      if (!/.(mp4)$/.test(file.name)) {
        this.$message.error('请上传格式为mp4视频');
        return '';
      }
      if (file.size > 104857600) {
        this.$message.error('请上传文件大小不超过100MB的视频');
        return;
      }
      that.expert.experience_video = file.name;
      that.uploadData.experience_video = file.raw;
    },
    async getAliOss() {
      const {data} = await this.axios.get('https://www.globaltalent.net/next/upload/webDirectOss?file_dir=expert/')
      return data;
    },

    async uploadFile() {
      if (this.uploadData) {
        let data = await this.getAliOss();
        for (let i in this.uploadData) {
          if (i === 'school_icon') {
            for (let index in this.uploadData[i]) {
              await this.uploadFileToAliOss(data, this.uploadData[i][index], 'school_icon', index);
            }
          } else {
            await this.uploadFileToAliOss(data, this.uploadData[i], i);
          }
        }
      }
    },

    async uploadFileToAliOss(data, file, type, index) {
      if (!file) {
        return;
      }
      let formData = new FormData();
      let random = this.common.random_string(32);
      let dir = data.dir + random.slice(0, 2) + '/';
      let key = dir + random + this.common.get_suffix(file.name);
      formData.append('key', key);
      formData.append('name', file.name);
      formData.append('policy', data.policy);
      formData.append('OSSAccessKeyId', data.accessid);
      formData.append('callback', data.callback);
      formData.append('signature', data.signature);
      formData.append('success_action_status', 200);
      formData.append('file', file);
      let header = {
        'Content-Type': 'multipart/form-data'
      };
      let that = this;
      await this.axios({
        method: 'post',
        url: data.host,
        data: formData,
        headers: header,
      }).then(response => {
        if (response.data.Status === 'Ok') {
          //将文件地址放到项目数据中
          let url = data.host + '/' + key;
          if (type === 'school_icon') {
            that.expert.school[index].school_icon = url;
          } else {
            that.expert[type] = url;
          }
        }
      }).catch(err => {
        console.log(err.response);
      });
    },
  },
  mounted() {
  },
  created() {
    this.getAcademicTitle();
    this.type();
  }
}
</script>

<style scoped>
.school {
  border: 1px #909399 dashed;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
}

.school .delete-item {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 20px;
  background-color: white;
}

.delete-item:hover {
  color: red;
}


</style>