<template>
<div>
  <p class="xs-title">职位直播专场-职位顺序</p>

  <el-table :data="positions" row-key="id">
    <el-table-column prop="id" label="ID" sortable  width="100px"></el-table-column>
    <el-table-column prop="job_name" label="直播专场职位名称" width="500px"></el-table-column>
<!--    <el-table-column prop="sort" label="1" width="50px"></el-table-column>-->
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button size="mini" @click="handleSee(scope.row.id,'top')" icon="el-icon-caret-top" v-if="scope.row.sort!=top"></el-button>
        <el-button size="mini" @click="handleSee(scope.row.id,'upper')" icon="el-icon-top"  v-if="scope.row.sort!=top"></el-button>
        <el-button size="mini" @click="handleSee(scope.row.id,'lower')" icon="el-icon-bottom"  v-if="scope.row.sort!=bottom"></el-button>
        <el-button size="mini" @click="handleSee(scope.row.id,'bottom')" icon="el-icon-caret-bottom"  v-if="scope.row.sort!=bottom"></el-button>
      </template>


    </el-table-column>
  </el-table>

</div>
</template>

<script>
import Sortable from 'sortablejs';
export default {
  name: "position",
  data() {
    return {
      positions: [],
      top:'',
      bottom:'',
    }
  },
  methods: {
    getPositions(){
      const id = this.$route.query.id;
      this.axios.get('/positionLive/position_c?id=' + id).then(response => {
        console.log(response)
        this.positions = response.data;
        this.top=response.data[0].sort;
        let len=response.data.length;
        this.bottom=response.data[len-1].sort;

      })
    },
    handleSee(id,style){
      this.axios.post('/positionLive/position_s',{
        id:id,
        style:style
      }).then(response => {
        console.log(response);
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.getPositions();
          }
        })
      })
    },
    rowDrop () {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector('.el-table__body-wrapper tbody');
      console.log(tbody);
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.positions.splice(oldIndex, 1)[0];
          _this.positions.splice(newIndex, 0, currRow);
          _this.handleSee_c();
        }
      });
    },
    handleSee_c(){
      this.axios.post('/positionLive/position_cody',this.positions).then(response => {
        console.log(response);
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.getPositions();
          }
        })
      })
    }
  },
  created() {
    this.getPositions();
  },
  mounted () {
    this.rowDrop();
  },
}
</script>

<style scoped>

</style>