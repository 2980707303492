<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}详情</span>
      </div>

      <div>
        <h3>{{ news.title }}</h3>
        <div class="news-info">
          <el-tag>
            <span>作者:</span>
            {{ news.author }}
          </el-tag>

          <el-tag>
            <span>创建时间:</span>
            {{ news.time }}
          </el-tag>

          <el-tag>
            <span>ip:</span>
            {{ news.ip_area }}
          </el-tag>

          <el-tag>
            <span>分类:</span>
            <span class="tag" v-for="(tag,index) in news.tag" :key="index">{{ tag.name }}</span>
          </el-tag>
        </div>
        <div v-if='news.content' v-html="news.content"></div>
        <div v-else>
          <div>{{ news.desc }}</div>
          <div class="app-header">
            <template v-if="isLoading">
              Loading...
            </template>

            <template v-else>
                    <span v-if="showAllPages">
                        {{ pageCount }} page(s)
                    </span>
              <span v-else>
                        <el-button class="btn" :disabled="page <= 1" @click="page--">❮</el-button>
                        {{ page }} / {{ pageCount }}
                        <el-button class="btn" :disabled="page >= pageCount" @click="page++">❯</el-button>
                    </span>
              <label class="right"><input v-model="showAllPages" type="checkbox">显示所有页面</label>
            </template>
          </div>

          <div class="app-content">
            <vue-pdf-embed ref="pdfRef" :source="news.pdf ? news.pdf:''" :page="page" @rendered="handleDocumentRender"/>
          </div>
          <div class="pages">
                <span v-if="showAllPages">
                            {{ pageCount }} page(s)
                        </span>
            <span v-else>
              <el-button class="btn" type="primary" :disabled="page <= 1" @click="page--">❮</el-button>
                    {{ page }} / {{ pageCount }}
              <el-button class="btn" type="primary" :disabled="page >= pageCount" @click="page++">❯</el-button>
                </span>
          </div>

        </div>
      </div>
      <div style="border-top: 1px solid #333;margin-bottom: 50px">
        评论
        <div v-for="(item,key) in news.comment" :key="key" style="margin-top: 25px">
          用户：{{ item.users_id }}---{{ item.name }}-----评论id:{{ item.id }}---内容：{{ item.content }}
          <el-button type="danger" icon="el-icon-delete" circle style="float: right;" @click="del_comment(item.id)"></el-button>
          <div v-for="(items,keys) in item._child" :key="keys" style="margin-top: 20px">
            ------用户：{{ items.users_id }}---{{ items.name }}----回复用户{{ items.users_comment_id }}--{{ items.comment_name }}
            内容：{{ items.content }}
            <el-button type="danger" icon="el-icon-delete" circle style="float: right;" @click="del_comment(items.id)"></el-button>
          </div>

        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'


export default {
  name: "CommonDetail",
  data() {
    return {
      news: {},
      isLoading: true,
      page: 1,
      pageCount: 1,
      showAllPages: false,
    }
  },
  components: {
    VuePdfEmbed
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    },
  },
  methods: {
    getNews() {
      const id = this.$route.query.id
      this.axios.get('/news/' + id).then(response => {
        const data = response.data
        this.news = data
      })
    },
    handleDocumentRender() {
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    },
    del_comment(id){
      console.log(id);
      this.$confirm('此操作将删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('/news/del_comment', {id:id}).then(response => {
          console.log(response);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getNews();
        })



      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    }
  },
  created() {
    this.getNews()
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.news-info {
  margin: 20px 0;
  color: #999999;
}

.news-info span {
  color: #333333;
}

.news-info .el-tag {
  margin-right: 30px;
  color: #999999;
}

canvas {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-content {
  padding: 24px 16px;
}

.right {
  margin-bottom: 0;
}

.right input {
  margin-right: 5px;
}

.btn {
  margin: 0 5px 0 0;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.tag {
  margin-left: 10px;
  color: #999999;
}
</style>
