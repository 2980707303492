<template>
  <div>
    <p class="xs-title">所有项目</p>
    <el-table :data="projects" style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="projectname" label="项目名称" width="600" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="user_name" label="所属人" width="250" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="email" label="联系邮箱" width="250" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popover
              :ref="`popover-`+ scope.row.id"
              placement="top"
              width="160"
          >
            <p>是否通过并发布该项目内容？</p>
            <br>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="danger" @click="handleAudit(scope.$index, scope.row.id,2)">拒绝</el-button>
              <el-button type="primary" size="mini" @click="handleAudit(scope.$index, scope.row.id,1)">通过</el-button>
            </div>
            <el-button size="mini" style="margin-right: 10px;" slot="reference" v-if="scope.row.audit_status === 0">审核
            </el-button>
          </el-popover>
          <el-button size="mini" @click="gotoDetail(scope.row.id)">查看</el-button>
<!--          <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>-->
          <el-button size="mini" type="danger" @click="deleteProject(scope.$index, scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Project",
  data() {
    return {
      projects: [],
      meta: [],
      currentPage: 0,
    }
  },
  methods: {
    getAllProjects() {
      this.axios.get('/projects?page=' + this.currentPage).then(response => {
        this.projects = response.data.data
        this.meta = response.data.meta
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllProjects()
    },
    gotoDetail(id) {
      this.axios.get('/projects/getShowUrl?uid=' + id).then((response) => {
        if (response.data.code === 1) {
          window.open(response.data.url);
        }
      });
    },
    handleEdit(id) {
      this.$router.push({
        path: 'projects/edit',
        query: {
          id
        }
      })
    },
    deleteProject(index, id) {
      this.$confirm('是否确定删除该项目', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/projects/' + id).then(response => {
          let type = 'success';
          if (response.data.code != 1) {
            type = 'error';
          }
          this.$message({
            message: response.data.msg,
            type: type,
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      });
    },
    handleAudit(index, id, status) {
      let that = this;
      for (const key in that.$refs) {
        if (key.indexOf('popover-') !== -1 && id !== key) {
          that.$refs[key].doClose();
        }
      }
      if (status === 2) {
        this.$prompt('请项目未通过原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({value}) => {
          that.audit(index, id, status, value);
        });
      } else {
        that.audit(index, id, status);
      }
    },
    audit(index, id, status, reason) {
      let that = this;
      that.axios.post('/projects/audit', {id: id, status: status, reason: reason}).then(response => {
        if (response.data.code === 1) {
          if (that.projects[index].id === id) {
            that.projects[index].audit_status = status;
          } else {
            for (let i in that.projects) {
              if (that.projects[i].id === id) {
                that.projects[i].audit_status = status;
              }
            }
          }
        }
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
    }
  },
  created() {
    this.getAllProjects()
  }
}
</script>

<style scoped>

.xs-title {
  margin-bottom: 30px;
}

.el-pagination {
  margin-top: 30px;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
