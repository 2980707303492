<template>
  <div class="xs-container">
    <p class="xs-title">机构--{{name}}</p>
    <el-select v-model="value" placeholder="请选择">
      <el-option

          v-for="item in type"

          :key="item.id"
          :label="item.name"
          :value="item.id">
      </el-option>
    </el-select>

    <el-button type="primary" @click="case_add">添加</el-button>


    <el-table :data="positions">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="name" label="公司名称" width="300"></el-table-column>
      <el-table-column label="logo">

        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="href" label="链接"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">

          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "Case",
  data() {
    return {
      positions: [],
      name:'',
      type:[],
      value:'',
      m_id:'',
    }
  },
  methods: {
    getAllPositions() {
      this.m_id = this.$route.query.id
      this.axios.get('/mechanism/caseList/' + this.m_id).then(response => {
        console.log(response)
        this.positions = response.data.list
        this.name=response.data.data.name
        this.type=response.data.type
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllPositions()
    },
    handleDelete(d_id){
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.axios.post('/mechanism/caseListDel',{d_id:d_id}).then(response => {
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      console.log(d_id)
    },
    case_add(){
      if (this.value){

        this.axios.post('/mechanism/cAdd',{m_id:this.m_id,c_id:this.value}).then(response => {
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
        console.log(this.value);
        console.log(this.m_id);
      }else{
        this.$message({
          message: '请选择要添加的企业',
          type: 'error',
          duration: 1000,

        })
      }
    }
  },
  created() {
    this.getAllPositions()
  }
}
</script>

<style scoped>
.xs-container {
  height: 100vh;
}

.xs-title {
  margin-bottom: 30px;
}


</style>
