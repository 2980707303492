<template>
    <div>
        <p class="xs-title">
            报名情况
            <span style="position: absolute;right:0;margin-right: 20px;">
            <el-button @click="exportUserSignUp()">数据导出
            </el-button>
            <el-button @click="signSMS()">发送签到提醒短信
            </el-button>
            </span>
        </p>
        <el-table :data="userActivitiesInfo.data" style="width: 100%">

            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item v-for="(value,key) in props.row.user_info" :key="key" :label="key">
                            <span>{{ value }}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>

            <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
            <el-table-column prop="user_info.姓名" label="姓名"></el-table-column>
            <el-table-column prop="user_info.单位" label="单位"></el-table-column>
            <el-table-column prop="user_info.职务" label="职务"></el-table-column>
            <el-table-column prop="user_info.手机" label="手机"></el-table-column>
            <el-table-column prop="user_info.邮箱" label="邮箱"></el-table-column>
            <el-table-column prop="created_at" label="报名时间"></el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-popover
                            :ref="`popover-`+ scope.row.id"
                            placement="top"
                            width="160"
                            v-if="scope.row.status<5"
                    >
                        <p>是否允许该用户参与会议？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text" @click="handleAudit(scope.$index, scope.row.id,4)">拒绝
                            </el-button>
                            <el-button type="primary" size="mini" @click="handleAudit(scope.$index, scope.row.id,3)">
                                允许
                            </el-button>
                        </div>
                        <el-button size="mini" type="primary" slot="reference">
                            {{ scope.row.status === 3 ? '已通过' : '' }}
                            {{ scope.row.status === 1 || scope.row.status === 2 ? '审核' : '' }}
                            {{ scope.row.status === 4 ? '已拒绝' : '' }}
                        </el-button>
                    </el-popover>
                    <el-button size="mini" type="primary" v-else>已参会</el-button>

                    <el-button style="margin-left: 10px;" size="mini" type="danger"
                               @click="handleDelete(scope.$index, scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="userActivitiesInfo.total"
                       :page-size="userActivitiesInfo.per_page"
                       :current-page="userActivitiesInfo.current_page" :hide-on-single-page="true"
                       @current-change="getCurrentData">
        </el-pagination>


        <el-dialog title="发送签到提醒短信" :visible.sync="signSMSDialog" :close-on-click-modal="false" width="750px">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <el-form :model="signSMSDialogData" label-width="100px" :rules="signSMSDialogDataRule" ref="venueDialog"
                         style="width: 500px;">
                    <el-form-item label="会议时间" prop="venue_name">
                        <el-date-picker
                                style="width: 100%;"
                                v-model="signSMSDialogData.time"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="会议主题" prop="venue_name">
                        <el-input v-model="signSMSDialogData.title" placeholder="请填写会议主题"></el-input>
                    </el-form-item>
                    <el-form-item label="会议地点" prop="venue_name">
                        <el-autocomplete
                                style="width: 100%;"
                                v-model="signSMSDialogData.address"
                                :fetch-suggestions="querySearch"
                                placeholder="请填写会议地点"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="签到时间" prop="venue_name">
                        <el-time-select
                                style="width: 100%;"
                                v-model="signSMSDialogData.signtime"
                                :picker-options="{
                                    start: '06:00',
                                    step: '00:15',
                                    end: '22:00'
                                  }"
                                placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                </el-form>
                <div style="width: 155px;">
                    【环球英才】您已成功报名{{ signSMSDialogData.time }}举行的{{ signSMSDialogData.title }}，
                    地点：{{ signSMSDialogData.address }}，签到时间：{{ signSMSDialogData.signtime }}，
                    请带上您的签到码并准时参加活动，我们期待您的到来！有问题请致电：010-68942688
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="signSMSDialog = false">取 消</el-button>
                <el-button type="primary" @click="sendSignSMS">发送短信</el-button>
            </div>
        </el-dialog>

    </div>


</template>

<script>


export default {
    name: "SignUp",
    data() {
        return {
            num: 0,
            userActivitiesInfo: {},
            currentPage: 1,
            fapi: null,
            rule: [],
            option: null,
            signSMSDialog: false,
            signSMSDialogData: {},
            signSMSDialogDataRule: {},
            restaurants: [
                {value: '中关村大街22号中科大厦A座一层“聚英会堂”'},
                {value: '中关村大街22号中科大厦A座12层'},
            ],
        }
    },
    methods: {
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        sendSignSMS() {
            const activityId = this.$route.query.id;
            let that = this;
            that.axios.post('/activities/sign-up-info/sendSms', {
                id: activityId,
                sms_param: that.signSMSDialogData
            }).then(response => {
                let type = 'success'
                if (response['data']['code'] === 0) {
                    type = 'error'
                }
                that.$message({
                    message: response['data']['msg'],
                    type: type,
                    duration: 2000,
                })
                that.signSMSDialog = false;
            });
        },
        signSMS() {
            const activityId = this.$route.query.id;
            let that = this;
            //获取当前活动信息
            this.axios.get('/activities/' + activityId).then(response => {
                let signtime = that.reduceDateTime(response.data.sub_start_time);
                that.signSMSDialogData = {
                    time: response.data.sub_start_time,
                    title: response.data.sub_title,
                    address: response.data.sub_address,
                    signtime: signtime,
                };
                that.signSMSDialog = true;
            })
        },
        reduceDateTime(dateStr, ttl) {
            ttl = ttl ? ttl : 0 - 1800 * 1000 * 2;
            let dt = new Date(dateStr.replace(/-/, "/"));
            let ndt = new Date(dt.getTime() + ttl);
            let hour = parseInt(ndt.getHours());
            let minutes = parseInt(ndt.getMinutes());
            if (hour < 10) {
                hour = '0' + hour;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            return hour + ':' + minutes;
        },
        exportUserSignUp() {
            const activityId = this.$route.query.id;
            window.location.href = process.env.VUE_APP_BASE_URL + '/export/activitySignUp?id=' + activityId;
        },
        getActivitySignUpInfo() {
            const activityId = this.$route.query.id;
            this.axios.get('/activities/sign-up-info/' + activityId + '?page=' + this.currentPage).then(response => {
                this.userActivitiesInfo = response.data
            })
        },
        getCurrentData(val) {
            this.currentPage = val;
            this.getActivitySignUpInfo()
        },

        handleDelete(index, id) {
            let type = 'success'
            this.axios.delete('/activities/sign-up-info/' + id).then(response => {
                if (response['data']['code'] === 0) {
                    type = 'error'
                }
                this.$message({
                    message: response['data']['msg'],
                    type: type,
                    duration: 2000,
                    onClose: () => {
                        this.$router.go(0);
                    }
                })
            })
        },
        handleAudit(index, id, status) {
            let that = this;
            for (const key in that.$refs) {
                if (that.$refs[key] === undefined) {
                    continue;
                }
                if (key.indexOf('popover-') !== -1 && id !== key) {
                    that.$refs[key].doClose();
                }
            }
            that.axios.post('/activities/sign-up-info/audit', {id: id, status: status}).then(response => {
                if (response.data.code === 1) {
                    if (that.userActivitiesInfo.data[index].id === id) {
                        that.userActivitiesInfo.data[index].status = status;
                    } else {
                        for (let i in that.userActivitiesInfo.data) {
                            if (that.userActivitiesInfo.data[i].id === id) {
                                that.userActivitiesInfo.data[i].status = status;
                            }
                        }
                    }
                }
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            });
            this.getActivitySignUpInfo();

        }

    },
    created() {
        this.getActivitySignUpInfo()
    }
}
</script>

<style scoped>
.demo-table-expand {
    font-size: 0;
    display: flex;
    flex-direction: column;
}

/deep/ .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
