<template>
    <div>
        <p class="xs-title">所有活动</p>
        <el-table :data="activities.data" style="width: 100%">
            <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
            <el-table-column prop="sub_title" label="会议名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="sub_start_time" label="会议时间" width="180"></el-table-column>
            <el-table-column label="轮播展示" width="100">
                <template v-slot="scope">
                    <el-switch
                            v-model="scope.row.is_banner"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-value="1"
                            :inactive-value="0"
                            @change="c_banner(scope.row.id)"
                    >
                    </el-switch>

                </template>
            </el-table-column>
            <el-table-column label="报名情况" width="100">
                <template v-slot="scope">
                    <el-button type="text" @click="showSignUpInfo(scope.$index, scope.row.id)">查看</el-button>
                </template>
            </el-table-column>

            <el-table-column label="推流地址" width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="streaming( scope.row.id)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="报名地址" width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="check(scope.row.sub_address)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
                    <el-button size="mini" @click="statistics( scope.row.id, scope.row.sub_title)">流量数据</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row.id)">删除
                    </el-button>
                    <el-button size="mini" type="danger" @click="changeStatus(scope.row.id, scope.row.status)">{{
                        scope.row.status
                        === 1 ? '下线' : '上线'
                        }}
                    </el-button>
                    <!--          <input v-if="scope.row.id === 108" type="file" class="upload_file" @change="importSignUp($event, scope.row.id)"/>-->
                </template>
            </el-table-column>

        </el-table>

        <el-dialog title="推流地址" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
                <el-table-column property="venue_name" label="会场名称" width="150"></el-table-column>
                <el-table-column property="title" label="直播/视频标题" width="150"></el-table-column>
                <el-table-column property="push_url" label="推流地址"></el-table-column>
            </el-table>
        </el-dialog>
        <el-pagination background layout="prev, pager, next" :total="activities.total" :page-size="activities.per_page"
                       :current-page="activities.current_page" :hide-on-single-page="true"
                       @current-change="getCurrentData">
        </el-pagination>

        <el-dialog title="推流地址" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
                <el-table-column property="venue_name" label="会场名称" width="150"></el-table-column>
                <el-table-column property="title" label="直播/视频标题" width="150"></el-table-column>
                <el-table-column property="push_url" label="推流地址"></el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :title="echarts_title" :visible.sync="statisticsVisible" :fullscreen="true"
                   @close="echartsIntervalClose = !echartsIntervalClose">
            <echarts-activity :type="1" :id="echarts_id" :interval-close="echartsIntervalClose"></echarts-activity>
        </el-dialog>
    </div>
</template>

<script>
import XLSX from 'xlsx'
import EchartsActivity from "@/components/statistics/activity.vue";


export default {

    name: "Activity",
    components: {EchartsActivity},
    data() {

        return {
            activities: {},
            currentPage: 1,
            file: null,
            statusText: '',
            gridData: {},
            dialogTableVisible: false,
            statisticsVisible: false,
            echarts_id: 0,
            echarts_title: '',
            echartsIntervalClose: false,
        }
    },
    methods: {
        statistics(id, title) {
            this.echarts_id = id;
            this.echarts_title = title + ' 实时数据统计(分钟)';
            this.statisticsVisible = true;
        },
        c_banner(c_id) {

            this.axios.get('/activities/banner/' + c_id).then(response => {
                this.$message({
                    message: response.data.msg,
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                        this.getAllActivitiesData();
                    }
                })
            })
        },
        getAllActivitiesData() {
            this.axios.get('/activities?page=' + this.currentPage).then(response => {

                this.activities = response.data;

            })
        },

        getCurrentData(val) {
            this.currentPage = val;
            this.getAllActivitiesData()
        },

        handleEdit(index, id) {
            this.$router.push({
                path: '/activities/form',
                query: {
                    id
                }
            })
        },

        handleDelete(index, id) {
            this.$confirm('是否确定删除该活动', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.delete('/activities/' + id).then(response => {
                    this.$message({
                        message: response.data.msg,
                        type: 'success',
                        duration: 1000,
                        onClose: () => {
                            this.$router.go(0);
                        }
                    })
                })
            });

        },

        showSignUpInfo(index, id) {
            this.axios.get('/activities/sign-up-info/' + id + '/count').then(response => {
                if (response.data === 0) {
                    this.$message({
                        message: '该活动暂无报名信息',
                        type: 'success',
                        duration: 2000
                    })
                } else {
                    this.$router.push({
                        path: 'activities/sign-up',
                        query: {
                            id
                        }
                    })
                }
            })
        },
        check(txt) {
            this.$message({
                message: '活动地址：' + txt,
                type: 'success',
                duration: 2000
            })
        },
        streaming(c_id) {
            this.axios.get(`/activities/streaming/` + c_id).then(response => {
                if (response.data.code === 1) {
                    this.gridData = response.data.data.list
                    this.dialogTableVisible = true
                } else {
                    this.$message({
                        message: response.data.msg,
                        type: 'error',
                        duration: 2000
                    })
                }
            })
        },
        importSignUp(e, id) {
            const files = e.target.files[0]
            const fileReader = new FileReader()

            fileReader.onload = ev => {
                try {
                    let insertData = []

                    const data = ev.target.result
                    const workbook = XLSX.read(data, {
                        type: 'binary'
                    })
                    const wsname = workbook.SheetNames[0] // 取第一张表
                    const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]) // 生成json表格内容

                    const wsString = JSON.stringify(ws)
                    const newWs = JSON.parse(wsString.replace(/\\r\\n/g, ''))
                    newWs.map(item => {
                        const tempData = {
                            'activity_id': id,
                            'user_info': JSON.stringify(item)
                        }
                        insertData.push(tempData)
                    })

                    this.axios.post('/activities/sign-up-info/import', {
                        data: insertData
                    }).then(response => {
                        if (response.data === 0) {
                            this.$message({
                                message: '导入失败',
                                type: 'error',
                                duration: 2000
                            })
                        } else {
                            this.$message({
                                message: '导入成功',
                                type: 'success',
                                duration: 2000
                            })
                        }
                    })


                } catch (e) {
                    return false
                }
            }
            fileReader.readAsBinaryString(files)
        },

        changeStatus(id, status) {
            this.axios.patch(`/activities/status/${id}`, {
                data: status === 1 ? 0 : 1
            }).then(response => {
                this.$message({
                    message: response['data']['msg'],
                    type: 'success',
                    duration: 2000,
                    onClose: () => {
                        this.$router.go(0);
                    }
                })
            })
        }
    },
    created() {
        this.getAllActivitiesData();
    }
}
</script>

<style scoped>
</style>
