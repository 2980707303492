<template>
  <el-aside :width="asideWidth">
    <el-menu
        default-active="2"
        class="el-menu-vertical-demo" background-color="RGB(39, 48, 69)" text-color="RGB(204, 204, 204)"
        active-text-color="RGB(204, 204, 204)"
        :unique-opened="true"
        :router="true"
        :collapse="isCollapse"
        :collapse-transition="false"
    >
      <div class="shrink" @click="changeShrinkStatus">| | |</div>
      <el-submenu :index="sideBar.id + ''" v-for="sideBar in sideBars" :key="sideBar.id">
        <template slot="title">
          <i :class="sideBar.icon"></i>
          <span>{{ sideBar.name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="item in sideBar.children" :key="item.id" :index="'/' + item.path">{{ item.name }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      sideBars: [
        {
          id: 1,
          name: '会议管理',
          icon: 'iconfont icon-huodong',
          children: [
            {
              id: 101,
              name: '会议列表',
              path: 'activities',
              icon: 'iconfont'
            },
            // {
            //   id: 102,
            //   name: '添加会议',
            //   path: 'activities/add',
            //   icon: 'iconfont'
            // },
              {
                  id: 103,
                  name: '添加会议',
                  path: 'activities/form',
                  icon: 'iconfont'
              }
          ]
        },
        {
          id: 2,
          name: '职位管理',
          icon: 'iconfont  icon-zhiwei',
          children: [
            {
              id: 201,
              name: '职位列表',
              path: 'positions',
              icon: 'iconfont'
            },
            {
              id: 205,
              name: '职位审核',
              path: 'position/auditing',
              icon: 'iconfont'
            },
            {
              id: 202,
              name: '职位直播专场',
              path: 'positionLive',
              icon: 'iconfont'
            },
            {
              id: 203,
              name: '添加职位直播专场',
              path: 'positionLive/form',
              icon: 'iconfont'
            },
            {
              id: 204,
              name: '职位顶部轮播图',
              path: 'position/positionsImg',
              icon: 'iconfont'
            },
          ]
        },{
              id: 18,
              name: '路演管理',
              icon: 'el-icon-notebook-2',
              children: [
                  {
                      id: 1801,
                      name: '路演列表',
                      path: 'roadshow',
                      icon: 'iconfont'
                  },
                  {
                      id: 1802,
                      name: '添加路演活动',
                      path: 'roadshow/form',
                      icon: 'iconfont'
                  },
                  {
                      id: 1803,
                      name: '项目征集',
                      path: 'roadshow/collect',
                      icon: 'iconfont'
                  }
              ]
          },
          {
          id: 15,
          name: '院士专家库',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1501,
              name: '院士专家列表',
              path: 'expert',
              icon: 'iconfont'
            },
            {
              id: 1502,
              name: '添加院士/专家',
              path: 'expert/form',
              icon: 'iconfont'
            },

          ]
        },
        {
          id: 16,
          name: '项目库',
          icon: 'iconfont icon-ziyuan',
          children: [
            {
              id: 1501,
              name: '项目列表',
              path: 'projectlib',
              icon: 'iconfont'
            },
            {
              id: 1502,
              name: '添加项目',
              path: 'projectlib/form',
              icon: 'iconfont'
            }
          ]
        },
        // {
        //   id: 5,
        //   name: '话题管理',
        //   icon: 'iconfont icon-huati',
        //   children: [
        //     {
        //       id: 501,
        //       name: '话题列表',
        //       path: 'topics',
        //       icon: 'iconfont'
        //     },
        //     {
        //       id: 502,
        //       name: '审核话题',
        //       path: 'topics/check',
        //       icon: 'iconfont'
        //     }
        //   ]
        // },
        {
          id: 6,
          name: '用户管理',
          icon: 'iconfont icon-user',
          children: [
            {
              id: 601,
              name: '用户列表',
              path: 'users',
              icon: 'iconfont'
            },
            {
              id: 602,
              name: '发送邮件',
              path: 'users/email',
              icon: 'iconfont'
            },
              {
                  id: 603,
                  name: '数据统计',
                  path: 'users/total',
                  icon: 'iconfont'
              }
          ]
        },
        {
          id: 7,
          name: '资讯管理',
          icon: 'el-icon-reading',
          children: [
            {
              id: 701,
              name: '资讯列表',
              path: 'news',
              icon: 'iconfont'
            },
            {
              id: 702,
              name: '添加资讯',
              path: 'news/add',
              icon: 'iconfont'
            },
            {
              id: 703,
              name: '企业咨讯审核',
              path: 'news/approval',
              icon: 'iconfont'
            },
            {
              id: 704,
              name: '标签列表',
              path: 'news/tags',
              icon: 'iconfont'
            },
            {
              id: 705,
              name: '添加标签',
              path: 'news/tags/add',
              icon: 'iconfont'
            },

          ]
        },
        {
          id: 17,
          name: '话题管理',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1701,
              name: '话题列表',
              path: 'news/topic',
              icon: 'iconfont'
            },
            {
              id: 1702,
              name: '添加话题',
              path: 'news/topicAdd',
              icon: 'iconfont'
            },
          ]
        },
        {
          id: 8,
          name: '政策管理',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 801,
              name: '政策列表',
              path: 'policy',
              icon: 'iconfont'
            },
            {
              id: 802,
              name: '添加政策',
              path: 'policy/add',
              icon: 'iconfont'
            },
            {
              id: 803,
              name: '标签列表',
              path: 'policy/tags',
              icon: 'iconfont'
            },
            {
              id: 804,
              name: '添加标签',
              path: 'policy/tags/add',
              icon: 'iconfont'
            }
          ]
        },
        {
          id: 9,
          name: '揭榜挂帅',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 901,
              name: '揭榜挂帅列表',
              path: 'bang',
              icon: 'iconfont'
            },
            {
              id: 902,
              name: '发布揭榜挂帅',
              path: 'bang/form',
              icon: 'iconfont'
            },
            {
              id: 903,
              name: '揭榜挂帅审核',
              path: 'bang/approval',
              icon: 'iconfont'
            }
          ]
        },
        {
          id: 10,
          name: '企业信息',
          icon: 'el-icon-notebook-2',
          children: [
            // {
            //   id: 1001,
            //   name: '企业信息审批',
            //   path: 'enterprise',
            //   icon: 'iconfont'
            // },
            {
              id: 1004,
              name: '企业资质审批',
              path: 'enterprise/auditing',
              icon: 'iconfont'
            },
            // 自定义表单
            // {
            //   id: 1002,
            //   name: '认证列表',
            //   path: 'formAgency/index',
            //   icon: 'iconfont'
            // },
            // {
            //   id: 1003,
            //   name: '添加认证',
            //   path: 'formAgency/save',
            //   icon: 'iconfont'
            // },
          ]
        },
        {
          id: 11,
          name: '标签管理',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1101,
              name: '单位福利',
              path: 'label',
              icon: 'iconfont'
            },
            {
              id: 1102,
              name: '添加单位福利',
              path: 'label/labelAdd',
              icon: 'iconfont'
            },
            {
              id: 1103,
              name: '职位管理',
              path: 'label/position',
              icon: 'iconfont'
            },
            {
              id: 1104,
              name: '添加职位',
              path: 'label/positionAdd',
              icon: 'iconfont'
            },
            {
              id: 1105,
              name: '公司类型管理',
              path: 'label/enterprise',
              icon: 'iconfont'
            },
            {
              id: 1106,
              name: '添加公司类型',
              path: 'label/enterpriseAdd',
              icon: 'iconfont'
            },
          ]
        },
        {
          id: 12,
          name: '创孵管理',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1206,
              name: '创孵首页顶部轮播图',
              path: 'mechanism/rotation',
              icon: 'iconfont'
            },
            {
              id: 1201,
              name: '创投机构',
              path: 'mechanism',
              icon: 'iconfont'
            },
            {
              id: 1202,
              name: '添加创投机构',
              path: 'mechanism/add',
              icon: 'iconfont'
            },
            {
              id: 1203,
              name: '案例企业',
              path: 'case',
              icon: 'iconfont'
            },
            {
              id: 1204,
              name: '添加案例企业',
              path: 'case/add',
              icon: 'iconfont'
            },
            // {
            //   id: 1205,
            //   name: '创孵顶部轮播',
            //   path: 'mechanism/incubation',
            //   icon: 'iconfont'
            // },
            {
              id: 1205,
              name: '创投机构审核',
              path: 'mechanism/settled',
              icon: 'iconfont'
            },
          ]
        },
        {
          id: 13,
          name: '专题中心',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1301,
              name: '专题中心',
              path: 'special',
              icon: 'iconfont'
            },
            {
              id: 1302,
              name: '添加专题',
              path: 'special/add',
              icon: 'iconfont'
            },
            {
              id: 1303,
              name: '专题页面数据',
              path: 'special/dataList',
              icon: 'iconfont'
            },
          ]
        },
        {
          id: 14,
          name: '问题反馈',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1401,
              name: '问题列表',
              path: 'feedback',
              icon: 'iconfont'
            },
            {
              id: 1402,
              name: '文章反馈列表',
              path: 'feedbackNew',
              icon: 'iconfont'
            }
          ]
        },{
          id: 19,
          name: '首页推荐',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 1901,
              name: '院士专家首页推荐',
              path: 'recommend/e_recommend',
              icon: 'iconfont'
            },
            {
              id: 1902,
              name: '职位首页推荐',
              path: 'recommend/p_recommend',
              icon: 'iconfont'
            },
            {
              id: 1903,
              name: '揭榜挂帅首页推荐',
              path: 'recommend/j_recommend',
              icon: 'iconfont'
            },
            {
              id: 1904,
              name: '项目首页推荐',
              path: 'recommend/x_recommend',
              icon: 'iconfont'
            },
            {
              id: 1905,
              name: '路演活动首页推荐',
              path: 'recommend/l_recommend',
              icon: 'iconfont'
            },
            {
              id: 1906,
              name: '会议活动首页推荐',
              path: 'recommend/h_recommend',
              icon: 'iconfont'
            },
            {
              id: 1907,
              name: '科创头条首页推荐',
              path: 'recommend/k_recommend',
              icon: 'iconfont'
            },
            {
              id: 1908,
              name: '首页轮播图',
              path: 'recommend/img_recommend',
              icon: 'iconfont'
            },
          ]
        },
        {
          id: 20,
          name: '创孵页面推荐',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 2001,
              name: '项目创孵页面推荐',
              path: 'c_recommend/c_x_recommend',
              icon: 'iconfont'
            },
            {
              id: 2002,
              name: '专家创孵页面推荐',
              path: 'c_recommend/c_z_recommend',
              icon: 'iconfont'
            },
            {
              id: 2003,
              name: '创投平台创孵页面推荐',
              path: 'c_recommend/c_c_recommend',
              icon: 'iconfont'
            },
          ]
        },
        {
          id: 21,
          name: '职位页面推荐',
          icon: 'el-icon-notebook-2',
          children: [
            {
              id: 2101,
              name: '热门职位推荐',
              path: 'z_recommend/z_z_recommend',
              icon: 'iconfont'
            },
            {
              id: 2102,
              name: '热门企业推荐',
              path: 'z_recommend/z_q_recommend',
              icon: 'iconfont'
            },
            {
              id: 2103,
              name: '热门企业列表推荐',
              path: 'z_recommend/z_list_recommend',
              icon: 'iconfont'
            },
            {
              id: 2104,
              name: '热门职位列表推荐',
              path: 'z_recommend/list_q_recommend',
              icon: 'iconfont'
            },

          ]
        },
      ],
      isCollapse: false,
      asideWidth: '200px'
    }
  },
  methods: {
    activeMenu() {
      const route = this.$route
      const {meta, path} = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    changeShrinkStatus() {
      if (!this.isCollapse) {
        this.isCollapse = true
        this.asideWidth = '60px'
      } else {
        this.isCollapse = false
        this.asideWidth = '200px'
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-menu-item-group__title {
  padding: 0;
}


/deep/ .el-submenu__title:hover {
  background-color: RGB(20, 24, 35) !important;

  span {
    color: #ffffff;
  }
}

.el-menu-item:hover {
  background-color: RGB(20, 24, 35) !important;
  color: #ffffff;
}

.el-menu-vertical-demo {
  width: 101%;
  overflow-x: hidden;
  height: 100%;
}

/*滚动条的宽度*/
.el-menu-vertical-demo::-webkit-scrollbar {
  width: 4px;
}

/*滚动条里面小方块*/
.el-menu-vertical-demo::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

/*滚动条里面轨道*/
.el-menu-vertical-demo::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.iconfont {
  margin-right: 15px;
}

.icon-jianli {
  font-size: 20px;
  margin-right: 12px;
}

.shrink {
  color: #ffffff;
  text-align: center;
  background-color: #7096CA;
  cursor: pointer;
}

</style>
