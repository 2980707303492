<template>
    <div id="echarts_activity" style="width: 100%;height:600px;"></div>
</template>

<script setup>
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import {LineChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);


export default {
    name: 'echartsActivity',
    props: {
        type: {
            type: Number, default() {
                return 1
            }
        },
        id: {
            type: Number, default() {
                return 0;
            }
        },
        title: {
            type: String, default() {
                return '';
            }
        },
        intervalClose:{
            type: Boolean,default(){
                return false
            }
        }
    },
    data() {
        return {
            statInterval: null
        }
    },

    watch: {
        id() {
            this.getStatData()
        },
        type() {
            this.getStatData()
        },
        title() {
            this.setEchartsTitle()
        },
        intervalClose() {
            this.closeInterval();
        }
    },
    mounted() {
        this.initEcharts();
        this.getStatData();
    },
    beforeDestroy() {
        this.closeInterval();
    },
    methods: {
        closeInterval() {
            if (this.statInterval !== null) {
                clearInterval(this.statInterval);
                this.statInterval = null;
            }
        },
        getStatDataInterval() {
            let that = this;
            this.closeInterval();
            this.statInterval = setInterval(function () {
                that.getStatData(true);
            }, 1000 * 60)
        },
        getStatData(closeLoading) {
            let config = {};
            if (closeLoading) {
                config.loading = false;
            }
            let that = this;
            that.axios.get('/Statistics/lineData?type=' + this.type + '&id=' + this.id, config).then(response => {
                response = response.data;
                if (response.code !== 200) {
                    that.$message({
                        message: response.msg,
                        type: 'warning'
                    });
                    return false;
                }
                that.setEchartsData(response.list);
                that.getStatDataInterval();
            })
        },
        setEchartsTitle() {
            this.myChart.setOption({
                title: {
                    text: this.title
                },
            });
        },
        setEchartsData(data) {
            this.myChart.setOption({
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: data.date
                },
                series: [
                    {
                        name: 'uv',
                        type: 'line',
                        smooth: true,
                        data: data.uv
                    },
                    {
                        name: '直播观看人次',
                        type: 'line',
                        smooth: true,
                        data: data.live
                    },
                    {
                        name: '回放观看人次',
                        type: 'line',
                        smooth: true,
                        data: data.watch
                    },
                ]
            });
        },
        initEcharts() {
            let that = this;
            that.myChart = echarts.init(document.getElementById('echarts_activity'));
            let opt = {
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['uv', '直播观看人次', '回放观看人次']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: false
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },

                yAxis: {
                    type: 'value'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                series: [
                    {
                        name: 'uv',
                        type: 'line',
                        data: []
                    },
                    {
                        name: '直播观看人次',
                        type: 'line',
                        data: []
                    },
                    {
                        name: '回放观看人次',
                        type: 'line',
                        data: []
                    },
                ]
            };
            that.myChart.setOption(opt);
        }
    }
}
</script>
<style scoped>

</style>
