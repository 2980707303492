<template>
    <div>
        <p class="xs-title">
            报名情况
            <span style="position: absolute;right:0;margin-right: 20px;">
            <el-button @click="exportUserSignUp()">数据导出
            </el-button>
            <el-button @click="signSMS()">发送签到提醒短信
            </el-button>
            </span>
        </p>
        <el-table :data="userActivitiesInfo.data" style="width: 100%" row-key="id"
                  @selection-change="handleSelectionChange"
                  ref="multipleTable">
            <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="mechanism" label="机构名称"></el-table-column>
            <el-table-column prop="tel" label="电话/微信"></el-table-column>
            <el-table-column prop="position" label="职务"></el-table-column>
            <el-table-column prop="areas_concern" label="关注领域"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.state==0">
                        未操作
                    </div>
                    <div v-else-if="scope.row.state==1">
                        已发送邀请函
                    </div>
                    <div v-else>
                        用户不合规
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div v-if="scope.row.state==0">
                        <el-button type="success" icon="el-icon-check"
                                   @click="operationAudit(scope.$index, scope.row.id,1)" circle>
                        </el-button>
                        <el-button type="danger" icon="el-icon-close"
                                   @click="operationAudit(scope.$index, scope.row.id,2)" circle>
                        </el-button>
                    </div>
                    <!--                            <el-popover-->
                    <!--                                    :ref="`popover-`+ scope.row.id"-->
                    <!--                                    placement="top"-->
                    <!--                                    width="160"-->
                    <!--                                    v-if="scope.row.status<5"-->
                    <!--                            >-->
                    <!--                                <p>是否允许该用户参与会议？</p>-->
                    <!--                                <div style="text-align: right; margin: 0">-->
                    <!--                                    <el-button size="mini" type="text" @click="handleAudit(scope.$index, scope.row.id,4)">拒绝-->
                    <!--                                    </el-button>-->
                    <!--                                    <el-button type="primary" size="mini" @click="handleAudit(scope.$index, scope.row.id,3)">-->
                    <!--                                        允许-->
                    <!--                                    </el-button>-->
                    <!--                                </div>-->
                    <!--                                <el-button size="mini" type="primary" slot="reference">-->
                    <!--                                    {{ scope.row.status === 3 ? '已通过' : '' }}-->
                    <!--                                    {{ scope.row.status === 1 || scope.row.status === 2 ? '审核' : '' }}-->
                    <!--                                    {{ scope.row.status === 4 ? '已拒绝' : '' }}-->
                    <!--                                </el-button>-->
                    <!--                            </el-popover>-->
                    <!--                            <el-button size="mini" type="primary" v-else>已参会</el-button>-->

                    <!--                            <el-button style="margin-left: 10px;" size="mini" type="danger"-->
                    <!--                                       @click="handleDelete(scope.$index, scope.row.id)">删除-->
                    <!--                            </el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="userActivitiesInfo.total"
                       :page-size="userActivitiesInfo.per_page"
                       :current-page="userActivitiesInfo.current_page" :hide-on-single-page="true"
                       @current-change="getCurrentData">
        </el-pagination>


        <el-dialog :title="`发送签到提醒短信（${dialogTitle}）`" :visible.sync="signSMSDialog" :close-on-click-modal="false" width="750px">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <el-form :model="signSMSDialogData" label-width="100px" :rules="signSMSDialogDataRule" ref="venueDialog"
                         style="width: 500px;">
                    <el-form-item label="会议时间" prop="sms_date">
                        <el-date-picker
                            @change="changeSmsDate"
                                style="width: 100%;"
                                v-model="signSMSDialogData.sms_date"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="会议主题" prop="title">
                        <el-input v-model="signSMSDialogData.title" placeholder="请填写会议主题，不能含有【】，[]等标签字符" @input="smsTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="会议地点" prop="address">
                        <el-autocomplete
                                style="width: 100%;"
                                v-model="signSMSDialogData.address"
                                :fetch-suggestions="querySearch"
                                placeholder="请填写会议地点"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="签到时间" prop="sign_time">
                        <el-time-select
                                style="width: 100%;"
                                v-model="signSMSDialogData.sign_time"
                                :picker-options="{
                                    start: '06:00',
                                    step: '00:15',
                                    end: '22:00'
                                  }"
                                placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                </el-form>
                <div style="width: 155px;">
                    【环球英才】感谢您报名参加于<span style="font-weight: bold;">{{ signSMSDialogData.date }}</span>举办的<span style="font-weight: bold;">{{ signSMSDialogData.title }}</span>，
                    活动将于<span style="font-weight: bold;">{{ signSMSDialogData.sign_time }}</span>开始签到，<span style="font-weight: bold;">{{ signSMSDialogData.start_time }}</span>
                    正式开始，期待您的光临。活动地点为：<span style="font-weight: bold;">{{ signSMSDialogData.address }}</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="signSMSDialog = false">取 消</el-button>
                <el-button type="primary" @click="sendSignSMS">发送短信</el-button>
            </div>
        </el-dialog>

    </div>


</template>

<script>


export default {
    name: "SignUp",
    data() {
        return {
            dialogTitle:'所有已发送邀请函用户',
            selectedRows: [], // 存储被选中的行的数据
            num: 0,
            userActivitiesInfo: {},
            currentPage: 1,
            fapi: null,
            rule: [],
            option: null,
            signSMSDialog: false,
            signSMSDialogData: {},
            signSMSDialogDataRule: {
                sms_date:{require:true,message:'请选择会议时间',trigger:'blur'},
                title:{require:true,message:'请填写会议主题',trigger:'blur'},
                sign_time:{require:true,message:'请选择签到时间',trigger:'blur'},
                address:{require:true,message:'请填写会议地址',trigger:'blur'},
            },
            restaurants: [
                {value: '中关村大街22号中科大厦A座一层“聚英会堂”'},
                {value: '中关村大街22号中科大厦A座12层'},
            ],
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.selectedRows = val;
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        sendSignSMS() {
            let phone = [];
            this.selectedRows.forEach(function (item) {
                if (item.tel) {
                    phone.push(item.tel);
                }
            });
            const roadshowId = this.$route.query.id;
            let that = this;
            that.axios.post('/roadshow/sendSms', {
                id: roadshowId,
                sms_param: that.signSMSDialogData,
                phone:phone
            }).then(response => {
                let type = 'success'
                if (response['data']['code'] === 0) {
                    type = 'error'
                }
                that.$message({
                    message: response['data']['msg'],
                    type: type,
                    duration: 2000,
                })
                that.signSMSDialog = false;
            });
        },
        smsTitle(){
            let title = this.signSMSDialogData.title;
            this.signSMSDialogData.title = title.replace(/【|】|\[|\]/g,function(matchStr){
                let map = {'【':'','】':'·','[':'',']':''};
                return map[matchStr];
            });
        },
        changeSmsDate(val){
            const timestamp = new Date(val);
            const month = String(timestamp.getMonth() + 1);
            const day = String(timestamp.getDate()).padStart(2, '0');
            const week = String(timestamp.getDay());
            const hour = String(timestamp.getHours()).padStart(2, '0');
            const minute = String(timestamp.getMinutes()).padStart(2, '0');
            const weekArr = ['周日','周一','周二','周三','周四','周五','周六'];
            this.signSMSDialogData.date = `${month}月${day}日（${weekArr[week]}）`;
            this.signSMSDialogData.start_time = `${hour}:${minute}`;
            const sign_timestamp = new Date(new Date(val).getTime() - 30 * 60 * 1000);
            const sign_hour = String(sign_timestamp.getHours()).padStart(2, '0');
            const sign_minute = String(sign_timestamp.getMinutes()).padStart(2, '0');
            this.signSMSDialogData.sign_time = `${sign_hour}:${sign_minute}`;
        },
        signSMS() {
            const roadshow_id = this.$route.query.id;
            let that = this;
            //获取当前活动信息
            this.axios.get('/roadshow/masterDetail?id=' + roadshow_id).then(response => {
                let data = response.data.data;
                data.sms_date = data.datetime;
                data.title = data.title.replace(/【|】|\[|\]/g,function(matchStr){
                    let map = {'【':'','】':'·','[':'',']':''};
                    return map[matchStr];
                });
                that.signSMSDialogData = response.data.data;
                if (that.selectedRows.length>0) {
                    that.dialogTitle = '共选中' + that.selectedRows.length + '人';
                }
                that.signSMSDialog = true;
            })
        },
        exportUserSignUp() {
            const activityId = this.$route.query.id;
            window.location.href = process.env.VUE_APP_BASE_URL + '/export/SignUp?id=' + activityId + '&type=1';
        },
        getActivitySignUpInfo() {
            const activityId = this.$route.query.id;
            this.axios.get('/roadshow/sign_up?id=' + activityId + '&page=' + this.currentPage).then(response => {
                this.userActivitiesInfo = response.data.data
            })
        },
        getCurrentData(val) {
            this.currentPage = val;
            this.getActivitySignUpInfo()
        },

        handleDelete(index, id) {
            let type = 'success'
            this.axios.delete('/activities/sign-up-info/' + id).then(response => {
                if (response['data']['code'] === 0) {
                    type = 'error'
                }
                this.$message({
                    message: response['data']['msg'],
                    type: type,
                    duration: 2000,
                    onClose: () => {
                        this.$router.go(0);
                    }
                })
            })
        },
        handleAudit(index, id, status) {
            let that = this;
            for (const key in that.$refs) {
                if (that.$refs[key] === undefined) {
                    continue;
                }
                if (key.indexOf('popover-') !== -1 && id !== key) {
                    that.$refs[key].doClose();
                }
            }
            that.axios.post('/activities/sign-up-info/audit', {id: id, status: status}).then(response => {
                if (response.data.code === 1) {
                    if (that.userActivitiesInfo.data[index].id === id) {
                        that.userActivitiesInfo.data[index].status = status;
                    } else {
                        for (let i in that.userActivitiesInfo.data) {
                            if (that.userActivitiesInfo.data[i].id === id) {
                                that.userActivitiesInfo.data[i].status = status;
                            }
                        }
                    }
                }
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            });
            this.getActivitySignUpInfo();

        },
        operationAudit(index, id, type) {
            this.axios.post('/roadshow/operationAudit', {
                id: id,
                state: type,
            }).then(response => {
                let type = 'success'
                if (response['data']['code'] != 200) {
                    type = 'error'
                }
                this.$message({
                    message: response['data']['msg'],
                    type: type,
                    duration: 2000,
                    onClose: () => {
                        this.getActivitySignUpInfo();
                    }
                })
            })

        }

    },
    created() {
        this.getActivitySignUpInfo()
    }
}
</script>

<style scoped>
.demo-table-expand {
    font-size: 0;
    display: flex;
    flex-direction: column;
}

/deep/ .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
