<template>
  <el-header>
    <div class="el-container xs-header">
      <div class="el-container xs-container">
        <el-avatar :src="avatarSrc"></el-avatar>
        <p>{{userName}}</p>
      </div>
      <el-row>
        <el-button type="info" @click="logout" circle class="iconfont icon-tuichu
"></el-button>
      </el-row>
    </div>
  </el-header>

</template>

<script>
  export default {
    name: "Header",
    data() {
      return {
        avatarSrc: require('@/assets/favicon.png'),
        userName: ''
      }
    },
    methods: {
      logout() {
        this.axios.delete('/logout').then(() =>
          this.$message({
            message: '退出成功',
            duration: 1000,
            onClose: () => {
              this.$cookies.remove('token');
              this.$cookies.remove('name');
              this.$router.push('/login');
            }
          })
        )
      },
      getUserInfo() {
        this.axios.get('/admin').then(response => {
          this.userName = response.data['name']
          this.$cookies.set('name', response.data['name']);
        })
      }
    },
    created() {
      this.userName = this.$cookies.get('name')
      this.getUserInfo()
    }
  }
</script>

<style scoped>
  .el-header {
    background-color: RGB(39, 48, 69);
    padding: 0;
    color: RGB(204, 204, 204);
    height: 60px !important;
    line-height: 60px;
  }

  .xs-header {
    margin: 0 30px;
  }

  .xs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .el-avatar {
    margin-right: 15px;
    background-color: RGB(36, 40, 83);
  }

  .icon-tuichu {
    background-color: RGB(23, 23, 23);
    color: #cccccc;
    border: 0;
  }
</style>
