<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>修改文章</span>
    </div>
    <div class="news-form">
      <el-form :model="news" ref="news" :rules="rules" label-width="120px" label-position="left">
        <el-form-item label="标题" prop="title">
          <el-input v-model="news.title" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="作者" prop="author">
          <el-input v-model="news.author" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="标签" prop="tags">
          <el-cascader
              v-model="news['tag_id']"
              :options="bindTags"
              :props="{ expandTrigger: 'hover',checkStrictly: true }"
              clearable
              ref="elCascadeTag"
              disabled
              @change="cancelCascadeTag"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="banner" prop="banner">

            <img v-if="news.banner" :src="news.banner" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

        </el-form-item>

        <el-form-item label="发布时间" prop="created_at">
          <el-date-picker v-model="news.time" type="datetime" placeholder="选择日期时间"
                          value-format="yyyy-MM-dd HH:mm:ss" disabled></el-date-picker>
        </el-form-item>
        <el-form-item label="简介">
          <el-input type="textarea" :rows="5" v-model="news.desc" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="内容">
          <div id="editor" class="editor"></div>
        </el-form-item>
        <el-form-item label="审核未通过原因" v-if="news.approval==2">
          <el-input v-model="news.reason" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">返回</el-button>
        </el-form-item>

      </el-form>
    </div>
  </el-card>
</template>

<script>

import E from "wangeditor"

export default {
  name: "CommonEdit",
  data() {
    return {
      news: {},
      editorObj: null,
      inputVisible: false,
      inputValue: '',
      newType: null,
      bindTags: [],
      cities: [],
      uploadPDFUrl: process.env.VUE_APP_BASE_URL + '/news/pdf',
      fileList: [],
    }
  },
  methods: {
    getNews() {
      const id = this.$route.query.id
      this.axios.get('/news/' + id).then(response => {
        const data = response.data
        this.fileList = [{'name':data.title,url:data.pdf}]
        this.news = data
        this.news['tag_id']=Number(this.news['tag_id']);
        console.log(this.news['tag_id'])

        this.editorObj.txt.html(this.news.content)
      })
    },
    submit() {
      this.$router.push('/news/approval');
    },


  },
  created() {
    this.getNews()
  },
  mounted() {
    const editor = new E('#editor')
    editor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    editor.config.uploadFileName = 'file'

    this.editorObj = editor
    editor.create()
    editor.disable();
    editor.txt.html(this.news.content)
  },

}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-cascader {
  width: 100%;
}

</style>

<style>

.editor .w-e-text-container .w-e-text p {
  text-indent: 2em !important;
}

.el-picker-panel, .el-message-box__wrapper, .el-cascader__dropdown {
  z-index: 99999 !important;
}

.editor .w-e-text-container .w-e-text p {
  text-indent: 2em !important;
}

.el-cascader-panel .el-checkbox {
  position: absolute;
  z-index: 10;
  padding: 0 10px;
  width: 132px;
  height: 34px;
  line-height: 34px;
}

.el-cascader-panel .el-checkbox__input {
  visibility: hidden;
}

.el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}
.avatar {
  width: 145px !important;
}

</style>
