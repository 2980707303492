<template>
  <div class="xs-container">
    <p class="xs-title">创投联盟</p>
<!--    <el-button type="primary" @click="mechanism_add">添加</el-button>-->
    <el-table :data="positions">
      <el-table-column prop="id" label="ID" sortable ></el-table-column>
      <el-table-column prop="name" label="机构名称" ></el-table-column>
<!--      <el-table-column align="center" prop="tags" label="创孵首页推荐" show-overflow-tooltip>-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch-->
<!--              v-model="scope.row.is_incubation"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949"-->
<!--              @change="recommend(scope.row.id)"-->
<!--              :active-value="1"-->
<!--              :inactive-value="0"-->
<!--          >-->
<!--          </el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="图片">

        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="establish_at" label="成立时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
          <el-button size="mini" @click="caseList(scope.row.id)">投资案例</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
    export default {
        name: "mechanism",
        data() {
            return {
                positions: [],
                meta: [],
                currentPage: 0
            }
        },
        methods: {
            getAllPositions() {
                this.axios.get('/mechanism?page=' + this.currentPage).then(response => {
                  console.log(response)
                  this.positions = response.data.data
                  this.meta['total'] = response.data.total*1
                  this.meta['per_page'] = response.data.per_page*1
                  this.meta['current_page'] = response.data.current_page*1
                })
            },
            getCurrentData(val) {
                this.currentPage = val;
                this.getAllPositions()
            },
          handleEdit(id){
            this.$router.push({
              path: '/mechanism/edit',
              query: {
                id
              }
            })
          },
          caseList(id){
            this.$router.push({
              path: '/mechanism/caseList',
              query: {
                id
              }
            })
          },
          handleDelete(d_id){
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              this.axios.post('/mechanismDel',{d_id:d_id}).then(response => {
                this.$message({
                  message: response.data.msg,
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.$router.go(0);
                  }
                })
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
              console.log(d_id)
          },
          mechanism_add(){
            this.$router.push({
              path: '/mechanism/add',
            })
          },
          recommend(id){
            this.axios.get('/mechanism/recommend/' + id).then(response => {
              console.log(response);
              let type = 'success';
              if (response.data.code !== 1) {
                type = 'error';
              }
              this.$message({
                message: response.data.msg,
                type: type,
                duration: 1000,
                onClose: () => {
                  this.$router.go(0);
                }
              })
            })
          }
        },
        created() {
            this.getAllPositions()
        }
    }
</script>

<style scoped>
  .xs-container {
    height: 100vh;
  }

  .xs-title {
    margin-bottom: 30px;
  }


</style>
