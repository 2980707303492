<template>
  <common-detail title="政策"></common-detail>
</template>

<script>

  import commonDetail from '../commonNews/detail'

  export default {
    name: "NewsDetail",
    data() {
      return {

      }
    },
    components: {
      commonDetail
    }
  }
</script>

