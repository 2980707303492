<template>
  <el-row>
    <p class="xs-title">{{ title }}</p>
    <div>
      <el-button @click="add">
        添加
      </el-button>
    </div>
    <el-table :data="data" row-key="id">
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column prop="name" label="标题" width="300"></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="移动端图片">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.m_img"
              :preview-src-list="[scope.row.m_img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="link" label="链接"></el-table-column>
      <el-table-column prop="m_link" label="移动端链接"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">

          <el-button type="danger" icon="el-icon-delete" circle @click="deleteNews(scope.row.id)"></el-button>
        </template>
      </el-table-column>

    </el-table>

    <el-dialog title="新增" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="form_rules" ref="form">
        <el-form-item label="图片" label-width="120px" prop="img">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleSpeech">
            <img v-if="form.img" :src="form.img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="移动端图片" label-width="120px" prop="m_img">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="MHandleSpeech">
            <img v-if="form.m_img" :src="form.m_img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>


        <el-form-item label="标题" label-width="120px" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="链接" label-width="120px" prop="link">
          <el-input v-model="form.link" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="移动端链接" label-width="120px" prop="link">
          <el-input v-model="form.m_link" autocomplete="off"></el-input>
          <div>
            *移动端链接只能跳转移动端站页面，链接由开发人员提供。
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click.once="determine('form')">确 定</el-button>
      </div>
    </el-dialog>


  </el-row>


</template>

<script>

import Sortable from 'sortablejs';

export default {
  name: "commonRecommend",
  data() {
    return {
      data: [],
      select_data: [],
      select_id: '',
      dialogFormVisible: false,
      form: {
        name: '',
        link: '',
        img: '',
        m_link:'',
        m_img:'',
      },
      form_rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        img: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ],
        m_img: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ],
      },
      form_data: [],
    }
  },
  methods: {
    getAllNews() {
      this.axios.get(`/recommendImg?type=${this.typeId}`).then(response => {
        this.data = response.data
      })
    },
    deleteNews(id) {
      this.$confirm(`是否确定删除该${this.title}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get('/recommendImgDel?id=' + id).then(response => {
          let type = 'success';
          if (response.data.code !== 1) {
            type = 'error';
          }
          console.log(type);
          this.$message({
            message: response.data.msg,
            type: type,
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      });
    },
    add() {
      this.dialogFormVisible = true;

    },
    rowDrop() {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector('.el-table__body-wrapper tbody');
      console.log(tbody);
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        onEnd({newIndex, oldIndex}) {
          const currRow = _this.data.splice(oldIndex, 1)[0];
          _this.data.splice(newIndex, 0, currRow);
          _this.handleSee_c();
        }
      });
    },
    handleSee_c() {
      this.axios.post('/recommendImg/recommend_data', this.data).then(response => {
        console.log(response);
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,

        })
      })
    },
    handleSpeech(file) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.form.img = url;
      this.form_data['img'] = file.raw;
    },
    MHandleSpeech(file){
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.form.m_img = url;
      this.form_data['m_img'] = file.raw;
    },
    determine(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.data_form()
        } else {
          return false;
        }
      });
    },
    async data_form() {
      await this.uploadFileToAliOss(this.form_data);
      this.form.type=this.typeId;
      this.axios.post('/recommendImg/save', this.form).then(response => {
        console.log(response);
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$router.go(0);
          }
        })
      })

    },

    async uploadFileToAliOss(file) {
      if (!file) {
        return;
      }
      console.log(file);
      for (const item in file) {
        const {data} = await this.axios.get('https://www.globaltalent.net/next/upload/webDirectOss?file_dir=positionLive/')

        let formData = new FormData();
        let random = this.random_string(32);
        let dir = data.dir + random.slice(0, 2) + '/';
        let key = dir + random + this.get_suffix(file[item].name);
        formData.append('key', key);
        formData.append('name', file[item].name);
        formData.append('policy', data.policy);
        formData.append('OSSAccessKeyId', data.accessid);
        formData.append('callback', data.callback);
        formData.append('signature', data.signature);
        formData.append('success_action_status', 200);
        formData.append('file', file[item]);
        let header = {
          'Content-Type': 'multipart/form-data'
        };
        let that = this;
        await this.axios({
          method: 'post',
          url: data.host,
          data: formData,
          headers: header,
        }).then(response => {
          if (response.data.Status === 'Ok') {
            //将文件地址放到项目数据中
            let url = data.host + '/' + key;
            that.form[item] = url;

          }
        });

      }


    },
    //生成随机字符串
    random_string(len) {
      len = len || 32;
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    get_suffix(filename) {
      var pos = filename.lastIndexOf('.');
      var suffix = '';
      if (pos !== -1) {
        suffix = filename.substring(pos)
      }
      return suffix;
    },
  },
  created() {
    this.getAllNews();

  },
  props: {
    typeId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.rowDrop();
  },
}
</script>

<style scoped>
.el-select {
  width: 400px;
}
</style>
