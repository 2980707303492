<template>
  <tag-add title="添加资讯标签" newsType='2'></tag-add>
</template>

<script>

  import tagAdd from "../commonNews/tagAdd";

  export default {
    name: "newsTagsAdd",
    data() {
      return {
      }
    },
    components: {
      tagAdd
    }
  }
</script>
