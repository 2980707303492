<template>
  <div>
    <p class="xs-title">所有用户</p>
    <div>
      <el-input v-model="input" placeholder="请输入手机号或邮箱" style="width: 300px;margin-right: 20px;margin-bottom: 20px"></el-input>
      <el-button type="primary" @click="screen">筛选</el-button>
    </div>
    <el-table :data="users" style="width: 100%">
      <el-table-column prop="uid" label="ID" sortable width="100"></el-table-column>
      <el-table-column prop="username" label="姓名"></el-table-column>
      <el-table-column prop="mobile" label="电话"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="cr_time" label="创建时间"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.state=='1'">正常</el-tag>
          <el-tag type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="移动端管理员">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.administrators"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="modify(scope.row.uid)"
          >
          </el-switch>
        </template>

      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="disabled(scope.$index, scope.row.uid)" v-if="scope.row.state=='1'">禁用</el-button>
          <el-button size="mini" type="success" @click="enable(scope.$index, scope.row.uid)" v-else>启用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total,prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
    export default {
        name: "User",
        data() {
            return {
                users: [],
                meta: [],
                currentPage: 0,
                perPage: 10,
                total: 0,
                input:'',
            }
        },
        methods: {
          screen(){
            if (this.input){
             this.getAllUsers();
            }else{
              this.$message({
                message: '请输入手机号或者邮箱',
                type: 'error',
                duration: 1000,
                onClose: () => {

                }
              })
            }
          },
          modify(id){
            this.axios.get('/users/modify?id=' + id).then(response => {
              console.log(response);
            })
          },
            getAllUsers() {
                this.axios.get('/users?page=' + this.currentPage +'&input='+this.input).then(response => {
                    this.users = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.perPage = response.data.per_page;
                    this.total = response.data.total;
                })
            },
            getCurrentData(val) {
                this.currentPage = val;
                this.getAllUsers()
            },
          disabled(index, id){
                this.$confirm('是否确定禁用该用户', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/users_disabled?u_id='+id).then(response => {
                        let type = 'success';
                        if (response.data.code != 1) {
                            type = 'error';
                        }
                        this.$message({
                            message: response.data.msg,
                            type: type,
                            duration: 1000,
                            onClose: () => {
                                this.$router.go(0);
                            }
                        })
                    })
                });
          },
          enable(index, id){
            this.axios.get('/users_disabled?u_id='+id).then(response => {
              let type = 'success';
              if (response.data.code != 1) {
                type = 'error';
              }
              this.$message({
                message: response.data.msg,
                type: type,
                duration: 1000,
                onClose: () => {
                  this.$router.go(0);
                }
              })
            })
          }
            // deleteUser(index, id) {
            //     this.$confirm('是否确定删除该用户', '提示', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //     }).then(() => {
            //         this.axios.delete('/users/' + id).then(response => {
            //             let type = 'success';
            //             if (response.data.code != 1) {
            //                 type = 'error';
            //             }
            //             this.$message({
            //                 message: response.data.msg,
            //                 type: type,
            //                 duration: 1000,
            //                 onClose: () => {
            //                     this.$router.go(0);
            //                 }
            //             })
            //         })
            //     });
            // },
        },
        created() {
            this.getAllUsers()
        }
    }
</script>

<style scoped>

</style>
