<template>
  <div id="projectlib">
    <el-card class="box-card">
      <div slot="header">
        <span>添加项目</span>
      </div>
      <div class="project-form">
        <el-form :model="project" ref="project" :rules="rules" label-width="120px" label-position="left">
          <div style="font-weight: bold;margin-bottom: 10px;">项目信息</div>
          <div style="margin-left: 15px;">
            <el-form-item label="项目来源" prop="project_source">
              <el-radio v-model="project.project_source" :label="0">国内项目</el-radio>
              <el-radio v-model="project.project_source" :label="1">海外项目</el-radio>
              <el-tooltip placement="top">
                <div slot="content">
                  1.项目核心技术或成果产出需来源于海外<br/>
                  2.项目中核心团队成员需来源于海外<br/>
                  满足以上条件可被称为海外项目
                </div>
                <i class="el-icon-info" style="color: #237BCD"></i>
              </el-tooltip>

            </el-form-item>
            <el-form-item label="项目类型" prop="stage">
              <el-cascader
                  placeholder="请选择项目类型"
                  v-model="project.stage"
                  :options="stageList"
                  :props="{ expandTrigger: 'hover' ,value:'id',children: 'children',label:'title'}"></el-cascader>
            </el-form-item>
            <el-form-item label="项目名称" prop="name">
              <el-input v-model="project.name" placeholder="请填写项目名称"></el-input>
            </el-form-item>
            <el-form-item label="立项时间" prop="project_time">
              <el-date-picker
                  style="width: 100%;"
                  v-model="project.project_time"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="请选择项目立项时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所属行业领域" prop="industry_id">
              <el-select v-model="project.industry_id" placeholder="请选择所属行业领域" style="width: 100%;">
                <el-option v-for="item in industry_title" :label="item.name" :value="item.id"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="细分行业" prop="s_industry">
              <el-input v-model="project.s_industry" placeholder="请填写细分行业"></el-input>
            </el-form-item>
            <el-form-item label="主营业务" prop="business">
              <el-input v-model="project.business"></el-input>
            </el-form-item>
            <el-form-item label="项目介绍" prop="intro">
              <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="500"
                  placeholder="请输入项目介绍"
                  v-model="project.intro">
              </el-input>
            </el-form-item>
            <el-form-item label="专利情况">
              <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="500"
                  placeholder="请输入您的专利内容，多个专利可用中文分号“；”隔开"
                  v-model="project.patent">
              </el-input>
            </el-form-item>
            <el-form-item label="上传BP" prop="bp_url">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="(file) => {bpChange(file,'bp')}"
                  :auto-upload="false">
                <el-button size="small" type="primary">点击上传BP</el-button>
                <el-input style="display: none" v-model="project.bp_url"></el-input>
                <div @click="showBp()" slot="tip" class="el-upload__tip"
                     style="display: inline-block;margin-left: 20px">{{ bpTxt }}
                </div>
              </el-upload>
            </el-form-item>

            <el-form-item label="项目LOGO" prop="avatar">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="(file) => {bpChange(file,'logo')}"
                  :auto-upload="false">
                <div class="upload-file">
                  <img v-if="project.logo" :src="project.logo" class="avatar" alt="project logo">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div style="font-weight: bold;margin-bottom: 10px;">主体信息</div>
          <div style="margin-left: 15px;">
            <el-form-item label="公司名称">
              <el-input v-model="project.company.name" placeholder="请填写公司名称"></el-input>
            </el-form-item>
            <el-form-item label="成立时间">
              <el-date-picker
                  style="width: 100%;"
                  v-model="project.company.founded_time"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="选择成立时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="办公地点">
              <el-cascader
                  v-model="project.company.area"
                  :options="cites"
                  :props="{expandTrigger: 'hover' }"
                  clearable
                  ref="elCascadeArea"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址">
              <el-input v-model="project.company.address" placeholder="请填写详细办公地址"></el-input>
            </el-form-item>
            <el-form-item label="公司估值">
              <el-input type="number" v-model="project.company.valuation" placeholder="请输入公司估值，单位：万">
                <template slot="append"><span style="margin-left: -18px">万</span></template>
                <el-select v-model="project.company.amount_type" slot="append">
                  <el-option v-for="item in amountType" :label="item.name" :value="item.id"
                             :key="item.id"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </div>

        </el-form>

        <el-form :model="need_financing" ref="needFinancing" :rules="needFinancingRule" label-width="120px"
                 label-position="left">

          <div style="font-weight: bold;margin-bottom: 10px;">融资信息</div>
          <div style="margin-left: 15px;">
            <el-form-item label="是否需要融资">
              <el-radio v-model="need_financing.is_financing" :label="0">不需要</el-radio>
              <el-radio v-model="need_financing.is_financing" :label="1">需要</el-radio>
            </el-form-item>
            <div v-if="need_financing.is_financing">
              <el-form-item label="融资金额" prop="amount">
                <el-input v-model="need_financing.amount" placeholder="请输入融资金额，单位：万">
                  <template slot="append"><span style="margin-left: -18px">万</span></template>
                  <el-select v-model="need_financing.amount_type" slot="append">
                    <el-option v-for="item in amountType" :label="item.name" :value="item.id"
                               :key="item.id"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="融资轮次" prop="financing_round">
                <el-select v-model="need_financing.round" placeholder="请选择融资轮次" style="width: 100%;">
                  <el-option v-for="item in round" :label="item.title" :value="item.id"
                             :key="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="转让股权">
                <el-input v-model="need_financing.trans_equity">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </div>
            <div style="margin-bottom: 10px;">
              历史融资信息
              <span style="float: right;cursor: pointer;font-size: 14px;color: #409EFF"
                    @click="financingHandleClick(null,'add')">添加历史融资信息</span>
              <div>
                <el-table
                    :data="financingTable"
                    border
                    style="width: 100%;margin-top: 10px;">
                  <el-table-column prop="financing_time" label="融资时间" width="180"></el-table-column>
                  <el-table-column prop="round" label="融资轮次" width="180"></el-table-column>
                  <el-table-column prop="amount" label="融资金额"></el-table-column>
                  <el-table-column prop="investor" label="投资方"></el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button @click="financingHandleClick(scope.row,'edit')" type="text" size="small">编辑</el-button>
                      <el-button @click="financingHandleClick(scope.row,'delete')" type="text" size="small">删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-form>

        <el-form :model="main_team_item" ref="mainTeam" :rules="teamItemRule" label-width="120px" label-position="left">
          <div style="font-weight: bold;margin-bottom: 10px;margin-top: 20px;">团队成员</div>
          <div style="margin-left: 15px;">
            <el-form-item label="创始人姓名" prop="name">
              <el-input v-model="main_team_item.name">
              </el-input>
            </el-form-item>
            <el-form-item label="专业领域" prop="direction">
              <el-select v-model="main_team_item.direction" placeholder="请选择专业领域" style="width: 100%;">
                <el-option v-for="item in direction" :label="item.title" :value="item.title" :key="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="main_team_item.phone">
              </el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="main_team_item.email">
              </el-input>
            </el-form-item>

            <el-form-item label="头像" prop="avatar">
              <el-upload
                  class="avatar-uploader"
                  :action="upload.host"
                  :data="upload.formData"
                  :show-file-list="false"
                  :on-change="(file) => {bpChange(file,'main_avatar')}"
                  :auto-upload="false">
                <div class="upload-file">
                  <img v-if="main_team_item.avatar" :src="main_team_item.avatar" class="avatar"
                       alt="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>

            <div style="margin-bottom: 10px;">
              团队成员信息
              <span style="float: right;cursor: pointer;font-size: 14px;color: #409EFF"
                    @click="teamHandleClick(null,'add')">添加团队成员</span>
            </div>
            <el-table
                :data="project.team"
                border
                :key="teamKey"
                style="width: 100%">
              <el-table-column prop="avatar" label="头像" width="180">
                <template slot-scope="scope">
                  <img :src="scope.row.avatar" alt="" style="width: 50px;height: 50px;">
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="180"></el-table-column>
              <el-table-column prop="direction" label="负责方向"></el-table-column>
              <el-table-column prop="position" label="职务"></el-table-column>
              <el-table-column prop="phone" label="手机号"></el-table-column>
              <el-table-column prop="email" label="邮箱"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="teamHandleClick(scope.row,'edit')" type="text" size="small">编辑</el-button>
                  <el-button @click="teamHandleClick(scope.row,'delete')" type="text" size="small">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>


        <el-form :model="other" ref="other" :rules="otherRule" label-width="135px" label-position="left">
          <div style="font-weight: bold;margin-bottom: 10px;margin-top: 20px;">其他信息</div>
          <div style="margin-left: 15px;">
            <el-form-item label="是否关联专家院士" prop="is_expert">
              <el-radio v-model="other.is_expert" :label="0">否</el-radio>
              <el-radio v-model="other.is_expert" :label="1">是</el-radio>
            </el-form-item>
            <el-form-item label="关联专家院士" prop="expert" v-show="other.is_expert === 1">
              <el-select v-model="other.expert" multiple placeholder="请选择关联的专家院士" style="width: 100%;">
                <el-option v-for="item in fellows" :label="item.name" :value="item.id"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="跟进情况" prop="follow_status">
              <el-radio v-model="other.follow_status" :label="0">待跟进</el-radio>
              <el-radio v-model="other.follow_status" :label="1">跟进中</el-radio>
              <el-radio v-model="other.follow_status" :label="2">已跟进</el-radio>
            </el-form-item>
            <el-form-item label="跟进备注">
              <el-input
                  type="textarea"
                  :rows="4"
                  maxlength="500"
                  placeholder="请输入跟进情况"
                  v-model="other.remark">
              </el-input>
            </el-form-item>
            <el-form-item label="是否应用于平台" prop="is_site">
              <el-radio v-model="other.is_site" :label="0">否</el-radio>
              <el-radio v-model="other.is_site" :label="1">是</el-radio>
            </el-form-item>
            <el-form-item label="项目应用平台" prop="site" v-show="other.is_site === 1">
              <el-select v-model="other.site" multiple placeholder="请选择项目应用平台" style="width: 100%;">
                <el-option v-for="item in siteList" :label="item.title" :value="item.id"
                           :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务" prop="tags">
              <el-select v-model="other.tags" multiple placeholder="请选择服务标签" style="width: 100%;">
                <el-option v-for="item in tags" :label="item.title" :value="item.title"
                           :key="item.title"></el-option>
              </el-select>
            </el-form-item>
          </div>


          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="submit()" :loading="subStatus" :disabled="subStatus">保存
            </el-button>
          </el-form-item>
        </el-form>


      </div>
    </el-card>

    <el-dialog title="历史融资信息" :visible.sync="financingDialogFormVisible">
      <el-form :model="financing" label-width="80px" :rules="financingDialogRule" ref="financing">
        <el-form-item label="融资时间" prop="financing_time">
          <el-date-picker
              style="width: 100%;"
              v-model="financing.financing_time"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择融资时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="融资金额" prop="amount">
          <el-input v-model="financing.amount" placeholder="请输入融资金额，单位：万">
            <template slot="append"><span style="margin-left: -18px">万</span></template>
            <el-select v-model="financing.amount_type" slot="append">
              <el-option v-for="item in amountType" :label="item.name" :value="item.id"
                         :key="item.id"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="融资轮次" prop="round">
          <el-select v-model="financing.round" placeholder="请选择融资轮次" style="width: 100%;">
            <el-option v-for="item in round" :label="item.title" :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投资方" prop="investor">
          <el-input v-model="financing.investor" placeholder="请填写投资方，如有多个，请以中文顿号“、”分隔"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="financingDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="financingSave">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="团队成员信息" :visible.sync="teamDialogFormVisible">
      <el-form :model="teamItem" label-width="80px" :rules="teamItemRule" ref="teamDialog">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="teamItem.name">
          </el-input>
        </el-form-item>
        <el-form-item label="负责方向" prop="direction">
          <el-input v-model="teamItem.direction">
          </el-input>
        </el-form-item>
        <el-form-item label="职务" prop="position">
          <el-input v-model="teamItem.position">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="teamItem.phone">
          </el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="teamItem.email">
          </el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
              class="avatar-uploader"
              :action="upload.host"
              :data="upload.formData"
              :show-file-list="false"
              :on-change="(file) => {bpChange(file,'team_avatar')}"
              :auto-upload="false">
            <div class="upload-file">
              <img v-if="teamItem.avatar" :src="teamItem.avatar" class="avatar" alt="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="teamDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="teamSave">确 定</el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>

import cites from '../../assets/citys.json'

export default {
  name: "projectlibForm",
  data() {
    let expertSelect = (rule, value, callback) => {
      if (this.other.is_expert === 1) {
        if (value.length < 1) {
          callback(new Error('请选择关联的院士/专家'));
        }
      }
      callback();
    };
    let siteSelect = (rule, value, callback) => {
      if (this.other.is_site === 1) {
        if (value.length < 1) {
          callback(new Error('请选择应用的平台'));
        }
      }
      callback();
    };
    let isPhone = (rule, value, callback) => {
      if (value && !/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('手机号码格式错误'));
      }
      callback();
    };


    return {
      teamKey: true,
      financingDialogFormVisible: false,
      teamDialogFormVisible: false,
      subStatus: false,
      bpTxt: '只能上传Word/PDF/PPT格式文件，且不超过100MB',
      project: {
        id: '',
        name: '',
        industry_id: '',
        s_industry: '',
        stage: '',
        business: '',
        intro: '',
        bp_url: '',
        logo: '',
        tags: '',
        company: {amount_type: 1},
        expert: [],
        financing: [],//历史融资
        team: [],//团队成员源数据
        patent: '',
        project_time: '',
        project_source: 0,
      },
      main_team_item: {
        name: '',
        major: '',
        avatar: 'https://file-stor.globaltalent.net//project_lib/default_avatar.png',
      },//项目创始人信息
      need_financing: {
        is_financing: 0,
        amount_type: 1
      },//需要融资的内容
      other: {
        is_expert: 0,
        site: [],//数据同步站点
        follow_status: '',
        expert: [],
      },//项目其他信息

      financingTable: [],//融资信息展示数据
      financing: {amount_type: 1},//dialog表单数据
      teamItem: {},//dialog表单数据
      rules: {
        name: {required: true, message: '请输入项目名称', trigger: 'blur'},
        industry_id: {required: true, message: '请选择行业', trigger: 'change'},
        stage: {required: true, message: '项目类别', trigger: 'change'},
        s_industry: {required: true, message: '请填写细分行业', trigger: 'blur'},
        business: {required: true, message: '请填写主营业务', trigger: 'blur'},
        intro: {required: true, message: '请填写项目介绍', trigger: 'blur'},
        bp_url: {required: true, message: '请上传项目BP', trigger: 'change'},
        is_financing: {required: true, message: '请选择近期是否需要融资', trigger: 'change'},
        follow_status: {required: true, message: '请选择跟进状态', trigger: 'change'},
        financing_amount: {required: true, message: '请填写融资金额', trigger: 'blur'},
        financing_round: {required: true, message: '请选择融资轮次', trigger: 'blur'},
        project_time: {required: true, message: '请选择项目立项时间', trigger: 'blur'},
        project_source: {required: true, message: '请选择项目来源', trigger: 'change'},
      },
      financingDialogRule: {
        amount: [{required: true, message: '请填写融资金额', trigger: 'blur'},
          {
            type: 'number',
            message: '格式错误，请输入正确的数字类型',
            trigger: ['blur', 'change'],
            transform: (value) => Number(value)
          }],
        investor: [{required: true, message: '请填写投资方', trigger: 'blur'}],
        round: [{required: true, message: '请选择融资轮次', trigger: 'blur'}],
        financing_time: [{required: true, message: '请选择融资时间', trigger: 'blur'}],
      },
      needFinancingRule: {
        amount: [
          {required: true, message: '请填写融资金额', trigger: 'blur'},
          {type: 'number', message: '格式错误，请输入正确的数字类型', trigger: ['blur', 'change'], transform: (value) => Number(value)}
        ],
        round: [{required: true, message: '请选择融资轮次', trigger: 'blur'}],
      },
      teamItemRule: {
        name: [{required: true, message: '请填写姓名', trigger: 'blur'}],
        direction: [{required: true, message: '请填写负责方向', trigger: 'blur'}],
        position: [{required: true, message: '请填写职务', trigger: 'blur'}],
        major: [{required: true, message: '请选择专业领域', trigger: 'blur'}],
        phone: [{validator: isPhone, trigger: ['blur', 'change']}],
        email: [{type: 'email', message: '格式错误，请输入正确的邮箱', trigger: ['blur', 'change']}],
      },
      otherRule: {
        is_expert: [{required: true, message: '请选择是否关联专家院士', trigger: 'blur'}],
        follow_status: [{required: true, message: '请选择跟进状态', trigger: 'blur'}],
        is_site: [{required: true, message: '请选择是否应用于平台', trigger: 'blur'}],
        expert: [{validator: expertSelect, trigger: 'blur'}],
        site: [{validator: siteSelect, trigger: 'blur'}],
      },
      upload: {
        host: '',
        formData: {}
      },
      uploadData: {
        bp: '',
        logo: '',
        team_avatar: []
      },
      aliOss: {},
      industry_title: [],//专业领域
      stageList: [],//项目类型、阶段
      cites: [],//城市列表
      round: [],//融资轮次
      amountType: [{id: 1, name: '人民币'}, {id: 2, name: '美元'}],
      fellows: [],//关联院士列表
      siteList: [
        {id: 1, title: '环球英才网'},
        {id: 2, title: '俄罗斯工程院中国中心'},
        {id: 3, title: '非洲科学院中国中心'},
        {id: 4, title: '环球英才交流促进会'},
      ],
      tags: [
        {title: '股权投资'},
        {title: '财务顾问（FA）'},
        {title: '战略咨询'},
        {title: '渠道推广'},
      ],
      direction:[
        {title:'运营管理'},
        {title:'技术统筹'},
        {title:'市场推广'},
      ],
    }

  },

  methods: {
    teamHandleClick(row, type) {
      let initTeam = {
        avatar: 'https://file-stor.globaltalent.net//project_lib/default_avatar.png',
        avatar_file: '',
        name: '',
        direction: '',
        position: '',
        phone: '',
        email: '',
        index: '',
        is_add: '',
        id: '',
      };
      if (type === 'add') {
        this.teamItem = initTeam;
        this.teamItem.index = this.project.team.length;
        this.teamItem.is_add = true;
        this.teamDialogFormVisible = true;
      }
      if (type === 'edit') {
        let team = initTeam;
        for (let i in team) {
          team[i] = this.project.team[row.index][i];
        }
        this.teamItem = team;
        this.teamItem.is_add = false;
        this.teamDialogFormVisible = true;
      }
      if (type === 'delete') {
        let index = row.index;
        this.project.team.splice(index, 1);
        for (let i in this.project.team) {
          this.project.team[i].index = i;
        }
        //
        this.teamKey = !this.teamKey;
      }
    },
    teamSave() {
      let that = this;
      this.$refs.teamDialog.validate((valid) => {
        if (valid) {
          let index = that.teamItem.index || that.teamItem.index === 0 ? that.teamItem.index : that.project.team.length;
          let team = {
            name: that.teamItem.name,
            direction: that.teamItem.direction,
            position: that.teamItem.position,
            phone: that.teamItem.phone,
            email: that.teamItem.email,
            avatar: that.teamItem.avatar,
            avatar_file: that.teamItem.avatar_file,
            index: index
          };
          if (that.teamItem.is_add) {
            that.project.team.push(team);
          } else {
            that.project.team[index] = team;
          }
        } else {
          console.log('表单验证失败');
        }
      });
      that.teamKey = !that.teamKey;
      that.teamDialogFormVisible = false;
      that.$refs.teamDialog.resetFields();
    },
    financingSave() {
      let that = this;
      this.$refs.financing.validate((valid) => {
        if (valid) {
          //源数据（数据库中的数据内容）
          let index = that.financing.index || that.financing.index === 0 ? that.financing.index : that.project.financing.length;
          let financing = {
            amount: that.financing.amount,
            amount_type: that.financing.amount_type,
            financing_time: that.financing.financing_time,
            investor: that.financing.investor,
            round: that.financing.round,
            index: index,
            id: that.financing.id ? that.financing.id : 0
          };
          //展示数据处理
          let amountType, round;
          for (let i in this.amountType) {
            if (this.amountType[i].id === that.financing.amount_type) {
              amountType = this.amountType[i].name;
              break;
            }
          }
          for (let i in this.round) {
            if (this.round[i].id === that.financing.round) {
              round = this.round[i].title;
            }
          }
          let financingTable = {
            amount: that.financing.amount + '万' + amountType,
            financing_time: that.financing.financing_time,
            round: round,
            index: index,
            investor: that.financing.investor
          };
          //更新数据
          if (that.financing.index || that.financing.index === 0) {
            that.project.financing[index] = financing;
            for (let i in financingTable) {
              that.financingTable[index][i] = financingTable[i];
            }
          } else {
            that.project.financing.push(financing);
            that.financingTable.push(financingTable);
          }
          that.financing = {amount_type: 1};
          that.financingDialogFormVisible = false;
          that.$refs.financing.resetFields();
        }
      });
    },
    financingHandleClick(row, type) {
      let init = {
        financing_time: '',
        amount: '',
        amount_type: 1,
        round: '',
        investor: '',
        index: '',
        id: '',
      };
      if (type === 'add') {
        this.financing = init;
        this.financingDialogFormVisible = true;
      } else if (type === 'edit') {
        for (let i in init) {
          init[i] = this.project.financing[row.index][i];
        }
        this.financing = init;
        this.financingDialogFormVisible = true;
      } else if (type === 'delete') {
        let index = row.index;
        this.financingTable.splice(index, 1);
        this.project.financing.splice(index, 1);
        for (let i in this.financingTable) {
          this.financingTable[i].index = i;
        }
        for (let i in this.project.financing) {
          this.project.financing[i].index = i;
        }
      }
    },
    getIndustryTitle() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/project/type',
      }).then(response => {
        if (response.data.code === 200) {
          that.industry_title = response.data.data.list;
        }
      });
    },
    getStage() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/project/stage',
      }).then(response => {
        if (response.data.code === 200) {
          that.stageList = response.data.data.list;
        }
      });
    },
    getRound() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/project/round',
      }).then(response => {
        if (response.data.code === 200) {
          that.round = response.data.data.list;
        }
      });
    },
    bpChange(file, type) {
      if (type === 'bp') {
        if (!/.(doc|docx|pdf|ppt|pptx)$/.test(file.name)) {
          this.$message.error('请上传格式为Word/PDF/PPT的BP文件');
          return '';
        }
        if (file.size > 104857600) {
          this.$message.error('请上传文件大小不超过100MB的文件');
          return;
        }
        this.uploadData[type] = file.raw;
        this.bpTxt = file.name;
        this.project.bp_url = 'upload';
      } else {
        if (!/.(jpg|jpeg|png)$/.test(file.name)) {
          this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
          return '';
        }
        if (file.size > 1048576) {
          this.$message.error('请上传文件大小不超过1MB的图片');
          return;
        }
        let url = '';
        if (window.createObjectURL !== undefined) {
          url = window.createObjectURL(file.raw);
        } else if (window.URL !== undefined) {
          url = window.URL.createObjectURL(file.raw);
        } else if (window.webkitURL !== undefined) {
          url = window.webkitURL.createObjectURL(file.raw);
        }
        if (type === 'team_avatar') {
          this.teamItem.avatar = url;
          this.teamItem.avatar_file = file.raw;
        } else if (type === 'main_avatar') {
          this.main_team_item.avatar = url;
          this.uploadData[type] = file.raw;
        } else {
          this.project[type] = url;
          this.uploadData[type] = file.raw;
        }
        this.$forceUpdate();
      }
    },

    projectFellows() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/expert/inProject',
      }).then(response => {
        if (response.data.code === 200) {
          that.fellows = response.data.data.list;
        }
      });
    },

    async submit() {
      let that = this;
      that.subStatus = true;
      let validFlag = true;
      await this.$refs.project.validate((valid) => {
        if (!valid) {
          validFlag = false;
        }
      });
      await this.$refs.mainTeam.validate((valid) => {
        if (!valid) {
          validFlag = false;
        }
      });
      await this.$refs.other.validate((valid) => {
        if (!valid) {
          validFlag = false;
        }
      });
      if (this.need_financing.is_financing === 1) {
        await this.$refs.needFinancing.validate((valid) => {
          if (!valid) {
            validFlag = false;
          }
        });
      }
      if (!validFlag) {
        that.subStatus = false;
        return false;
      }
      if (that.project.team) {
        for (let i in that.project.team) {
          if (that.project.team[i].avatar_file) {
            that.uploadData.team_avatar.push({
              file: that.project.team[i].avatar_file,
              index: i
            });
          }
        }
      }
      //文件上传
      //上传BP文件
      await this.uploadFile();
      let data = {
        project: this.project,
        main_team: this.main_team_item,
        other: this.other,
        financing: this.need_financing
      };
      this.axios({
        method: 'post',
        url: '/projectlib/saveData',
        data: data
      }).then(response => {
        that.subStatus = false;
        let type = 'success';
        if (response.data.code !== 200) {
          type = 'error';
        }
        that.$message({
          message: response.data.msg,
          type: type,
          duration: 1000,
          onClose: () => {
            if (response.data.code === 200) {
              this.$router.push('/projectlib');
            }
          }
        })
      });
    },


    async getAliOss(dir) {
      const {data} = await this.axios.get('https://www.globaltalent.net/next/upload/webDirectOss?file_dir=' + dir + '/')
      return data;
    },

    async uploadFile() {
      if (!this.uploadData.bp && !this.uploadData.logo && this.uploadData.team_avatar.length === 0) {
        return false;
      }
      if (this.uploadData) {
        let bpData = null, avatarData = null;
        bpData = await this.getAliOss('project_lib/bp');
        avatarData = await this.getAliOss('project_lib/avatar');
        for (let i in this.uploadData) {
          let data = null;
          if (i === 'bp') {
            data = bpData;
          } else {
            data = avatarData;
          }
          if (i === 'team_avatar') {
            for (let index in this.uploadData[i]) {
              await this.uploadFileToAliOss(data, this.uploadData[i][index].file, 'team_avatar', this.uploadData[i][index].index);
            }
          } else {
            await this.uploadFileToAliOss(data, this.uploadData[i], i);
          }
        }
      }
    },

    async uploadFileToAliOss(data, file, type, index) {
      if (!file) {
        return;
      }
      let formData = new FormData();
      let random = this.common.random_string(32);
      let dir = data.dir + random.slice(0, 2) + '/';
      let key = dir + random + this.common.get_suffix(file.name);
      formData.append('key', key);
      formData.append('name', file.name);
      formData.append('policy', data.policy);
      formData.append('OSSAccessKeyId', data.accessid);
      formData.append('callback', data.callback);
      formData.append('signature', data.signature);
      formData.append('success_action_status', 200);
      formData.append('file', file);
      let header = {
        'Content-Type': 'multipart/form-data'
      };
      let that = this;
      await this.axios({
        method: 'post',
        url: data.host,
        data: formData,
        headers: header,
      }).then(response => {
        if (response.data.Status === 'Ok') {
          //将文件地址放到项目数据中
          let url = data.host + '/' + key;
          if (type === 'bp') {
            that.project.bp_url = url;
          } else if (type === 'logo') {
            that.project[type] = url;
          } else if (type === 'main_avatar') {
            that.main_team_item.avatar = url;
          } else if (type === 'team_avatar') {
            that.project.team[index].avatar = url;
          }
        }
      });

    },
    getDetail() {
      let param = this.$route.query;
      if (param.id) {
        let id = param.id;
        let that = this;
        this.axios({
          method: 'get',
          url: '/projectlib/getDetail?id=' + id,
        }).then(response => {
          if (response.data.code === 200) {
            that.project = response.data.data.project;
            that.bpTxt = that.project.bp_url;
            that.need_financing = response.data.data.need_financing;
            that.other = response.data.data.other;
            that.main_team_item = response.data.data.main_team;
            let financingTable = [];
            if (that.project.financing.length > 0) {
              for (let i in that.project.financing) {
                //展示数据处理
                let amountType, round;
                for (let j in that.amountType) {
                  if (that.amountType[j].id === that.project.financing[i].amount_type) {
                    amountType = that.amountType[j].name;
                    break;
                  }
                }
                for (let j in that.round) {
                  if (that.round[j].id === that.project.financing[i].round) {
                    round = that.round[j].title;
                    break;
                  }
                }
                let financing = {
                  amount: that.project.financing[i].amount + '万' + amountType,
                  financing_time: that.project.financing[i].financing_time,
                  round: round,
                  index: i,
                  investor: that.project.financing[i].investor
                };
                financingTable.push(financing);
              }
            }
            that.financingTable = financingTable;

          }
        });
      }

    },
    showBp() {
      let expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
      let exp = new RegExp(expression);
      if (exp.test(this.bpTxt) === true) {
        window.open(this.bpTxt, '_blank');
      }
    }

  },
  mounted() {
  },
  created() {
    this.getIndustryTitle();
    this.getStage();
    this.getRound();
    this.projectFellows();
    this.cites = [...cites]
    this.$nextTick(function () {
      this.getDetail();
    });
  }
}
</script>

<style>
.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
}

#projectlib .el-input-group__append {
  width: 50px !important;
  padding-left: 30px !important;
}

#projectlib .el-input-group__append .el-select .el-input--suffix {
  margin-left: 10px !important;
}

</style>