<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>添加直播专场</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="left"
               class="demo-ruleForm">

        <el-form-item label="专场名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="专场介绍" prop="introduce">
          <el-input v-model="ruleForm.introduce" type="textarea" :rows="3"></el-input>
        </el-form-item>

        <el-form-item label="直播占位图" prop="live_img">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="file => {handleAvatar(file,'live_img')}">
            <img v-if="ruleForm.live_img" :src="ruleForm.live_img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="直播时间" prop="date">
          <div class="block">
            <el-date-picker
                :picker-options="setFulltime"
                v-model="ruleForm.date"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['08:00:00','17:00:00']"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="关联企业" prop="company_id">
          <el-select v-model="ruleForm.company_id" filterable placeholder="请选择关联企业" @change="getPosition">
            <el-option
                v-for="item in enterprise_data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="企业职位" prop="position">
          <el-checkbox-group v-model="ruleForm.position">
            <el-checkbox v-for="(item,index) in position_data" :label="item.id" :key="index">{{ item.job_name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="font-weight: bold;margin-bottom: 10px;">演讲人信息</div>
        <el-form-item label="主讲人头像" prop="main_img">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="file => {handleAvatar(file,'main_img')}">
            <img v-if="ruleForm.main_img" :src="ruleForm.main_img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="主讲人姓名" prop="main_name">
          <el-input v-model="ruleForm.main_name"></el-input>
        </el-form-item>

        <el-button @click="add_speech">
          添加演讲人信息
        </el-button>

        <el-table
            :data="speech_data"
            height="250"
            border
            style="width: 100%">
          <el-table-column
              label="头像">
            <template slot-scope="scope">
              <img :src="scope.row.user_img" style="max-width: 80px;max-height: 80px">
            </template>

          </el-table-column>
          <el-table-column
              prop="name"
              label="姓名">
          </el-table-column>
          <el-table-column
              prop="post"
              label="职务">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="financingHandleClick(scope.$index,'edit')" type="text" size="small">编辑</el-button>
              <el-button @click="financingHandleClick(scope.$index,'delete')" type="text" size="small">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        </div>

      </el-form>
    </el-card>

    <el-dialog title="演讲人信息" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="form_rules" ref="form">
        <el-form-item label="头像" label-width="120px" prop="user_img">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="file => {handleSpeech(file,this.count)}">
            <img v-if="form.user_img" :src="form.user_img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="姓名" label-width="120px" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="职务" label-width="120px" prop="post">
          <el-input v-model="form.post" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="determine('form')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "form",
  data() {
    return {
      setFulltime: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000; // 可选未来天、可选当前天、不可选历史天
        },
      },
      ruleForm: {
        name: '',
        introduce: '',
        live_img: '',
        date: '',
        company_id: '',
        position: [],
        main_img: '',
        main_name: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入直播专场名称', trigger: 'blur'}
        ],
        introduce: [
          {required: true, message: '请输入直播专场介绍', trigger: 'blur'}
        ],
        live_img: [
          {required: true, message: '请上传直播专场占位图', trigger: 'blur'}
        ],
        date: [
          {required: true, message: '请选择开始时间和结束时间', trigger: 'blur'}
        ],
        company_id: [
          {required: true, message: '请选择关联企业', trigger: 'blur'}
        ],
        position: [
          {required: true, message: '请选择企业职位', trigger: 'blur'}
        ],
        main_img: [
          {required: true, message: '请上传主讲人头像', trigger: 'blur'}
        ],
        main_name: [
          {required: true, message: '请输入主讲人姓名', trigger: 'blur'}
        ],
      },
      upload_data: {},
      position_data: {},
      enterprise_data: {},
      speech_data: [],
      dialogFormVisible: false,
      form_data: {},
      count: '',
      form: {
        name: '',
        post: '',
        user_img: '',
      },
      form_rules: {
        name: [
          {required: true, message: '请输入演讲人姓名', trigger: 'blur'}
        ],
        post: [
          {required: true, message: '请输入演讲人职位', trigger: 'blur'}
        ],
        user_img: [
          {required: true, message: '请上传演讲人头像', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendHttpRequest();
        } else {
          return false;
        }
      });
    },
    determine(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.data_form()
        } else {
          return false;
        }
      });
    },
    data_form(){
      let team = {
        name:this.form.name,
        post:this.form.post,
        user_img:this.form.user_img,
      }
      if (this.speech_data[this.count]) {
        this.speech_data.splice(this.count,1,team);
      } else {
        this.speech_data.push(team);
      }
      this.dialogFormVisible = false;
    },
    async sendHttpRequest() {
      for (let i in this.upload_data) {
        await this.uploadFileToAliOss(this.upload_data[i], 'ruleForm', i);
      }
      if (this.form_data){
        for (let i in this.form_data) {
          await this.uploadFileToAliOss(this.form_data[i], 'speech_data', i);
        }
      }
      let data={
        'ruleForm':this.ruleForm,
        'speech_data':this.speech_data,
      }
      this.axios.post('/positionLive/save', data).then(response => {
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$router.push('/positionLive');
          }
        })
      });
    },
    handleAvatar(file, name) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.ruleForm[name] = url;
      this.upload_data[name] = file.raw;
    },
    handleSpeech(file, count) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.form.user_img = url;
      this.form_data[count] = file.raw;
    },
    enterprise() {
      this.axios.get('/positionLive/enterprise').then(response => {
        this.enterprise_data = response.data;
      })
    },
    getPosition() {
      this.axios.get('/positionLive/position?id=' + this.ruleForm.company_id).then(response => {
        this.position_data = response.data;
      })
    },
    add_speech() {
      let team = {
        name:'',
        post:'',
        user_img:'',
      }
      this.form=team;
      this.count = this.speech_data.length;
      this.dialogFormVisible = true;
    },
    financingHandleClick(row, type) {
      if (type == 'edit') {
        let team = {
          name:this.speech_data[row].name,
          post:this.speech_data[row].post,
          user_img:this.speech_data[row].user_img,
        }
        this.form=team;
        this.dialogFormVisible = true;
        this.count=row;
      } else {
        this.speech_data.splice(row, 1);
        this.form_data.splice(row, 1);
      }
    },

    async uploadFileToAliOss(file, from, index) {
      if (!file) {
        return;
      }
      const {data} = await this.axios.get('https://www.globaltalent.net/next/upload/webDirectOss?file_dir=positionLive/')

      let formData = new FormData();
      let random = this.random_string(32);
      let dir = data.dir + random.slice(0, 2) + '/';
      let key = dir + random + this.get_suffix(file.name);
      formData.append('key', key);
      formData.append('name', file.name);
      formData.append('policy', data.policy);
      formData.append('OSSAccessKeyId', data.accessid);
      formData.append('callback', data.callback);
      formData.append('signature', data.signature);
      formData.append('success_action_status', 200);
      formData.append('file', file);
      let header = {
        'Content-Type': 'multipart/form-data'
      };
      let that = this;
      await this.axios({
        method: 'post',
        url: data.host,
        data: formData,
        headers: header,
      }).then(response => {
        if (response.data.Status === 'Ok') {
          //将文件地址放到项目数据中
          let url = data.host + '/' + key;
          if (from=='speech_data'){
            that[from][index].user_img=url;
          }else{
            that[from][index]=url;
          }

        }
      });

    },
    //生成随机字符串
    random_string(len) {
      len = len || 32;
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    get_suffix(filename) {
      var pos = filename.lastIndexOf('.');
      var suffix = '';
      if (pos !== -1) {
        suffix = filename.substring(pos)
      }
      return suffix;
    },
    liveData(){
      const id = this.$route.query.id;
      if (id){
        this.axios.get('/positionLive/show?id='+id).then(response => {
          this.ruleForm=response.data.data;
          this.speech_data=response.data.speech_data;
          this.getPosition();
        })
      }
    }
  },
  created() {
    this.enterprise();
    this.liveData();
  },
}
</script>

<style scoped>

</style>