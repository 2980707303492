<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>新增会议</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left"
               class="demo-ruleForm">
        <el-form-item label="会议名称" prop="sub_title">
          <el-input v-model="ruleForm.sub_title"></el-input>
        </el-form-item>
        <el-form-item label="会议时间" prop="date">
          <div class="block">
            <el-date-picker
                v-model="ruleForm.date"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['08:00:00','17:00:00']"
                format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="会议地点" prop="sub_address">
          <el-input v-model="ruleForm.sub_address"></el-input>
        </el-form-item>

        <el-form-item label="会议形式" prop="mode">
          <el-select v-model="ruleForm.mode" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="行业分类" prop="industry_type">
          <el-select v-model="ruleForm.industry_type" placeholder="请选择">
            <el-option
                v-for="item in industry"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>



        <!--        <el-form-item label="是否需要用户登录">-->
        <!--          <el-switch v-model="ruleForm.is_login"></el-switch>-->
        <!--        </el-form-item>-->

        <el-form-item label="主办方" prop="sub_organizer">
          <el-input v-model="ruleForm.sub_organizer"></el-input>
        </el-form-item>

        <el-form-item label="会议类型" prop="type_id">
          <!--          <el-select v-model="ruleForm.type_id" placeholder="请选择活动分类">-->
          <!--            <el-option v-for="type in activityTypes" :key="type.id" :label="type.name" :value="type.id"></el-option>-->
          <!--          </el-select>-->


          <el-cascader
              :options="activityTypes"
              v-model="ruleForm.type_id"
              :props="{ multiple: true }"
              expand-trigger="hover"
              clearable></el-cascader>
<!--          <el-cascader-->
<!--              v-model="ruleForm.type_id"-->
<!--              :options="activityTypes"-->
<!--              :props="{ multiple: true }"-->
<!--              ></el-cascader>-->
        </el-form-item>

        <el-form-item label="会议图片" prop="event_banner">
          <el-upload
              :action="uploadImageUrl"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="uploadImageSuccess">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>




        <el-form-item label="轮播图片" prop="banner_img">
          <el-upload
              :action="uploadImageUrl"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="bannerSuccess">
            <img v-if="ruleForm.banner_img" :src="ruleForm.banner_img" style="width: 150px;">
            <i class="el-icon-plus" v-else></i>
          </el-upload>
        </el-form-item>





        <el-form-item label="会议详情" prop="sub_activity_content">
          <div id="editor" class="editor"></div>
        </el-form-item>

        <!--        <el-form-item label="跳转地址">-->
        <!--          <el-input v-model="ruleForm.sign_up_href" placeholder="报名成功后的跳转地址"></el-input>-->
        <!--        </el-form-item>-->

        <el-form-item label="大会网址" prop="href">
          <el-input v-model="ruleForm.href" ></el-input>
        </el-form-item>
        <el-form-item label="开启直播" prop="is_live">
          <el-switch
              v-model="ruleForm.is_live"
              active-color="#13ce66"
              inactive-color="#706666"
              :active-value="1"
              :inactive-value="0">
          </el-switch>
        </el-form-item>

      <div >
        <div>
          <span style="font-weight: bold;">会场设置</span>
          <span style="float: right;color: rgb(64, 158, 255);cursor: pointer;"  @click="addSchoolItem()">添加会场信息</span>
        </div>

        <div v-for="(item,index) in ruleForm.meetingplace" :key="index" style="width: 90%;margin-left: 5%;border: 1px #909399 dashed;margin-top: 10px">
          <i class="el-icon-error delete-item" @click="delSchoolItem(index)" style="float: right;"></i>
          <el-row style="width: 90%; margin-left: 5%;margin-top: 15px;">
<!--            <el-col :span="7">-->
<!--              <el-form-item label="直播标题" prop="live_title" style="width: 95%">-->
<!--                <el-input v-model="item.live_title" ></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="21">
              <el-form-item label="直播时间" prop="data" style="width: 95%">
                <el-date-picker
                    v-model="item.data"
                    type="datetimerange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['08:00:00','17:00:00']"
                    format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="3">
              <span style="float: right;color: rgb(64, 158, 255);cursor: pointer;font-size: 16px" @click="addVideo(index)">
                添加回放视频
              </span>
            </el-col>
          </el-row>
          <div style="width: 90%; margin-left: 5%;margin-top: 15px;">




                <el-form-item label="会场名称" prop="venue_name" style="margin-top: 20px;width: 80%;margin-left: 10%">
                  <el-input v-model="item.venue_name" ></el-input>
                </el-form-item>

          </div>
          <div v-for="(items,indexs) in item.playback" :key="indexs" style="width: 80%;margin-left: 10%;border: 1px #909399 dashed;">
            <i class="el-icon-error delete-item" @click="delItem(index,indexs)" style="float: right;"></i>

            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item label="视频标题" prop="title" style="margin-top: 20px;width: 80%;margin-left: 10%">
                  <el-input v-model="items.title" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="视频录制时间" prop="timer" style="margin-top: 20px;width: 80%;margin-left: 10%">
                  <el-date-picker
                      v-model="items.timer"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm"
                      placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>



            <el-row>
              <el-col :span="12">
                <el-form-item label="图片" prop="vodo_img" style="width: 80%;margin-left: 10%">
                  <el-upload
                      :action="uploadImageUrl"
                      list-type="picture-card"
                      :show-file-list="false"

                      :on-change="(file) => {imgKey(file,index,indexs)}"
                      :on-success="uploadSuccess">
                    <img v-if="items.vodo_img" :src="items.vodo_img" class="avatar" style="width: 148px;height: 148px;">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会场视频" prop="playback_url" style="margin-top: 20px;width: 80%;margin-left: 10%">
                  <el-input v-model="items.playback_url" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>





          </div>
        </div>
      </div>

      </el-form>
    </el-card>



    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>报名表单设置</span>
      </div>
      <div>
        <fc-designer ref="designer" height="800px" :menu="menu"/>
      </div>
    </el-card>

    <div>
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
    </div>

  </div>
</template>

<script>
import E from "wangeditor";
import {changeTagsToListString} from "@/utils/tags";

export default {
  name: "ActivityAdd",
  data() {
    return {
      ruleForm: {
        sub_title: '',
        sub_address: '',
        date: '',
        is_login: 1,
        sub_organizer: '',
        event_banner: '',
        sub_activity_content: '',
        type_id: '',
        activity_type: '',
        sign_up_href: '',
        href: '',
        mode:'',
        is_live:'0',
        industry_type:'',
        banner_img:'',
        meetingplace:'',
      },
      rules: {
        sub_title: [
          {required: true, message: '请输入会议名称', trigger: 'blur'},

        ],
        sub_address: [
          {required: true, message: '请输入会议地点', trigger: 'blur'},

        ],
        type_id: [
          {required: true, message: '请选择会议类型', trigger: 'change'}
        ],
        date: [
          {required: true, message: '请选择会议开始和结束日期', trigger: 'blur'}
        ],
        sub_organizer: [
          {required: true, message: '请输入主办方名称', trigger: 'blur'},

        ],
        event_banner: [
          {required: true, message: '请上传会议图片', trigger: 'change'}
        ],
        sub_activity_content: [
          {required: true, message: '请输入会议详情', trigger: 'change'}
        ],

        mode:[
          {required: true, message: '请选择会议形式', trigger: 'change'}
        ],
        industry_type:[
          {required: true, message: '请选择行业分类', trigger: 'change'}
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      menu: [
        {
          title: '基本信息',
          name: 'group1',
          list: [
            {
              'icon': 'icon-input',
              'name': 'input',
              'label': '输入框'
            }
          ]
        }
      ],
      activityTypes: [],
      editorObj: null,
      uploadImageUrl: process.env.VUE_APP_BASE_URL + '/image',
      types: {},
      key:'',
      options:[
        {
          value: '1',
          label: '线下'
        },
        {
          value: '2',
          label: '线上'
        },
        {
          value: '3',
          label: '线上线下同步'
        },
      ],
      industry:{},
      keys:''
    };
  },
  methods: {
    projectType(){
      this.axios.get('/projectType').then(response => {
        this.industry = response.data
      })
    },
    addSchoolItem(){
      if (this.ruleForm.meetingplace){
        this.ruleForm.meetingplace.push({

          'live_img':'',
          'data':'',
          'venue_name':'',
          playback:''
        });
      }else{
        this.ruleForm.meetingplace=[
          {
            'live_img':'',
            'data':'',
            'venue_name':'',
            playback:''
          }
        ];
      }
    },
    addVideo(index){
      if (this.ruleForm.meetingplace[index].playback){
        this.ruleForm.meetingplace[index].playback.push({
          'playback_url':'',
          'title':'',
          'timer':'',
          'vodo_img':''
        });
      }else{
        this.ruleForm.meetingplace[index].playback=[
          {
            'playback_url':'',
            'title':'',
            'timer':'',
            'vodo_img':''
          }
        ];
      }

    },
    delItem(index,indexs){
      console.log(111);
      this.ruleForm.meetingplace[index].playback.splice(indexs, 1);
    },
    delSchoolItem(index){
      this.ruleForm.meetingplace.splice(index, 1);
    },
    submitForm(formName) {
      this.ruleForm['sub_activity_content'] = this.editorObj.txt.html();
      this.ruleForm['type_id']=changeTagsToListString(this.ruleForm['type_id']);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendHttpRequest();
        } else {
          return false;
        }
      });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    sendHttpRequest() {


      let rule = this.$refs.designer.getRule();
      for (let i = 0; i < rule.length; i++) {
        rule[i]['field'] = rule[i]['title']
        if (rule[i]['validate']) {
          rule[i]['validate'][0]['message'] = '请输入您的' + rule[i]['title']
        }
      }
      this.ruleForm['sub_form_filed'] = JSON.stringify(rule);
      this.ruleForm['sub_form_option'] = JSON.stringify(this.$refs.designer.getOption());
      this.axios.post('/activities', {
        data: this.ruleForm
      }).then(response => {
        console.log(response)
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$router.push('/activities');
          }
        })
      })
    },

    getAllTypes() {
      this.axios.get('/types').then(response => {
        this.activityTypes = response.data
      })
    },

    uploadImageSuccess(response) {
      this.ruleForm.event_banner = response
    },
    bannerSuccess(response){
      this.ruleForm.banner_img = response
    },
    imgKey(file,index,indexs){
      console.log(file);
      this.key=index;
      this.keys=indexs;
    },
    uploadSuccess(response){
      console.log(231);
      this.ruleForm.meetingplace[this.key].playback[this.keys].vodo_img = response
    },
    handleChange(value) {
      this.ruleForm.type_id = value[1];
      this.ruleForm.activity_type = value[0];
    }
  },
  created() {
    this.getAllTypes();
    this.projectType();
  },
  mounted() {
    const editor = new E('#editor')
    editor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/editor/image'
    editor.config.uploadFileName = 'file'

    this.editorObj = editor
    editor.create()
    editor.txt.html(this.ruleForm.sub_activity_content)

    const rule = [
      {
        "type": "input",
        "field": "姓名",
        "title": "姓名",
        "info": "",
        "_fc_drag_tag": "input",
        "hidden": false,
        "display": true,
        "validate": [
          {
            "trigger": "blur",
            "mode": "required",
            "message": "请输入您的姓名",
            "required": true,
            "type": "string"
          }
        ]
      },
      {
        "type": "input",
        "field": "单位",
        "title": "单位",
        "info": "",
        "_fc_drag_tag": "input",
        "hidden": false,
        "display": true,
        "validate": [
          {
            "trigger": "blur",
            "mode": "required",
            "message": "请输入您的单位",
            "required": true,
            "type": "string"
          }
        ]
      },
      {
        "type": "input",
        "field": "职务",
        "title": "职务",
        "info": "",
        "_fc_drag_tag": "input",
        "hidden": false,
        "display": true,
        "validate": [
          {
            "trigger": "blur",
            "mode": "required",
            "message": "请输入您的职务",
            "required": true,
            "type": "string"
          }
        ]
      },
      {
        "type": "input",
        "field": "手机",
        "title": "手机",
        "info": "",
        "_fc_drag_tag": "input",
        "hidden": false,
        "display": true,
        "validate": [
          {
            "trigger": "blur",
            "mode": "required",
            "message": "请输入您的手机",
            "required": true,
            "type": "string"
          }
        ]
      },
      {
        "type": "input",
        "field": "邮箱",
        "title": "邮箱",
        "info": "",
        "_fc_drag_tag": "input",
        "hidden": false,
        "display": true,
        "validate": [
          {
            "trigger": "blur",
            "mode": "required",
            "message": "请输入您的邮箱",
            "required": true,
            "type": "string"
          }
        ]
      },
      {
        "type": "input",
        "field": "微信",
        "title": "微信",
        "info": "",
        "_fc_drag_tag": "input",
        "hidden": false,
        "display": true,
        "validate": [
          {
            "trigger": "blur",
            "mode": "required",
            "message": "请输入您的微信",
            "required": true,
            "type": "string"
          }
        ]
      },
    ]
    this.$refs.designer.setRule(rule)
  }
}
</script>

<style scoped>
.el-form {
  margin-top: 30px;
}

.box-card {
  margin-bottom: 30px;
}
</style>

<style>
.el-select-dropdown {
  z-index: 100000 !important;
}
</style>
