<template>
  <div class="xs-container">
    <p class="xs-title">专题中心</p>

    <el-table :data="positions" style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="title" label="标题" width="300"></el-table-column>
      <el-table-column label="图片">

        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
              v-if="scope.row.img.indexOf('http') !== -1"
          >
          </el-image>
            <el-image
                style="width: 100px; height: 100px"
                :src="'http://www.globaltalent.net/'+scope.row.img"
                :preview-src-list="['http://www.globaltalent.net/'+scope.row.img]"
                v-else
            >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="href" label="链接"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
    export default {
        name: "Special",
        data() {
            return {
                positions: [],
                meta: [],
                currentPage: 0
            }
        },
        methods: {
            getAllPositions() {
                this.axios.get('/special?page=' + this.currentPage).then(response => {
                  console.log(response)
                  this.positions = response.data.data
                  this.meta['total'] = response.data.total*1
                  this.meta['per_page'] = response.data.per_page*1
                  this.meta['current_page'] = response.data.current_page*1
                })
            },
            getCurrentData(val) {
                this.currentPage = val;
                this.getAllPositions()
            },
          handleEdit(id){
            this.$router.push({
              path: '/special/edit',
              query: {
                id
              }
            })
          },
          handleDelete(d_id){
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {
              this.axios.post('/specialDel',{d_id:d_id}).then(response => {
                this.$message({
                  message: response.data.msg,
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.$router.go(0);
                  }
                })
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
              console.log(d_id)
          }
        },
        created() {
            this.getAllPositions()
        }
    }
</script>

<style scoped>
  .xs-container {
    height: 100vh;
  }

  .xs-title {
    margin-bottom: 30px;
  }


</style>
