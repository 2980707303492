<template>
  <tags-index newsType="2"></tags-index>
</template>

<script>

  import tagsIndex from "../commonNews/tagsIndex";

  export default {
    name: "newsTags",
    data() {
      return {
      }
    },
    components: {
      tagsIndex
    }
  }
</script>

