<template>
  <div class="project-desc">
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>项目状态
      </div>
      <div class="project-desc-content">
        <div class="project-info">
              <span><span class="label">项目审核状态：</span>
                    <el-radio-group v-model="project.detail.status" @change="statusSave">
                      <el-radio :label="0">待审核</el-radio>
                      <el-radio :label="1">审核通过</el-radio>
                      <el-radio :label="2">审核不通过</el-radio>
                    </el-radio-group>
              </span>
        </div>
      </div>
    </div>
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>主体信息
      </div>
      <div class="project-desc-content">
        <div class="project-info">
          <span><span class="label">公司名称：</span>{{ project.detail.company_name }}</span>
          <span><span class="label">公司成立时间：</span>{{ project.detail.founded_time }}</span>
          <span><span class="label">公司估值：</span>{{ project.detail.valuation }}</span>
        </div>
        <div class="project-info">
          <span><span class="label">公司地址：</span>{{ project.detail.province }}</span>
          <span><span class="label">详细地址：</span>{{ project.detail.address }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>项目信息
      </div>
      <div class="project-desc-content">
        <div class="el-row">
          <div class="el-col-12">
            <div class="project-info">
              <span><span class="label">项目名称：</span>{{ project.detail.name }}</span>
              <span><span class="label">项目类型：</span>{{ project.detail.stage }}</span>
            </div>
            <div class="project-info">
              <span><span class="label">所属行业：</span>{{ project.detail.industry }}</span>
              <span><span class="label">细分行业：</span>{{ project.detail.s_industry }}</span>
            </div>
            <div class="project-info">
              <span><span class="label">主营业务：</span>{{ project.detail.business }}</span>
            </div>
            <div class="project-info">
              <span><span class="label">项目BP：</span><a
                  :href="project.detail.bp_url">{{ project.detail.bp_url }}</a></span>
            </div>
            <div class="project-info">
              <span><span class="label">跟进状态：</span>{{ project.detail.follow_status }}</span>
            </div>
            <div class="project-info">
              <span style="white-space: pre-wrap;"><span class="label">跟进备注：</span>{{ project.detail.remark }}</span>
            </div>
          </div>
          <div class="el-col-12">
            <img :src="project.detail.logo" alt="">
          </div>
        </div>
        <div class="project-info">
          <span><span class="label">项目简介：</span>{{ project.detail.intro }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>融资信息
      </div>
      <div class="project-desc-content">
        <div class="project-desc-need-financing" v-if="project.detail.is_financing === 0">
          <span>近期不需要融资</span>
        </div>
        <div class="project-desc-need-financing" v-if="project.detail.is_financing === 1">
          <span>近期需要融资</span>
          <span>期望融资轮次：{{ project.need_financing.round }}</span>
          <span>融资金额：{{ project.need_financing.amount }}{{ project.need_financing.amount_type }}</span>
          <span>转让{{ project.need_financing.trans_equity }}%股权</span>
        </div>
        <div class="project-history-financing" v-if="project.financing.length>0">
          <table>
            <tr style="text-align: center">
              <th width="261px">融资时间</th>
              <th width="261px">融资轮次</th>
              <th width="261px">融资金额</th>
              <th width="261px">投资方</th>
            </tr>
            <tr class="resume-item" v-for="(item,index) in project.financing" :key="index">
              <td>{{ item.financing_time }}</td>
              <td>{{ item.round }}</td>
              <td>{{ item.amount }}{{ item.amount_type }}</td>
              <td>{{ item.investor }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 1px;" v-if="project.expert.length>0">
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>相关专家
      </div>
      <div class="project-desc-content">
        <div class="expert-list">
          <div class="expert-item" v-for="(item,index) in project.expert" :key="index">
            <img :src="item.url" alt="">
            <div>
              <div class="expert-name">{{ item.name }}</div>
              <div class="expert-subtitle">{{ item.title }}</div>
              <div class="expert-subtitle">{{ item.resume }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>团队成员
      </div>
      <div class="project-desc-content">
        <div class="team-list">
          <div class="team-item" v-for="(item,index) in project.team" :key="index">
            <img :src="item.avatar" alt="">
            <div>
              <div>{{ item.name }}</div>
              <div>{{ item.position }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>项目同步站点
      </div>
      <div class="project-desc-content">
        <el-tag style="margin-right: 10px;" v-for="(item,index) in project.site" :key="index">{{ item.title }}</el-tag>
      </div>
    </div>
    <div>
      <div class="card_title">
        <span class="title-border-line" style="margin-right: 10px;">&nbsp;</span>服务标签
      </div>
      <div class="project-desc-content">
        <el-tag style="margin-right: 10px;" v-for="(item,index) in project.detail.tags" :key="index">{{ item }}</el-tag>
      </div>
    </div>
  </div>

</template>

<script>
import city from '@/assets/citys.json'

export default {
  name: "ProjectlibDetail",
  data() {
    return {
      id: 0,
      city: [],
      project: {
        "detail": {
          "id": 0,
          "name": "",
          "industry_id": 0,
          "intro": "",
          "bp_url": "",
          "logo": "https://file-stor.globaltalent.net/project_lib/default-project-logo.png",
          "is_financing": 0,
          "s_industry": "",
          "stage": "",
          "business": "",
          "tags": [],
          "follow_status": 0,
          "status": 0,
          "remark": "",
          "project_source": 0,
          "project_time": "",
          "patent": "",
          "province": 0,
          "city": 0,
          "county": 0,
          "founded_time": "",
          "valuation": "",
          "address": "",
          "company_name": "",
          "amount_type": 1,
          "industry": ""
        },
        "expert": [],
        "financing": [],
        "team": [],
        "need_financing": {
          "financing_time": "",
          "round": "",
          "amount": "",
          "amount_type": "",
          "trans_equity": 0,
          "investor": "",
          "type": 0
        },
        "site": []
      },
      // siteList: [
      //   {id: 1, title: '环球英才网'},
      //   {id: 2, title: '俄罗斯工程院中国中心'},
      //   {id: 3, title: '非洲科学院中国中心'},
      //   {id: 4, title: '环球英才交流促进会'},
      // ],
      siteList: {1: '环球英才网', 2: '俄罗斯工程院中国中心', 3: '非洲科学院中国中心', 4: '环球英才交流促进会'}
    }
  },
  methods: {
    statusSave() {
      this.axios.post('/projectlib/status', {id: this.id, status: this.project.detail.status}).then(response => {
        console.log(response);
      });
    },
    getDetail() {
      let param = this.$route.query;
      this.id = param.id;
      let that = this;
      this.axios.get(`/projectlib/show?id=${this.id}`).then(response => {
        response = response.data;
        if (response.code === 200) {
          let data = response.data.detail;
          if (data.detail.province) {
            for (let i in that.city) {
              if (Number(that.city[i].value) === data.detail.province) {
                data.detail.province = that.city[i].label;
                for (let j in that.city[i].children) {
                  if (data.detail.city === Number(that.city[i].children[j].value)) {
                    data.detail.city = that.city[i].children[j].label;
                    for (let k in that.city[j].children[j].children) {
                      if (data.detail.county === Number(that.city[i].children[j].children[k].value)) {
                        data.detail.county = that.city[i].children[j].children[k].label;
                        break;
                      }
                    }
                    break;
                  }
                }
                break;
              }
            }
          }
          let follow_status = ['待跟进', '已跟进', '跟进中'];
          data.detail.follow_status = follow_status[data.detail.follow_status];
          if (data.site.length > 0) {
            for (let i in data.site) {
              data.site[i].title = that.siteList[data.site[i].site_id];
            }
          }
          if (data.detail.province === data.detail.city) {
            data.detail.city = '';
          }
          data.detail.province = data.detail.province === 0 ? '未填写' : data.detail.province + data.detail.city + data.detail.county;
          that.project = data;
        }

      })
    }

  },
  created() {
    this.city = [...city]
    this.getDetail()
  },
}
</script>
<style>
.project-info {
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}

.project-info > span {
  margin-right: 20px;
  color: #666;
}

.label {
  color: #000;
  font-size: 16px;
}

.project-desc > div {
  background: #FFFFFF;
  box-shadow: 0 6px 30px 0 rgba(225, 225, 225, 0.4);
  border-radius: 5px;
  padding: 30px 40px;
  box-sizing: border-box;

}

.project-desc > div:not(:first-child) {
  margin-top: 30px;
}

.project-desc-content {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  white-space: pre-wrap;
  margin-left: 2rem;
}


.card_title {
  font-weight: 600;
  color: #333;
  font-size: 16px;
  margin-bottom: 15px;

}

.title-border-line {
  height: 20px;
  background: #237BCD;
  border-radius: 3px;
  margin-right: 10px;

}

.project-desc-need-financing > span {
  margin-right: 20px;
}

.project-history-financing {
  margin-top: 10px;
}

.project-desc table, table tr th {
  color: #999999;
  font-size: 14px;

  font-weight: 400;
}

.project-desc table, table tr th, table tr td {
  height: 36px;
}

.project-desc table > tbody > tr:first-child {
  background: #F3F3F3;
}

.project-desc tr {
  border: 1px solid #EFEFEF;
}

.project-desc td {
  border: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.project-desc table {
  text-align: center;
  border-collapse: collapse;
  padding: 2px;
  border: 1px solid #EFEFEF;
  table-layout: fixed;
  width: 100px;
}

.expert-item {
  display: flex;
  margin-bottom: 30px;
}

.expert-item > img {
  width: 101px;
  height: 134px;
}

.expert-item > div {
  margin-left: 24px;
}

.expert-name {
  font-size: 20px;

  font-weight: 400;
  color: #333333;
  margin-bottom: 8px;
}

.expert-subtitle {
  font-size: 16px;

  font-weight: 400;
  color: #666666;
}

.expert-resume {
  font-size: 16px;

  font-weight: 400;
  color: #666666;
}

.team-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 20px;
}

.team-item {
  display: flex;
}

.team-item > img {
  width: 91px;
  height: 91px;
  border-radius: 91px;
  margin-right: 20px;
}

.team-item > div > div:first-child {
  margin-top: 13px;
  font-size: 18px;

  font-weight: 400;
  color: #333333;
}

.team-item > div > div:last-child {
  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 30px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>