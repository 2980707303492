<template>
    <div>
        <el-card class="box-card">
            <div slot="header">
                <span>添加职位</span>
            </div>
            <div class="news-form">
                <el-form :model="ruleForm" :rules="rules" label-width="100px" label-position="left">
                    <el-form-item label="职位名称" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="职位上级" prop="p_id">
                      <el-cascader
                          :options="options"
                          v-model="ruleForm.p_id"
                          :props="{value:'id',label:'title',checkStrictly: true }"
                          clearable></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit('ruleForm')">发布</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>



    export default {
        name: "LabelPositionAdd",
        data() {
            return {
                ruleForm: {
                    title: '',
                    p_id: ''
                },
                rules: {
                    title: [{required: true, message: '请输入标题', trigger: 'blur'}],
                    p_id: [{required: true, message: '请输入请选择级别', trigger: 'change'}],
                },
              options:[]
            }
        },
        methods: {
            submit() {

                // this.$refs[formName].validate((valid) => {
                //     if (valid) {
              let len=this.ruleForm.p_id.length;
              this.ruleForm.p_id=this.ruleForm.p_id[len-1];

                        this.sendHttpRequest(this.ruleForm)
                //     } else {
                //         return false;
                //     }
                // });
            },
            sendHttpRequest(data) {

                this.axios.post('/label', {data}).then(response => {
                    if (response.data) {
                        this.$message({
                            message: '创建成功',
                            type: 'success',
                            onClose: () => {
                                this.$router.go(0);
                            }
                        });
                    }
                })

            },
          getType(){
            this.axios.get(`/label/type`).then(response => {
              console.log(response);
               this.options = response.data
            })
          }
        },

        created() {
           this.getType();
        },

    }
</script>
<style>
.el-cascader{
  width: 100%;
}
</style>

