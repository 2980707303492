import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI, {Loading} from 'element-ui';
import './theme/element/index.css'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

import formCreate from '@form-create/element-ui'
import FcDesigner from '@form-create/designer'
import less from 'less'
import './assets/icon/iconfont.css'

import commonJS from './utils/common.js'
Vue.prototype.common = commonJS

Vue.use(VueCookies);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(formCreate)
Vue.use(FcDesigner)
Vue.use(less)
// <!--自定义表单（弃用）-->
// import myImageComponent from '@/components/formAgency/myImage.vue';
//
// Vue.component('my-image', myImageComponent);
Vue.config.productionTip = false

let loading;

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(#E5DDED,0.5)'
  })
}

function endLoading() {
  loading.close();
}

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = 10000;

axios.interceptors.request.use(config => {
  if (config.loading !== false) {
    startLoading();
  }
    const token = Vue.$cookies.get('token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
      config.headers.Accept = 'application/json';
    }
    return config;
  }, function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(response => {
  endLoading();
  return response;
}, () => {
  endLoading();
  ElementUI.Message({
    message: '请求超时',
    type: 'error',
    duration: 3000
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
