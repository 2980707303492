<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>添加投资机构</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left"
               class="demo-ruleForm">
        <el-form-item label="公司名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="公司logo" prop="img">

          <el-upload
              class="avatar-uploader"
              :action="uploadImageUrl"
              :show-file-list="false"
              :on-success="uploadImageSuccess"
              :on-preview="handlePictureCardPreview">
            <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>


        </el-form-item>
        <el-form-item label="公司链接" prop="href">
          <el-input v-model="ruleForm.href" placeholder="跳转链接"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <div>
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: "CaseAdd",
  data() {
    return {
      ruleForm: {
        title: '',
        img: '',
        href: '',

      },
      rules: {
        title: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        href: [
          {required: true, message: '请输入公司链接', trigger: 'blur'}
        ],
        img: [
          {required: true, message: '请上传公司logo', trigger: 'change'}
        ]
      },
      activityTypes: [],
      editorObj: null,
      uploadImageUrl: process.env.VUE_APP_BASE_URL + '/image'
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendHttpRequest();
        } else {
          return false;
        }
      });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadImageSuccess(response) {
      this.ruleForm.img = response
    },
    sendHttpRequest() {

      this.axios.post('/case', {
        data: this.ruleForm
      }).then(response => {
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$router.push('/case');
          }
        })
      })
    },

  },
  created() {

  },

}
</script>

<style scoped>


.box-card {
  margin-bottom: 30px;
}
.avatar{
  width: 100px;
  height: 100px;
}
.el-icon-plus{
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 1px dashed #333;
}
</style>

<style>

</style>
