export const changeTagsToString = (tags) => {
  let tagsStr = ''
  if (tags.length > 1) {
    tagsStr = tags.join('-')
  } else {
    tagsStr = tags.toString()
  }
  return tagsStr
}

// export const changeTagsToListString = (tags) => {
//   const tagsList = tags.map(item => {
//     return item.pop()
//   })
//   return tagsList.toString()
// }
export const changeTagsToListString = (tags) => {
  const tagsList = []
  const tagItem = tags.map(item => {
    return item.join('-')
  })
  tagsList.push(tagItem)
  return tagsList.toString()
}
