<template>
  <common-add title="政策" typeId="1"></common-add>
</template>

<script>
  import commonAdd  from '../commonNews/add'

  export default {
    name: "NewsAdd",
    data() {
      return {

      }
    },
    components: {
      commonAdd
    }
  }
</script>
