<template>
  <common-add title="话题" typeId="3"></common-add>
</template>

<script>

import commonAdd  from '../commonNews/add'
export default {
  name: "TopicAdd",
  data() {
    return {

    }
  },
  components: {
    commonAdd
  }
}
</script>

