<template>
  <common-index typeId="1" title="政策"></common-index>
</template>

<script>

  import CommonIndex from '../commonNews/index'

  export default {
    name: "Policy",
    data() {
      return {
      }
    },
    components: {
      CommonIndex
    }
  }
</script>
