import {Storage} from "./storage";
import axios from "axios";
import common from "@/utils/common";

export default {

    async webDirectOss(dir) {
        let Key = 'web_direct_oss_data_' + dir;
        let storage = new Storage();
        let data = storage.getItem(Key);
        if (data) {
            return data;
        }
        storage.getItem(Key);
        let responseData;
        await axios({
            method: 'get',
            url: 'https://www.globaltalent.net/next/upload/webDirectOss?file_dir=' + dir,
        }).then(function (response) {
            responseData = response.data;
        });
        let date = parseInt(new Date().getTime() / 1000);
        let expires = responseData.expire - date - 2;
        storage.setItem(Key, responseData, expires);
        return responseData;
    },

    async uploadFile(file, dir,success) {
        if (!file) {
            return '';
        }
        dir = dir ? dir : 'default/';
        let data = await this.webDirectOss(dir);
        let formData = new FormData();
        let random = common.random_string(32);
        dir = data.dir + random.slice(0, 2) + '/';
        let key = dir + random + common.get_suffix(file.name);
        formData.append('key', key);
        formData.append('name', file.name);
        formData.append('policy', data.policy);
        formData.append('OSSAccessKeyId', data.accessid);
        formData.append('callback', data.callback);
        formData.append('signature', data.signature);
        formData.append('success_action_status', 200);
        formData.append('file', file);
        let header = {
            'Content-Type': 'multipart/form-data'
        };
        let url = '';
        await axios({
            method: 'post',
            url: data.host,
            data: formData,
            headers: header,
        }).then(function (response) {
            if (response.data.Status === 'Ok') {
                url = data.host + '/' + key;
                if (success) {
                    success(url);
                }
            }
        });
        return url;
    }

}