<template>
  <div class="xs-container">
    <p class="xs-title">创孵顶部轮播</p>
    <el-button type="primary" @click="mechanism_add">添加</el-button>
    <el-table :data="positions">
      <el-table-column prop="id" label="ID" sortable></el-table-column>
      <el-table-column prop="link" label="链接"></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>

    <el-dialog title="添加顶部轮播" :visible.sync="dialogFormVisible" :before-close="cancel">
      <el-form :model="form" :rules="rules">
        <el-form-item label="链接" :label-width="formLabelWidth" prop="link">
          <el-input v-model="form.link" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth" prop="img">
          <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleAvatar">
            <img v-if="form.img" :src="form.img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "mechanism",
  data() {
    return {
      positions: [],
      meta: [],
      currentPage: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        link: '',
        img: ''
      },
      rules: {
        link: [
          {required: true, message: '请输入链接', trigger: 'blur'},
        ],
        img: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],
      },
      formLabelWidth: '120px',
      upload_data:{}
    }
  },
  methods: {
    getAllPositions() {
      this.axios.get('/incubation?page=' + this.currentPage).then(response => {
        console.log(response)
        this.positions = response.data.data
        this.meta['total'] = response.data.total * 1
        this.meta['per_page'] = response.data.per_page * 1
        this.meta['current_page'] = response.data.current_page * 1
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllPositions()
    },
    handleEdit(data) {
      this.form.link=data.link;
      this.form.img=data.img;
      this.form.id=data.id;
      this.dialogFormVisible=true;
    },
    handleDelete(d_id) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.axios.post('/incubationDel', {d_id: d_id}).then(response => {
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      console.log(d_id)
    },
    mechanism_add() {
      this.dialogFormVisible = true;
    },
    cancel() {
      this.form.link = '';
      this.form.img = '';
      this.form.id='';
      this.dialogFormVisible = false;
    },
    async submit() {
      if (this.form.link && this.form.img) {
        await this.upload();
        this.axios.post('/incubation', this.form).then(response => {
          console.log(response);
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.push('/mechanism/incubation');
              this.cancel();
              this.getAllPositions();
            }
          })
        })
      }
    },
    async upload() {
      if (this.upload_data.name){
        let file=this.upload_data
        let data = await this.getAliOss();
        let formData = new FormData();
        let random = this.random_string(32);
        let dir = data.dir + random.slice(0, 2) + '/';
        let key = dir + random + this.get_suffix(file.name);
        formData.append('key', key);
        formData.append('name', file.name);
        formData.append('policy', data.policy);
        formData.append('OSSAccessKeyId', data.accessid);
        formData.append('callback', data.callback);
        formData.append('signature', data.signature);
        formData.append('success_action_status', 200);
        formData.append('file', file);
        let header = {
          'Content-Type': 'multipart/form-data'
        };
        await this.axios({
          method: 'post',
          url: data.host,
          data: formData,
          headers: header,
        }).then(response => {
          if (response.data.Status === 'Ok') {
            //将文件地址放到项目数据中
            this.form.img = data.host + '/' + key;
            this.upload_data='';
          }
        }).catch(err => {
          console.log(err.response);
        });
      }
    },
    async getAliOss() {
      const {data} = await this.axios.get('https://www.globaltalent.net/next/upload/webDirectOss?file_dir=expert/')
      return data;
    },
    handleAvatar(file) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 1048576) {
        this.$message.error('请上传文件大小不超过1MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      this.form.img=url;
      this.upload_data=file.raw;
    },
    //生成随机字符串
    random_string(len) {
      len = len || 32;
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    get_suffix(filename) {
      var pos = filename.lastIndexOf('.');
      var suffix = '';
      if (pos !== -1) {
        suffix = filename.substring(pos)
      }
      return suffix;
    }

  },
  created() {
    this.getAllPositions()
  }
}
</script>

<style scoped>
.xs-container {
  height: 100vh;
}

.xs-title {
  margin-bottom: 30px;
}


</style>
