<template>
  <div>
    <Header></Header>
    <el-container class="xs-container">
      <SideBar></SideBar>
      <el-scrollbar>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-scrollbar>
    </el-container>
  </div>
</template>

<script>

  import SideBar from "@/components/SideBar";
  import Header from "@/components/Header";

  export default {
    name: 'Home',
    components: {
      Header,
      SideBar,
    }
  }
</script>

<style scoped>

  .el-aside {
    height: 100%;
    overflow: hidden !important;
    background-color: RGB(39, 48, 69);
  }

  .xs-container {
    height: calc(100vh - 60px)
  }

  .el-scrollbar {
    width: 100%;
  }

  .el-main {
    height: 100%;
    background-color: RGB(237, 244, 249);
  }

  /deep/ .el-table {
    border-radius: 10px;
  }
</style>
