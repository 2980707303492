<template>
  <common-edit title="资讯" typeId="2"></common-edit>
</template>

<script>

  import commonEdit from '../commonNews/edit'

  export default {
    name: "newsEdit",
    data() {
      return {
      }
    },
    components: {
      commonEdit
    }
  }
</script>
