<template>
  <div class="xs-container">
    <p class="xs-title">所有简历</p>
    <el-table :data="positions" style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="title" label="姓名" width="300"></el-table-column>
      <el-table-column prop="professional" label="国籍"></el-table-column>
      <el-table-column prop="user.username" label="邮箱"></el-table-column>
      <el-table-column prop="user.username" label="微信号"></el-table-column>
      <el-table-column prop="user.username" label="行业类别"></el-table-column>
      <el-table-column prop="user.username" label="专业"></el-table-column>
      <el-table-column prop="user.username" label="附件"></el-table-column>
      <el-table-column prop="user.username" label="操作"></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
    export default {
        name: "Resume",
        data() {
            return {
                positions: [],
                meta: [],
                currentPage: 0
            }
        },
        methods: {
            getAllPositions() {
                this.axios.get('/positions?page=' + this.currentPage).then(response => {
                    this.positions = response.data.data
                    this.meta = response.data.meta
                })
            },
            getCurrentData(val) {
                this.currentPage = val;
                this.getAllPositions()
            }
        },
        created() {
            this.getAllPositions()
        }
    }
</script>

<style scoped>
  .xs-container {
    height: 100vh;
  }

  .xs-title {
    margin-bottom: 30px;
  }

  .el-pagination {
    margin-top: 30px;
  }
</style>
