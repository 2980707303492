<!--自定义表单（弃用）-->
<template>
  <div>
    <p class="xs-title">认证类别</p>
    <el-table :data="litData" style="width: 100%">
      <el-table-column align="center" prop="id" label="ID" width="100"></el-table-column>
      <el-table-column align="center" prop="title" label="认证名称"></el-table-column>
      <el-table-column align="center" prop="picture" label="认证图标">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.picture"
              :preview-src-list="[scope.row.picture]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="state" label="状态">
        <template v-slot="scope">
          <el-switch
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="updateState(scope.row.id, scope.row.state)"
          >
          </el-switch>

        </template>
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="添加时间" ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>

</template>

<script>
export default {
  name: "index",
  data() {

    return {
      currentPage: 0,
      perPage: 10,
      total: 0,
      litData:[]
    }
  },
  methods: {
    updateState(id, state){
      this.axios.get(`/formAgency/updateState/`+id+`?&state=${state}`).then(response => {

        if (response.data.code===200){
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 2000,
            onClose: () => {
              this.getData()
            }
          })
        }else{
          this.$message({
            message: response.data.msg,
            type: 'error',
            duration: 2000
          })
        }
      })

    },
    handleEdit(index,id){
      this.$router.push({
        path: '/formAgency/save',
        query: {
          id
        }
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getData()
    },
    getData(){
      this.axios.get(`/formAgency?&page=${this.currentPage}`).then(response => {
        console.log(response);
        this.litData = response.data.data.data;
        this.currentPage = response.data.data.current_page;
        this.perPage = response.data.data.per_page;
        this.total = response.data.data.total;
      })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>

</style>
