<template>
  <common-add title="资讯" typeId="2"></common-add>
</template>

<script>

  import commonAdd  from '../commonNews/add'
  export default {
    name: "NewsAdd",
    data() {
      return {

      }
    },
    components: {
      commonAdd
    }
  }
</script>

