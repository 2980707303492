<template>
  <div>
    <p class="xs-title">院士库</p>
    <el-table :data="expertData" style="width: 100%" row-key="index">
      <el-table-column align="center" prop="avatar" label="证件照" width="70">
        <template slot-scope="scope">
          <el-image :src="scope.row.avatar" style="width: 40px;height: 40px;" alt=""
                    :preview-src-list="showImage(scope.row)"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="姓名" width="130"></el-table-column>
      <!--      <el-table-column align="center" prop="mobile" label="手机号码" width="130"></el-table-column>-->
      <!--      <el-table-column align="center" prop="email" label="邮箱"  width="200"></el-table-column>-->
<!--      <el-table-column align="center" prop="tags" label="创孵首页推荐" width="100" min-width="60" show-overflow-tooltip>-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch-->
<!--              v-model="scope.row.is_incubation"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949"-->
<!--              @change="recommend(scope.row.id)"-->
<!--              :active-value="1"-->
<!--              :inactive-value="0"-->
<!--          >-->
<!--          </el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column align="center" prop="industry" label="专业领域">
        <template slot-scope="scope">
          <el-tag v-for="(item,index) in scope.row.industry" :key="index">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="type" label="学术身份" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">院士</span>
          <span v-if="scope.row.type === 2">专家</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="academic_title" label="学术称号">
        <template slot-scope="scope">
          <el-tag v-for="(item,index) in scope.row.academic_title" :key="index">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" circle
                     @click="editExpert(scope.row.id)"></el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination background layout="prev, pager, next" :total="expertCount" :page-size="pageSize"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      expertData: [],
      avatarList: [],
      expertCount: 0,
      pageSize: 10,
      currentPage: 1,

    }
  },
  methods: {
    getCurrentData(val) {
      this.currentPage = val;
      this.expertList()
    },
    expertList() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/expert?page=' + that.currentPage + '&limit=' + that.pageSize,
      }).then(response => {
        if (response.data.code === 200) {
          for (let i in response.data.data.list) {
            if (response.data.data.list[i].academic_title) {
              response.data.data.list[i].academic_title = response.data.data.list[i].academic_title.split(',');
            }
            if (response.data.data.list[i].industry) {
              response.data.data.list[i].industry = response.data.data.list[i].industry.split(',');
            }
          }
          that.expertData = response.data.data.list;
          that.expertCount = response.data.data.count;
        }
      });
    },
    showImage(item) {
      let list = [];
      if (item.avatar) {
        list.push(item.avatar);
      }
      if (item.life_img) {
        list.push(item.life_img);
      }
      if (item.bg_img) {
        list.push(item.bg_img);
      }
      return list;
    },
    editExpert(id) {
      this.$router.push({
        path: `expert/form?id=${id}`,
      });
    },
    recommend(id){
      this.axios.get('/expert/recommend?id=' + id).then(response => {
        console.log(response);
        let type = 'success';
        if (response.data.code !== 1) {
          type = 'error';
        }
        this.$message({
          message: response.data.msg,
          type: type,
          duration: 1000,
          onClose: () => {
            this.$router.go(0);
          }
        })
      })
    }
  },
  created() {
    this.expertList();
  },
}
</script>

<style scoped>

</style>