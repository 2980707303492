
const label = '授权函';
const name = 'myImage';
let i = 1
const uniqueId = () => `uni${i++}`
export default {
    icon: 'icon-upload',
    label,
    name,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            $required:false,
            component: 'my-image',
            props: {
                imgUrl:'',
                uploadUrl:'',
                downloadUrl:'',
            },
            info:''
        };
    },
    props() {
        return [{type: 'Required', field: 'formCreate$required', title: '是否必填'},
            {type: 'input', field: 'imgUrl', title: '案例地址'},
            {type: 'input', field: 'uploadUrl', title: '图片上传地址'},
            {type: 'input', field: 'downloadUrl', title: '文件下载地址'},
        ];
    },

};
