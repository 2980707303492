<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <span>发布揭榜挂帅信息</span>
      </div>
      <div class="bangs-form">
        <el-form :model="bangs" ref="bangs" :rules="rules" label-width="130px" label-position="left">
<!--          <el-form-item label="标题" prop="title">-->
<!--            <el-input v-model="bangs.title" :disabled="true"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="专业领域" prop="type_id">-->
<!--            <el-select v-model="bangs.type_id" placeholder="请选择专业领域" disabled >-->
<!--              <el-option v-for="type in projectType" :key="type.id" :label="type.name" :value="type.id"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="基本信息">-->
<!--            <div id="basicEditor" class="editor"></div>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="发布时间" prop="time">-->
<!--            <el-date-picker v-model="bangs.time" type="datetime" placeholder="选择日期时间"-->
<!--                            value-format="yyyy-MM-dd HH:mm:ss" disabled></el-date-picker>-->
<!--          </el-form-item>-->


<!--          <el-form-item label="预算" prop="author">-->
<!--            <el-input v-model="bangs.budget" :disabled="true"></el-input>-->
<!--          </el-form-item>-->


<!--          <el-form-item label="地区">-->
<!--            <el-cascader-->
<!--                disabled-->
<!--                v-model="bangs.area"-->
<!--                :options="cities"-->
<!--                :props="{ checkStrictly: true,expandTrigger: 'hover',value:'label' }"-->
<!--                clearable-->
<!--                ref="elCascadeArea"-->
<!--                @change="cancelCascadeArea"-->
<!--            ></el-cascader>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="需求方" prop="company">-->
<!--            <el-input v-model="bangs.company" :disabled="true"></el-input>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="单位介绍">-->
<!--            <div id="unit_introEditor" class="editor"></div>-->
<!--          </el-form-item>-->


<!--          <el-form-item label="需求描述">-->
<!--            <div id="editor" class="editor"></div>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="技术需求">-->
<!--            <div id="skill_introEditor" class="editor"></div>-->
<!--          </el-form-item>-->





          <el-form-item label="项目名称" prop="title">
            <el-input v-model="bangs.title" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="项目行业" prop="type_id">
            <el-select v-model="bangs.type_id" placeholder="请选择专业领域" disabled>
              <el-option v-for="type in projectType" :key="type.id" :label="type.name" :value="type.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="细分方向" prop="subdivide">
            <el-input v-model="bangs.subdivide" :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="研究背景" prop="basic">
            <div id="basicEditor" class="editor"></div>
          </el-form-item>

          <el-form-item label="发布时间" prop="time">

            <el-date-picker
                v-model="bangs.time"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                disabled
            >
            </el-date-picker>

            <!--            <el-date-picker v-model="bangs.time" type="datetime" placeholder="选择日期时间"-->
            <!--                            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>-->
          </el-form-item>
          <el-form-item label="截止日期" prop="end_time">
            <el-date-picker
                v-model="bangs.end_time"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                disabled
            >
            </el-date-picker>
            <!--            <el-date-picker v-model="bangs.end_time" type="datetime" placeholder="选择日期时间"-->
            <!--                            value-format="yyyy-MM-dd"></el-date-picker>-->
          </el-form-item>

          <el-form-item label="研发资金" prop="budget">
            <el-input v-model="bangs.budget" :disabled="true"></el-input>
          </el-form-item>


          <el-form-item label="所在地区" prop="area">
            <el-cascader
                v-model="bangs.area"
                :options="cities"
                :props="{ checkStrictly: true,expandTrigger: 'hover',value:'label' }"
                clearable
                ref="elCascadeArea"
                @change="cancelCascadeArea"
                disabled
            ></el-cascader>
          </el-form-item>
          <el-form-item label="企业名称" prop="company">
            <el-input v-model="bangs.company" :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="企业介绍" prop="unit_intro">
            <div id="unit_introEditor" class="editor"></div>
          </el-form-item>


          <el-form-item label="研究内容" prop="desc">
            <div id="editor" class="editor"></div>
          </el-form-item>

          <el-form-item label="考核指标" prop="skill_intro">
            <div id="skill_introEditor" class="skill_introEditor"></div>
          </el-form-item>

          <el-form-item label="产权归属" prop="ownership">
            <div id="ownership" class="editor"></div>
          </el-form-item>

          <el-form-item label="其他说明" prop="other">
            <div id="other" class="other"></div>
          </el-form-item>



















          <el-form-item label="审核未通过原因" v-if="bangs.approval==2">
            <el-input v-model="bangs.reason" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit('bangs')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import E from "wangeditor"
import cities from '../../assets/citys.json'

export default {
  name: "form",
  data() {
    const validateTypes = (rule, value, callback) => {
      if (this.bangs.type_id === 0 || !this.bangs.type_id) {
        callback(new Error(`请选择专业领域`))
      }
      callback()
    }

    return {
      bangs: {
        title: '',
        desc: '',
        type_id: '',
        area: '',
        budget: '',
        company: '',
        basic: '',
        skill_intro: '',
        unit_intro: '',

      },
      editorObj: null,
      ownershipEditorObj: null,
      basicEditor: null,
      unitIntroEditor: null,
      otherEditor:null,
      skill_introEditor:null,
      projectType: [],
      cities: [],


      rules: {
        title: {required: true, message: '请输入标题', trigger: 'blur'},
        desc: {required: true, message: '请输入需求描述', trigger: 'blur'},
        tags: {required: true, validator: validateTypes, trigger: 'change'},
      }
    }

  },
  methods: {
    cancelCascadeArea() {
      this.$refs.elCascadeArea.dropDownVisible = false
    },
    async getProjectType() {
      const {data} = await this.axios.get(`/projectType`)
      this.projectType = data;
    },
    submit() {
      this.$router.push({
        path: `/bang/approval`,
      });
    },
    getDetail() {
      let param = this.$route.query;
      if (param.id) {
        this.axios.get('/bangDetail?id='+param.id).then(response => {

          if (response.data.code === 200) {
            this.bangs = response.data.data.info;
            this.editorObj.txt.html(this.bangs.desc);
            this.basicEditor.txt.html(this.bangs.basic);
            this.ownershipEditorObj.txt.html(this.bangs.ownership);
            this.unitIntroEditor.txt.html(this.bangs.unit_intro);
            this.otherEditor.txt.html(this.bangs.other);
            this.skill_introEditor.txt.html(this.bangs.skill_intro);
          }
        });
      }
    }
  },
  mounted() {
    const editor = new E('#editor')
    editor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    editor.config.uploadFileName = 'file'
    this.editorObj = editor
    editor.disable();
    editor.create()


    const ownership = new E('#ownership')
    ownership.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    ownership.config.uploadFileName = 'file'
    this.ownershipEditorObj = ownership
    ownership.create()
    ownership.disable();


    const basicEditor = new E('#basicEditor')
    basicEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    basicEditor.config.uploadFileName = 'file'
    this.basicEditor = basicEditor
    basicEditor.create()
    basicEditor.disable();


    const unit_introEditor = new E('#unit_introEditor')
    unit_introEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    unit_introEditor.config.uploadFileName = 'file'
    this.unitIntroEditor = unit_introEditor
    unit_introEditor.create()
    unit_introEditor.disable();


    const otherEditor = new E('#other')
    otherEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    otherEditor.config.uploadFileName = 'file'
    this.otherEditor = otherEditor
    otherEditor.create()
    otherEditor.disable();

    const skill_introEditor = new E('#skill_introEditor')
    skill_introEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    skill_introEditor.config.uploadFileName = 'file'
    this.skill_introEditor = skill_introEditor
    skill_introEditor.create()
    skill_introEditor.disable();




    this.getDetail()
  },
  created() {
    this.getProjectType()

    this.cities = [...cities]
  }
}
</script>

<style scoped>

</style>