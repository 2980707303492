<template>
  <el-row>
    <div>
      <el-input v-model="input" placeholder="请输入标题筛选" style="width: 200px;margin-right: 20px"></el-input>
      <el-button @click="getAllNews">搜索</el-button>
    </div>
    <el-table :data="news" style="width: 100%">
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column align="center" prop="title" label="标题" show-overflow-tooltip></el-table-column>

      <!--      <el-table-column align="center" prop="area" label="地区" width="200" min-width="100" show-overflow-tooltip></el-table-column>-->

      <el-table-column align="center" prop="tags" label="分类" width="200" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-for="(tag,index) in scope.row['tag_name']" :key="index">{{ tag }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="tags" label="首页顶部推荐" width="100" min-width="60" show-overflow-tooltip v-if="this.typeId==2">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.top_news"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="recommend(scope.row.id)"
              :active-value="1"
              :inactive-value="0"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="time" label="发布时间" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" label="操作" width="250" min-width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-plus" circle
                     @click="increaseNews(scope.row)"></el-button>

          <el-button type="primary" icon="el-icon-more" circle
                     @click="showDetailNews(scope.$index, scope.row.id)"></el-button>
          <el-button type="primary" icon="el-icon-edit" circle
                     @click="editNews(scope.$index, scope.row.id)"></el-button>
          <el-button type="danger" icon="el-icon-delete" circle
                     @click="deleteNews(scope.$index, scope.row.id)"></el-button>
        </template>
      </el-table-column>

    </el-table>


    <el-dialog title="增加阅读量" :visible.sync="dialogFormVisible">


        当前阅读量:
        <el-input v-model="volume" style="width: 200px;margin-right: 20px"
                  type="number"></el-input>

        增加点赞量:
      <el-input v-model="fabulous" style="width: 200px;margin-right: 20px"
                type="number"></el-input>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>

    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </el-row>


</template>

<script>


export default {
  name: "CommonIndex",
  data() {
    return {
      news: [],
      currentPage: 0,
      perPage: 10,
      total: 0,
      input: '',
      dialogFormVisible: false,
      volume: 0,
      increaseNews_id: 0,
      fabulous:0
    }
  },
  methods: {
    getAllNews() {
      this.axios.get(`/news?type=${this.typeId}&page=${this.currentPage}&input=${this.input}`).then(response => {
        const data = response.data.data
        data.map(item => {
          item['type'] = '资讯';
        })

        this.news = data
        this.currentPage = response.data.current_page;
        this.perPage = response.data.per_page;
        this.total = response.data.total;
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllNews()
    },
    deleteNews(index, id) {
      this.$confirm(`是否确定删除该${this.title}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/news/' + id).then(response => {
          let type = 'success';
          if (response.data.code !== 1) {
            type = 'error';
          }
          this.$message({
            message: response.data.msg,
            type: type,
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      });
    },
    showDetailNews(index, id) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id
        }
      })
    },
    editNews(index, id) {
      if (this.typeId==2){
        this.$router.push({
          path: '/news/edit',
          query: {
            id
          }
        })
      }else{
        this.$router.push({
          path: '/news/topicEdit',
          query: {
            id
          }
        })
      }

    },
    recommend(id) {
      this.axios.get('/news/recommend/' + id).then(response => {
        console.log(response);
        let type = 'success';
        if (response.data.code !== 1) {
          type = 'error';
        }
        this.$message({
          message: response.data.msg,
          type: type,
          duration: 1000,
          onClose: () => {
            this.$router.go(0);
          }
        })
      })
    },
    increaseNews(data) {
      this.dialogFormVisible = true;
      this.increaseNews_id = data.id;
      this.volume=data.view_count;
      this.fabulous=data.fabulous;
    },
    determine() {
      let data = {
        'id': this.increaseNews_id,
        'volume': this.volume,
        'fabulous':this.fabulous
      }
      this.axios.post('/news/increaseNews', data).then(response => {
        console.log(response);
        let type = 'success';
        if (response.data.code !== 1) {
          type = 'error';
        }
        this.$message({
          message: response.data.msg,
          type: type,
          duration: 1000,
          onClose: () => {
            this.$router.go(0);
          }
        })
      })
    }
  },
  created() {
    this.getAllNews()
  },
  props: {
    typeId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.el-tag {
  margin-right: 10px;
}
</style>
