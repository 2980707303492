<template>


    <div>
        <p class="xs-title">单位福利</p>
        <el-table :data="bangs" style="width: 100%">
            <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

            <el-table-column align="center" prop="title" label="标签名称" show-overflow-tooltip></el-table-column>



            <el-table-column align="center" prop="created_at" label="录入时间" width="170" min-width="150"
                             show-overflow-tooltip></el-table-column>

            <el-table-column align="center" label="操作" width="250" min-width="200">
                <template slot-scope="scope">
                    <el-button type="danger" icon="el-icon-delete" circle
                               @click="deleteBangs(scope.row.id)"></el-button>
                </template>
            </el-table-column>

        </el-table>
    </div>
</template>

<script>

    export default {
        name: "Label",
        data() {
            return {
                bangs: [],
                activeName:0,
            }
        },
        methods:{
            getAllBangs() {
                this.axios.get(`/label?&type=${this.activeName}`).then(response => {
                    console.log(response);
                    this.bangs = response.data
                })
            },
            getCurrentData(val) {
                this.currentPage = val;
                this.getAllBangs()
            },
            handleClick(tab, event) {
                this.getAllBangs();
                console.log(tab, event);
            },
            deleteBangs(e_id){
                var data= {
                    'e_id':e_id
                };


                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.axios.post('/labelDel', {data}).then(response => {
                        console.log(response);
                        if (response.data) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                onClose: () => {
                                    this.getAllBangs()
                                }
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });





            }
        },
        created() {
            this.getAllBangs()
        },

        components: {

        }
    }
</script>
