<template>
  <div>
    <p class="xs-title">企业信息审批</p>
    <el-table :data="bangs" style="width: 100%">
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column align="center" prop="name" label="公司名称" width="300" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="legal_person" label="法人姓名" width="100" min-width="100"
                       show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="number" label="营业执照编号" width="300" min-width="200"
                       show-overflow-tooltip>

      </el-table-column>

      <el-table-column align="center" prop="license" label="营业执照" width="170" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-image
              style="width: 80px"
              :src="scope.row.license"
              :preview-src-list="[scope.row.license]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="examine" label="状态" width="100" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.examine=='1'">
            待审核
          </div>
          <div v-else-if="scope.row.examine=='2'">
            审批已通过
          </div>
          <div v-else>
            审批未通过
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250" min-width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" circle
                     @click="editBangs(scope.row.id)" v-if="scope.row.examine=='1'"></el-button>
        </template>
      </el-table-column>

    </el-table>


    <el-dialog
        title="企业信息审批"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-radio-group v-model="radio">
        <el-radio :label="2">通过</el-radio>
        <el-radio :label="3">不通过</el-radio>
      </el-radio-group>
      <el-input type="textarea" v-model="desc" v-if="radio=='3'"></el-input>


      <el-select v-model="type" placeholder="请选择公司类型" v-if="radio=='2'">
        <el-option
            v-for="item in type_data"
            :key="item.id"
            :label="item.title"
            :value="item.id">
        </el-option>
      </el-select>

      <el-input type="text" v-model="ed_id" style="display: none"></el-input>


      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="approve">确 定</el-button>
  </span>
    </el-dialog>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>


// import {CodeToText} from "element-china-area-data";

export default {
  name: "Enterprise",
  data() {
    return {
      bangs: [],
      currentPage: 0,
      perPage: 10,
      total: 0,
      centerDialogVisible: false,
      radio: '',
      ed_id: '',
      desc: '',
      type: '',
      type_data: {},
    }
  },
  methods: {
    getAllBangs() {
      this.axios.get(`/enterprise?&page=${this.currentPage}`).then(response => {
        this.bangs = response.data.data.data
        this.currentPage = response.data.data.current_page;
        this.perPage = response.data.data.per_page;
        this.total = response.data.data.total;
        this.type_data=response.data.type;
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllBangs()
    },
    editBangs(v_id) {
      this.centerDialogVisible = true;
      this.ed_id = v_id;
      console.log(v_id);
    },
    approve() {
      if (this.radio) {
        var data = {
          'ed_id': this.ed_id,
          'radio': this.radio,
          'desc': this.desc,
          'type': this.type
        };
        this.axios.post('/enterprise', {data}).then(response => {
          console.log(response);
          if (response.data.code == '1') {
            this.$message({
              message: '审批成功',
              type: 'success',
              onClose: () => {
                this.$router.go(0);
              }
            });
          } else {
            this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            });
          }
          this.ed_id = '';
          this.radio = '';
          this.desc = '';
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择企业审批',
          type: 'error'
        });
      }

    }
  },
  created() {
    this.getAllBangs()
  },
  components: {}

}
</script>
