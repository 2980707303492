<template>


  <div>
    <p class="xs-title">职位管理</p>


    <el-table
        :data="bangs"
        style="width: 100%"
        row-key="id"
        border
        lazy
        :load="load"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
        prop="id"
        label="ID"
        sortable>
      </el-table-column>
      <el-table-column
          prop="title"
          label="职位名称"
          sortable>
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="录入时间"
          sortable>
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-button type="danger" icon="el-icon-delete" circle
                     @click="deleteBangs(scope.row.id)"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "LabelPosition",
  data() {
    return {
      bangs: [],
      activeName: 1,
    }
  },
  methods: {
    getAllBangs() {
      this.axios.get(`/label?&type=${this.activeName}`).then(response => {
        console.log(response);
        this.bangs = response.data
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllBangs()
    },
    handleClick(tab, event) {
      this.getAllBangs();
      console.log(tab, event);
    },
    deleteBangs(e_id) {
      var data = {
        'e_id': e_id
      };


      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.axios.post('/labelDel', {data}).then(response => {
          console.log(response);
          if (response.data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              onClose: () => {
                this.getAllBangs()
              }
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    }
  },
  created() {
    this.getAllBangs()
  },

  components: {}
}
</script>
