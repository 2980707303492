<template>
  <common-edit title="话题" typeId="3"></common-edit>
</template>

<script>

import commonEdit from '../commonNews/edit'

export default {
  name: "TopicEdit",
  data() {
    return {
    }
  },
  components: {
    commonEdit
  }
}
</script>
