<template>
  <div>
    <p class="xs-title">揭榜挂帅</p>
    <el-table :data="bangs" style="width: 100%">
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column align="center" prop="title" label="项目名称" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="area" label="地区" width="200" min-width="100"
                       show-overflow-tooltip></el-table-column>
<!--      <el-table-column align="center" prop="name" label="类型" width="200" min-width="100"-->
<!--                       show-overflow-tooltip></el-table-column>-->

      <el-table-column align="center" prop="name" label="项目行业" width="300" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag>{{scope.row.name}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="time" label="发布时间" width="170" min-width="150"
                       show-overflow-tooltip></el-table-column>

      <el-table-column align="center" label="操作" width="250" min-width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-more" circle
                     @click="showDetailBangs( scope.row.id)"></el-button>
          <el-button type="primary" icon="el-icon-user" circle
                     @click="showBangUser( scope.row.id)"></el-button>
          <el-button type="primary" icon="el-icon-edit" circle
                     @click="editBangs(scope.row.id)"></el-button>
          <el-button type="danger" icon="el-icon-delete" circle
                     @click="deleteBangs(scope.$index, scope.row.id)"></el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>


// import {CodeToText} from "element-china-area-data";

export default {
  name: "Bang",
  data() {
    return {
      bangs: [],
      currentPage: 0,
      perPage: 10,
      total: 0,
    }
  },
  methods:{
    getAllBangs() {
      this.axios.get(`/bang?&page=${this.currentPage}`).then(response => {
        this.bangs = response.data.data
        this.currentPage = response.data.current_page;
        this.perPage = response.data.per_page;
        this.total = response.data.total;
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllBangs()
    },
    showDetailBangs(id){
      window.open('https://www.globaltalent.net/next/bang/detail/'+id,'blank');
    },
    showBangUser(id){
      this.$router.push({
        path: 'bang/people',
        query:{id:id}
      });
    },
    editBangs(id){
      this.$router.push({
        path: `bang/form?id=${id}`,
      });
    },
    deleteBangs(index,id){

        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios.post(`/delBang`, {id:id}).then(response => {
            let type = 'success';
            if (response.data.code !== 200) {
              type = 'error';
            }else{
              for (let i in this.bangs) {
                if (this.bangs[i].id === id) {
                  this.bangs.splice(i,1);
                }
              }
            }
            this.$message({
              message: response.data.msg,
              type: type,
              duration: 1000,
            })

          })
        }).catch(() => {

        });

    },

  },
  created() {
    this.getAllBangs()
  },
  components: {

  }
}
</script>
