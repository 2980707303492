<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>添加职位</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="left"
               class="demo-ruleForm">

        <div style="display: flex;flex-direction: row;">
          <el-form-item label="职位名称" prop="title">
            <el-input placeholder="专业名称" v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item class="job_name" prop="job_name">
            <el-input placeholder="岗位名称" v-model="ruleForm.job_name"></el-input>
          </el-form-item>
        </div>

        <el-form-item label="行业领域" prop="industry">
          <el-select v-model="ruleForm.industry" placeholder="请选择活动分类">
            <el-option v-for="industry in industryFields" :key="industry.id" :label="industry.name"
                       :value="industry.name"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="专业领域" prop="professional">
          <div class="xs-position-name">
            <el-input placeholder="请输入所属专业领域" v-model="ruleForm.professional"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="招聘人数" prop="num">
          <div class="xs-position-name">
            <el-input placeholder="请输入人数" v-model="ruleForm.num"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="期望到岗日期" prop="time">
          <div class="block">
            <el-date-picker
              v-model="ruleForm.time"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="职位要求" prop="requirements">
          <div class="xs-position-name">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.requirements">
            </el-input>
          </div>
        </el-form-item>

        <el-form-item label="薪资待遇(元)">
          <div class="xs-position-name">
            <div class="xs-salary">
              <el-input placeholder="请输入最低工资" @input="handleInput('low_salary')"
                        v-model="ruleForm.low_salary"></el-input>
              -
              <el-input placeholder="请输入最高工资" @input="handleInput('high_salary')"
                        v-model="ruleForm.high_salary"></el-input>
            </div>
            <el-radio id="salaryStatus" v-model="ruleForm.salary_status" label="1" border
                      @click.native.prevent="changeStatus">
              面议
            </el-radio>
          </div>
        </el-form-item>

        <el-form-item label="职位待遇" prop="treatment">
          <div class="xs-position-name">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.treatment">
            </el-input>
          </div>
        </el-form-item>
      </el-form>
    </el-card>

    <div>
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      <el-button type="info" @click="addDraft('ruleForm')">添加草稿</el-button>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ResumeAdd",
        data() {
            return {
                ruleForm: {
                    title: '',
                    job_name: '',
                    industry: '',
                    professional: '',
                    num: '',
                    time: '',
                    requirements: '',
                    low_salary: '',
                    high_salary: '',
                    salary_status: '0',
                    treatment: '',
                    status: '',
                },
                rules: {
                    title: [
                        {required: true, message: '请输入专业名称', trigger: 'blur'},
                    ],
                    job_name: [
                        {required: true, message: '请输入岗位名称', trigger: 'blur'}
                    ],
                    industry: [
                        {required: true, message: '请选择行业领域', trigger: 'blur'}
                    ],
                    professional: [
                        {required: true, message: '请输入专业领域', trigger: 'blur'}
                    ],
                    num: [
                        {required: true, message: '请输入招聘人数', trigger: 'blur'}
                    ],
                    time: [
                        {required: true, message: '请选择到岗日期', trigger: 'blur'}
                    ],
                    requirements: [
                        {required: true, message: '请输入职位要求', trigger: 'blur'}
                    ],
                    salary: [
                        {required: true, message: '请输入薪资待遇', trigger: 'blur'}
                    ],
                    treatment: [
                        {required: true, message: '请输入职位待遇', trigger: 'blur'}
                    ]
                },
                industryFields: [
                    {
                        id: 1,
                        name: '能源·资源·环境'
                    },
                    {
                        id: 2,
                        name: '信息科技'
                    },
                    {
                        id: 3,
                        name: '生物医药'
                    },
                    {
                        id: 4,
                        name: '工程制造'
                    },
                    {
                        id: 5,
                        name: '化学化工'
                    },
                    {
                        id: 6,
                        name: '现代农业'
                    },
                    {
                        id: 7,
                        name: '教育文化'
                    },
                    {
                        id: 8,
                        name: '经济·金融·管理'
                    }
                ]
            }
        },
        methods: {
            changeStatus() {
                const obj = document.getElementById('salaryStatus')
                obj.blur()
                if (this.ruleForm.salary_status == '0') {
                    this.ruleForm.salary_status = '1'
                } else {
                    this.ruleForm.salary_status = '0'
                }
            },
            handleInput(type) {
                this.ruleForm[type] = this.ruleForm[type].replace(/[^\d]/g, '')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.sendHttpRequest(1);
                    } else {
                        return false;
                    }
                });
            },
            addDraft(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.sendHttpRequest(0);
                    } else {
                        return false;
                    }
                });
            },
            sendHttpRequest(status) {
                this.ruleForm.status = status;
                this.axios.post('/positions', {
                    data: this.ruleForm
                }).then(response => {
                    let type = 'success';
                    if (response.data.code == 0) {
                        type = 'error';
                    }
                    this.$message({
                        message: response.data.msg,
                        type: type,
                        duration: 1000,
                        onClose: () => {
                            this.$router.push('/positions');
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped lang="less">
  .xs-position-name {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      margin-right: 30px;
    }

    .xs-salary {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      div:last-child {
        margin-left: 30px;
      }
    }
  }

  .box-card {
    margin-bottom: 30px;
  }

</style>

<style scoped>

  >>> .job_name .el-form-item__content {
    margin-left: 10px !important;
  }
</style>
