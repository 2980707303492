<template>
  <div>
    <p class="xs-title">企业信息审批</p>
    <el-table :data="bangs" style="width: 100%">

      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column align="center" prop="username" label="用户名账号" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="entity_type" label="主体类型" min-width="90" show-overflow-tooltip>

        <template slot-scope="scope">
          <span v-if="scope.row.entity_type == 1">高校</span>
          <span v-else-if="scope.row.entity_type == 2">科研院所</span>
          <span v-else-if="scope.row.entity_type == 3">企业</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="entity_full_name" label="主体全称"  show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="authorization_letter" label="授权函" show-overflow-tooltip>
        <template slot-scope="scope" v-if="scope.row.authorization_letter">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.authorization_letter"
              :preview-src-list="[scope.row.authorization_letter]">
          </el-image>
        </template>

      </el-table-column>
      <el-table-column align="center" prop="campus_website" label="校园官网" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="examine" label="状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.state=='1'">
            待审核
          </div>
          <div v-else-if="scope.row.state=='2'">
            审批已通过
          </div>
          <div v-else>
            审批未通过
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="提交时间" width="150" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" label="操作" width="100" min-width="100">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" circle
                     @click="editBangs(scope.row.id)" v-if="scope.row.state==1"></el-button>
        </template>
      </el-table-column>

    </el-table>


    <el-dialog
        title="企业信息审批"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-radio-group v-model="state">
        <el-radio :label="2">通过</el-radio>
        <el-radio :label="3">不通过</el-radio>
      </el-radio-group>
      <el-input type="textarea" v-model="reason" v-if="state=='3'"></el-input>
      <el-input type="text" v-model="ed_id" style="display: none"></el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="approve">确 定</el-button>
  </span>
    </el-dialog>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: "auditing",
  data() {
    return {
      bangs: [],
      currentPage: 0,
      perPage: 10,
      total: 0,
      centerDialogVisible: false,
      state: '',
      ed_id: '',
      reason: '',

    }
  },
  methods: {
    getAllBangs() {
      this.axios.get(`/enterprise/auditing?&page=${this.currentPage}`).then(response => {
        this.bangs = response.data.data
        this.currentPage = response.data.current_page;
        this.perPage = response.data.per_page;
        this.total = response.data.total;

      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllBangs()
    },
    editBangs(v_id) {
      this.centerDialogVisible = true;
      this.ed_id = v_id;
    },
    approve() {
      if (this.state) {
        var data = {
          'id': this.ed_id,
          'state': this.state,
          'reason': this.reason,
        };
        this.axios.post('/enterprise/auditing', {data}).then(response => {
          this.centerDialogVisible = false;
          if (response.data.code == '1') {
            this.$message({
              message: '审批成功',
              type: 'success',
              onClose: () => {
                this.$router.go(0);
              }
            });
          } else {
            this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            });
          }
          this.ed_id = '';
          this.state = '';
          this.reason = '';
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择企业审批',
          type: 'error'
        });
      }

    }
  },
  created() {
    this.getAllBangs()
  },
  components: {}

}
</script>
