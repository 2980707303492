<template>
  <div>
    <p class="xs-title">文章问题反馈</p>
    <el-table :data="feedback" style="width: 100%" row-key="index" >
      <el-table-column align="center" prop="user_id" label="用户ID" width="130"></el-table-column>
      <el-table-column align="center" prop="type" label="问题类型" width="130">
        <template slot-scope="scope">

          <el-tag v-if="scope.row.type==1">举报文章</el-tag>
          <el-tag v-else>举报评论</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="problem" label="问题" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="new_id" label="文章ID" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="comment_id" label="评论ID" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="status" label="回复状态" width="130"></el-table-column>
      <el-table-column align="center" prop="created_at" label="发布时间" width="170"></el-table-column>

      <el-table-column align="center" label="操作" width="250" min-width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-picture-outline" circle v-if="scope.row.images"
                     @click="showImages(scope.row.images)"></el-button>
          <el-button type="primary" icon="el-icon-edit-outline" circle v-if="scope.row.status ==='未回复'"
                     @click="reply(scope.row.id,scope.$index)"></el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>

    <el-image-viewer
        v-if="dialogVisible"
        :on-close="()=>{dialogVisible=false}"
        :url-list="dialogImageList"/>
  </div>

</template>

<script>


// import {CodeToText} from "element-china-area-data";


export default {
  name: "feedbackNew",
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      dialogImageList: [],
      feedback: [],
      currentPage: 0,
      perPage: 10,
      total: 0,
    }
  },
  methods: {
    getAllFeedback() {
      this.axios.get(`/feedbackNew?limit=${this.perPage}&page=${this.currentPage}`).then(response => {
        this.feedback = response.data.data.list
        this.total = response.data.data.count;
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllFeedback()
    },
    showImages(images) {
      if (images) {
        this.dialogImageList = [];
        for (let i in images) {
          this.dialogImageList[images[i].sort] = images[i].url;
        }
      }
      this.dialogImageList = this.dialogImageList.filter(function (s) {
        return s && s.trim();
      });
      this.dialogVisible = true;
    },
    reply(id,row) {

      let that = this;
      this.$prompt('请输入回复内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({value}) => {
        this.axios.post('/feedback/replyNew', {id: id, content: value}).then(response => {
          if (response.data.code === 200) {
            that.feedback[row].status = '已回复';
            this.$message({
              message: '回复成功',
              type: 'success'
            });
          }else{
            this.$message({
              message: response.data.msg,
              type: 'warning'
            });
          }
        });
      });
    }

  },
  created() {
    this.getAllFeedback()
  },
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
}
</script>
