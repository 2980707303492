<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <span>发布揭榜挂帅信息</span>
      </div>
      <div class="bangs-form">
        <el-form :model="bangs" ref="bangs" :rules="rules" label-width="100px" label-position="left">
          <el-form-item label="项目名称" prop="title">
            <el-input v-model="bangs.title"></el-input>
          </el-form-item>
          <el-form-item label="项目行业" prop="type_id">
            <el-select v-model="bangs.type_id" placeholder="请选择专业领域">
              <el-option v-for="type in projectType" :key="type.id" :label="type.name" :value="type.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="细分方向" prop="subdivide">
            <el-input v-model="bangs.subdivide"></el-input>
          </el-form-item>

          <el-form-item label="研究背景" prop="basic">
            <div id="basicEditor" class="editor"></div>
          </el-form-item>

          <el-form-item label="发布时间" prop="time">

            <el-date-picker
                v-model="bangs.time"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
            >
            </el-date-picker>

<!--            <el-date-picker v-model="bangs.time" type="datetime" placeholder="选择日期时间"-->
<!--                            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>-->
          </el-form-item>
          <el-form-item label="截止日期" prop="end_time">
            <el-date-picker
                v-model="bangs.end_time"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
            >
            </el-date-picker>
<!--            <el-date-picker v-model="bangs.end_time" type="datetime" placeholder="选择日期时间"-->
<!--                            value-format="yyyy-MM-dd"></el-date-picker>-->
          </el-form-item>

          <el-form-item label="研发资金" prop="budget">
            <el-input v-model="bangs.budget"></el-input>
          </el-form-item>


          <el-form-item label="所在地区" prop="area">
            <el-cascader
                v-model="bangs.area"
                :options="cities"
                :props="{ checkStrictly: true,expandTrigger: 'hover',value:'label' }"
                clearable
                ref="elCascadeArea"
                @change="cancelCascadeArea"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="企业名称" prop="company">
            <el-input v-model="bangs.company"></el-input>
          </el-form-item>

          <el-form-item label="企业介绍" prop="unit_intro">
            <div id="unit_introEditor" class="editor"></div>
          </el-form-item>


          <el-form-item label="研究内容" prop="desc">
            <div id="editor" class="editor"></div>
          </el-form-item>
          <el-form-item label="考核指标" prop="skill_intro">
            <div id="skill_introEditor" class="skill_introEditor"></div>
          </el-form-item>

          <el-form-item label="产权归属" prop="ownership">
            <div id="ownership" class="editor"></div>
          </el-form-item>

          <el-form-item label="其他说明" prop="other">
            <div id="other" class="other"></div>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submit('bangs')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import E from "wangeditor"
import cities from '../../assets/citys.json'

export default {
  name: "form_list",
  data() {

    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      bangs: {
        title: '',
        desc: '',
        type_id: '',
        area: '',
        budget: '',
        company: '',
        basic: '',
        ownership: '',
        unit_intro: '',
        time:'',
        other:'',//其他
        end_time:'',//申报截止时间
        subdivide:'',//细分方向
        skill_intro:''
      },
      editorObj: null,
      ownershipEditorObj: null,
      basicEditor: null,
      unitIntroEditor: null,
      otherEditor:null,
      skillIntroEditorObj:null,
      projectType: [],
      cities: [],
      rules: {
        company: {required: true, message: '请输入需求企业', trigger: 'blur'},
        title: {required: true, message: '请输入项目名称', trigger: 'blur'},
        desc: {required: true, message: '请输入预期目标', trigger: 'blur'},
        type_id: {required: true, message: '请输入专业领域', trigger: 'blur'},
        area: {required: true, message: '请输入地区', trigger: 'blur'},
        budget: {required: true, message: '请输入研发资金', trigger: 'blur'},
        basic: {required: true, message: '请输入研究背景', trigger: 'blur'},
        ownership: {required: true, message: '请输入产权归属', trigger: 'blur'},
        end_time: {required: true, message: '请选择申报截止时间', trigger: 'blur'},
        time: {required: true, message: '请选择发布时间', trigger: 'blur'},
        subdivide: {required: true, message: '请输入细分方向', trigger: 'blur'},
        skill_intro: {required: true, message: '请输入考核指标', trigger: 'blur'},
      }
    }
  },
  methods: {

    cancelCascadeArea() {
      this.$refs.elCascadeArea.dropDownVisible = false
    },
    async getProjectType() {
      const {data} = await this.axios.get(`/projectType`)
      this.projectType = data;
    },
    submit(formName) {
      this.bangs.desc = this.editorObj.txt.html();
      this.bangs.basic = this.basicEditor.txt.html();
      this.bangs.ownership = this.ownershipEditorObj.txt.html();
      this.bangs.unit_intro = this.unitIntroEditor.txt.html();
      this.bangs.other = this.otherEditor.txt.html();
      this.bangs.skill_intro = this.skillIntroEditorObj.txt.html();
      // if (this.bangs['area']) {
      //   this.bangs['area'] = JSON.stringify(this.bangs['area'])
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.axios.post(`/bangForm`, this.bangs).then(response => {
            let type = 'success';
            if (response.data.code === 0) {
              type = 'error';
            }
            this.$message({
              message: response.data.msg,
              type: type,
              duration: 1000,
              onClose: () => {
                this.$router.push('/bang');
              }
            })
          })
        } else {
          return false;
        }
      });
    },
    getDetail() {
      let param = this.$route.query;
      if (param.id) {
        this.axios.get('/bangDetail?id='+param.id).then(response => {
          if (response.data.code === 200) {
            this.bangs = response.data.data.info;
            this.editorObj.txt.html(this.bangs.desc);
            this.basicEditor.txt.html(this.bangs.basic);
            this.ownershipEditorObj.txt.html(this.bangs.ownership);
            this.unitIntroEditor.txt.html(this.bangs.unit_intro);
            this.otherEditor.txt.html(this.bangs.other);
            this.skillIntroEditorObj.txt.html(this.bangs.skill_intro);
          }
        });
      }
    }
  },
  mounted() {
    const editor = new E('#editor')
    editor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    editor.config.uploadFileName = 'file'
    this.editorObj = editor
    editor.create()


    const ownership = new E('#ownership')
    ownership.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    ownership.config.uploadFileName = 'file'
    this.ownershipEditorObj = ownership
    ownership.create()


    const basicEditor = new E('#basicEditor')
    basicEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    basicEditor.config.uploadFileName = 'file'
    this.basicEditor = basicEditor
    basicEditor.create()


    const unit_introEditor = new E('#unit_introEditor')
    unit_introEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    unit_introEditor.config.uploadFileName = 'file'
    this.unitIntroEditor = unit_introEditor
    unit_introEditor.create()



    const otherEditor = new E('#other')
    otherEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    otherEditor.config.uploadFileName = 'file'
    this.otherEditor = otherEditor
    otherEditor.create()

    const skill_introEditor = new E('#skill_introEditor')
    skill_introEditor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    skill_introEditor.config.uploadFileName = 'file'
    this.skillIntroEditorObj = skill_introEditor
    skill_introEditor.create()

    this.getDetail()
  },
  created() {
    this.getProjectType()

    this.cities = [...cities]
  }
}
</script>

<style>
.editor .w-e-text-container .w-e-text p {
  text-indent: 0 !important;
}
</style>