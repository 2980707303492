<template>
  <common-index typeId="2" title="职位首页推荐"></common-index>
</template>

<script>

import CommonIndex from '../commonRecommend'

export default {
  name: "recommend",
  data() {
    return {

    }
  },
  components: {
    CommonIndex
  }
}
</script>
