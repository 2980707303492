<template>
  <div class="xs-container">
    <p class="xs-title">审核话题</p>
    <el-table :data="topics" style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="content" label="内容"></el-table-column>

      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleCheck(scope.$index, scope.row.id,2)">通过</el-button>
          <el-button size="mini" type="primary" @click="handleCheck(scope.$index, scope.row.id,3)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
  </div>
</template>

<script>
    export default {
        name: "Topic",
        data() {
            return {
                topics: [],
                meta: [],
                currentPage: 0
            }
        },
        methods: {
            getAllTopics() {
                this.axios.get('/check-topics?page=' + this.currentPage).then(response => {
                    this.topics = response.data.data
                    this.meta = response.data.meta
                })
            },
            getCurrentData(val) {
                this.currentPage = val;
                this.getAllTopics()
            },
            handleCheck(index, id, status) {
                let titleMessage = '是否确定通过该话题';
                if (status == 3) {
                    titleMessage = '是否确定拒绝该话题';
                }
                this.$confirm(titleMessage, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info'
                }).then(() => {
                    this.axios.patch('/topics/' + id, {
                        status
                    }).then(response => {
                        let type = 'success';
                        if (response.data.code == 0) {
                            type = 'error';
                        }
                        this.$message({
                            message: response.data.msg,
                            type: type,
                            duration: 1000,
                            onClose: () => {
                                this.$router.go(0);
                            }
                        })
                    })
                }).catch(() => {
                    return
                });
            }
        },
        created() {
            this.getAllTopics()
        }
    }
</script>

<style scoped>
  .xs-container {
    height: 100vh;
  }

  .xs-title {
    margin-bottom: 30px;
  }

  .el-pagination {
    margin-top: 30px;
  }
</style>
