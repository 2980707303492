<template>
  <div class="xs-container">
    <p class="xs-title">创投机构入驻</p>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="姓名">
              <span>{{ props.row.name }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="联系方式">
              <span>{{ props.row.contact_details }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="企业邮箱">
              <span>{{ props.row.email }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="机构名称">
              <span>{{ props.row.institution_name }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="机构地址">
              <span>{{ props.row.institutional_address }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="投资领域">
              <span>{{ props.row.investment_areas }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="投资轮次">
              <span>{{ props.row.investment_rounds }}</span>
            </el-form-item>
          </el-form>

          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="名片">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="props.row.business_card"
                  :preview-src-list="[props.row.business_card]">
              </el-image>

            </el-form-item>
          </el-form>
        </template>

      </el-table-column>
      <el-table-column
          label="姓名"
          prop="name">
      </el-table-column>
      <el-table-column
          label="职位"
          prop="position">
      </el-table-column>
      <el-table-column
          label="机构名称"
          prop="institution_name">
      </el-table-column>
      <el-table-column
          label="状态"
          prop="state">
        <template slot-scope="scope">
          <div v-if="scope.row.state==1">
            未审核
          </div>
          <div v-else-if="scope.row.state==2">
            已通过
          </div>
          <div v-else>
            已拒绝
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" v-if="scope.row.state==1">审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="meta.total" :page-size="meta.per_page" :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-radio v-model="state" label="2">通过</el-radio>
      <el-radio v-model="state" label="3">拒绝</el-radio>
      <div style="margin-top: 10px">
        <el-input type="textarea" v-model="reason" v-if="state==3"  placeholder="请输入拒绝理由"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="approval">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "settled",
  data() {
    return {
      tableData:[],
      meta:[],
      currentPage:0,
      centerDialogVisible: false,
      a_id:'',
      state:'',
      reason:'',
    }
  },
  methods: {
    tabulation(){
      this.axios.get('/settled?page=' + this.currentPage).then(response => {
        console.log(response)
        this.tableData = response.data.data
        this.meta['total'] = response.data.total*1
        this.meta['per_page'] = response.data.per_page*1
        this.meta['current_page'] = response.data.current_page*1
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllPositions()
    },
    handleClick(row){
      this.centerDialogVisible=true;
      this.a_id=row.id;
    },
    approval(){
      this.axios.post(`/settledApproval`, {a_id:this.a_id,state:this.state,reason:this.reason}).then(response => {
        console.log(response)
        if (response.data.code){
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        }else{
          this.$message({
            message: response.data.msg,
            type: 'error',
            duration: 1000,
          })
        }
      })
    }
  },
  created() {
    this.tabulation();
  }
}
</script>

<style scoped>



</style>
