import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{},
  },
  mutations: {

    setUserInfo(state,info) {
      state.user = info
    },

    deleteUserInfo(state) {
      state.user = {}
    }
  },
  actions: {},
  modules: {}
})
