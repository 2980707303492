<template>
  <div>
    <p class="xs-title">职位审核</p>
    <el-table :data="positions" style="width: 100%" >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="id">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="职位名称">
              <span>{{ props.row.job_name }}</span>
            </el-form-item>
            <el-form-item label="专业领域">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="职位类别">
              <span>{{ props.row.title }}</span>
            </el-form-item>
            <el-form-item label="岗位职责">
              <span>{{ props.row.requirements }}</span>
            </el-form-item>
            <el-form-item label="任职要求">
              <span>{{ props.row.describe }}</span>
            </el-form-item>
            <el-form-item label="工作地址">
              <span>{{ props.row.detailed_address }}</span>
            </el-form-item>
            <el-form-item label="工作经验">
              <span>{{ props.row.experience }}</span>
            </el-form-item>
            <el-form-item label="学历要求">
              <span>{{ props.row.education}}</span>
            </el-form-item>
            <el-form-item label="薪资要求">
              <span>{{ props.row.salary }}</span>
            </el-form-item>
            <el-form-item label="公司名称">
              <span>{{ props.row.company_name }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <!--      <el-table-column label="ID" prop="id"></el-table-column>-->
      <el-table-column label="职位名称" prop="job_name"></el-table-column>
      <el-table-column label="专业领域" prop="name"></el-table-column>
      <el-table-column label="发布时间" prop="addtime"></el-table-column>
      <!--      <el-table-column label="职位类别" prop="title"></el-table-column>-->
      <el-table-column label="公司名称" prop="company_name"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row.id)" v-if="scope.row.status==0">审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total,prev, pager, next" :total="meta.total" :page-size="meta.per_page"
                   :current-page="meta.current_page" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-radio v-model="radio" label="1">通过</el-radio>
      <el-radio v-model="radio" label="2">拒绝</el-radio>
      <div style="margin-top: 10px">
        <el-input type="textarea" v-model="desc" v-if="radio==2"  placeholder="请输入拒绝理由"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="approval">确 定</el-button>
  </span>
    </el-dialog>
  </div>


</template>

<script>
import { CodeToText } from 'element-china-area-data'
export default {
  name: "Position",
  data() {
    return {
      positions: [],
      meta: [],
      currentPage: 0,
      centerDialogVisible: false,
      a_id:'',
      radio:'',
      desc:'',
    }
  },
  methods: {
    getAllPositions() {
      this.axios.get('/positions?page=' + this.currentPage + '&type=1').then(response => {
        this.positions = response.data.data

        this.positions.map(item => {
          const province = CodeToText[item['province']];
          const city = CodeToText[item['city']];
          const area = CodeToText[item['area']];
          item['detailed_address']=province+'-'+city+'-'+area+'-'+item['detailed_address'];
          if (item['salary']){
            item['salary']='面议';
          }else{
            if (item['salary_type']=='0'){
              item['salary']=item['salary_max']+' - '+item['salary_min']+'  月薪'
            }else{
              item['salary']=item['salary_max']+' - '+item['salary_min']+'  年薪'
            }

          }
        })

        this.meta.total = response.data.total
        this.meta.per_page = response.data.per_page
        this.meta.current_page = response.data.current_page
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllPositions()
    },
    handleEdit(index,id){
      this.centerDialogVisible=true;
      this.a_id=id;
    },
    approval() {
      this.axios.post(`/positionsApproval`, {a_id:this.a_id,approval:this.radio,reason:this.desc}).then(response => {
        console.log(response)
        if (response.data.code){
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        }else{
          this.$message({
            message: response.data.msg,
            type: 'error',
            duration: 1000,
          })
        }
      })

    },

  },
  created() {
    this.getAllPositions()
  }
}
</script>

<style scoped>
/deep/ .el-form-item {
  display: block !important;
}

/deep/ .el-form-item__label {
  width: 150px !important;
  color: #99a9bf !important;
}
</style>
