<template>
  <div id="roadshowForm">
    <el-card class="box-card">
      <div slot="header">
        <span>添加路演会议</span>
      </div>
      <el-form :model="detail" ref="detail" :rules="rules" label-width="100px" label-position="left">
        <div style="margin-bottom: 20px;">
          <span style="font-weight: bold;">基本信息</span>
        </div>
        <div style="margin-left: 15px;">
          <el-form-item label="活动名称" prop="title">
            <el-input v-model="detail.title"></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="date">
            <div class="block">
              <el-date-picker
                  v-model="detail.date"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['08:00:00','17:00:00']"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="行业领域" prop="industry_id">
            <el-select v-model="detail.industry_id" placeholder="请选择所属行业领域" style="width: 100%;">
              <el-option v-for="item in industry_title" :label="item.name" :value="item.id"
                         :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动形式" prop="mode">
            <el-select v-model="detail.mode" placeholder="请选择">
              <el-option
                  v-for="item in modeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="活动地址">
            <el-cascader
                v-model="detail.area"
                :options="city"
                :props="{expandTrigger: 'hover' }"
                clearable
                ref="elCascadeArea"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="detail.address" placeholder="请填写详细活动地址"></el-input>
          </el-form-item>

          <el-form-item label="活动主办方" prop="sub_organizer">
            <el-select
                v-model="detail.sub_organizer"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择活动主办方，可自定义添加，输入后按回车保存">
              <el-option
                  v-for="item in subOrganizer"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="活动封面图" prop="img_url">
            <el-upload
                class="avatar-uploader"
                :action="upload.host"
                :data="upload.formData"
                :show-file-list="false"
                :on-change="(file) => {uploadChange(file,'img_url')}"
                :auto-upload="false">
              <div class="upload-file">
                <img v-if="detail.img_url" :src="detail.img_url" class="avatar" alt="roadshow img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="首页banner" prop="is_banner">
            <el-radio v-model="detail.is_banner" :label="0">不添加</el-radio>
            <el-radio v-model="detail.is_banner" :label="1">添加</el-radio>
          </el-form-item>
          <el-form-item label="banner图" prop="banner_img" v-show="detail.is_banner === 1">
            <el-upload
                class="avatar-uploader"
                :action="upload.host"
                :data="upload.formData"
                :show-file-list="false"
                :on-change="(file) => {uploadChange(file,'banner_img')}"
                :auto-upload="false">
              <div class="upload-file">
                <img v-if="detail.banner_img" :src="detail.banner_img" class="avatar" alt="banner img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="观看人次" prop="view_count">
            <el-input v-model="detail.view_count"></el-input>
          </el-form-item>
          <el-form-item label="会议简介" prop="content">
            <tinymce upload_dir="roadshow/" v-model="detail.content" ref="editor" :height="300"></tinymce>
          </el-form-item>
        </div>


        <div style="margin-bottom: 20px;">
          <span style="font-weight: bold;">会议信息</span>
          <span style="float: right;color: #409EFF;cursor: pointer" @click="meetingHandleClick('','add')">添加会议</span>
        </div>
        <div style="margin-left: 15px;">
          <el-table
              :key="meetingTableKey"
              :data="detail.meeting"
              border
              style="width: 100%;margin-top: 10px;">
            <el-table-column prop="subject" label="会议名称"></el-table-column>
            <el-table-column prop="create_meeting" label="是否开启线上会议" width="180">
              <template slot-scope="scope">
                {{ scope.row.create_meeting === 1 ? '开启' : '关闭' }}
              </template>
            </el-table-column>
            <el-table-column prop="is_live" label="是否开启直播" width="180">
              <template slot-scope="scope">
                {{ scope.row.is_live === 1 ? '开启' : '关闭' }}
              </template>
            </el-table-column>
            <el-table-column prop="date" label="会议时间" width="350">
              <template slot-scope="scope">
                {{ scope.row.date[0] + ' ~ ' + scope.row.date[1] }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="meetingHandleClick(scope.row,'edit')" type="text" size="small">编辑</el-button>
                <el-button @click="meetingHandleClick(scope.row,'delete')" type="text" size="small">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>


        <el-form-item style="margin-top: 20px;">
          <el-button type="primary" @click="submit()" :loading="subStatus" :disabled="subStatus">保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog title="会议信息" :visible.sync="meetingDialog" :close-on-click-modal="false">
      <el-form :model="meetingDialogData" label-width="180px" :rules="meetingDialogDataRule" ref="meetingDialog">
        <el-form-item label="会议时间" prop="date">
          <el-date-picker
              v-model="meetingDialogData.date"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['08:00:00','17:00:00']"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <el-tooltip placement="top" style="margin-left: 10px;">
            <div slot="content">
              如果开启线上会议，会议开始时间必须大于当前时间且会议时长不可超过24小时
            </div>
            <i class="el-icon-info" style="color: #237BCD"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="会议主题" prop="subject">
          <el-input v-model="meetingDialogData.subject" placeholder="请填写当前会议主题（会议名称）"></el-input>
        </el-form-item>
        <el-form-item label="是否开启线上会议" prop="create_meeting">
          <el-radio-group v-model="meetingDialogData.create_meeting" @change="create_meeting">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item label="是否开启直播" prop="is_live">
          <el-radio v-model="meetingDialogData.is_live" :label="1">开启</el-radio>
          <el-radio v-model="meetingDialogData.is_live" :label="0">关闭</el-radio>
        </el-form-item>
        <el-form-item label="会议封面图" prop="main_img">
          <el-upload
              class="avatar-uploader"
              :action="upload.host"
              :data="upload.formData"
              :show-file-list="false"
              :on-change="(file) => {uploadChange(file,'main_img')}"
              :auto-upload="false">
            <div class="upload-file">
              <img v-if="meetingDialogData.main_img" :src="meetingDialogData.main_img" class="avatar"
                   alt="meeting main img">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
            <el-input v-model="meetingDialogData.main_img" placeholder="会议封面图链接"></el-input>
        </el-form-item>
        <div v-if="meetingDialogData.create_meeting === 1">
          <el-form-item label="选择项目" prop="project">
            <el-select value-key="id" multiple filterable v-model="meetingDialogData.project" placeholder="请选择项目"
                       style="width: 100%;">
              <el-option v-for="item in projectList" :label="item.name" :value="item"
                         :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择会议主持人" prop="host">
            <el-select
                v-model="meetingDialogData.host"
                filterable
                remote
                reserve-keyword
                placeholder="请输入ID或绑定手机号或绑定邮箱查找用户"
                :remote-method="searchUsers">
              <el-option
                  v-for="item in userList"
                  :key="item.uid"
                  :label="item.username"
                  :value="item.uid">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择项目主讲人" prop="presenter">
            <el-select
                v-model="meetingDialogData.presenter"
                filterable
                remote
                multiple
                reserve-keyword
                placeholder="请输入ID或绑定手机号或绑定邮箱查找用户"
                :remote-method="searchUsers">
              <el-option
                  v-for="item in userList"
                  :key="item.uid"
                  :label="item.username"
                  :value="item.uid">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择会议评委" prop="judges">
            <el-select
                v-model="meetingDialogData.judges"
                filterable
                remote
                multiple
                reserve-keyword
                placeholder="请输入ID或绑定手机号或绑定邮箱查找用户"
                :remote-method="searchUsers">
              <el-option
                  v-for="item in userList"
                  :key="item.uid"
                  :label="item.username"
                  :value="item.uid">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="会议回放地址" prop="record_url">
          <el-input v-model="meetingDialogData.record_url"
                    placeholder="请填写会议回放地址（会议开启直播会自动生成回放地址，未开启则需要手动填入）"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="meetingDialog = false">取 消</el-button>
        <el-button type="primary" @click="meetingSave">确 定</el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>

import city from '../../assets/citys.json'
import tinymce from "../tinymce";
import uploadFileToAliOss from "@/utils/uploadFileToAliOss";

export default {
  components: {tinymce},
  name: "roadshowForm",
  data() {
    let hostValidate = (rule, value, callback) => {
      if (this.meetingDialogData.create_meeting === 1) {
        if (!value) {
          callback(new Error('请选择线上会议室的主持人'));
        }
      }
      callback();
    };
    let createMeetingValidate = (rule, value, callback) => {
      if (this.meetingDialogData.create_meeting === 1) {
        if (value.length < 1) {
          let msg = '请选择讲解的项目';
          if (rule.field === 'presenter') {
            msg = '请选择线上会议室的项目主讲人';
          }
          if (rule.field === 'judges') {
            msg = '请选择线上会议室的评委';
          }
          callback(new Error(msg));
        }
      }
      callback();
    };
    let record_urlValidate = (rule, value, callback) => {
      if (this.meetingDialogData.create_meeting === 0 && this.meetingDialogData.is_live === 0) {
        if (value.length < 1) {
          callback(new Error('请填写会议视频回放地址'));
        }
      }
      callback();
    };
    let bannerValidate = (rule, value, callback) => {
      if (this.detail.is_banner === 1) {
        if (!value) {
          callback(new Error('请上传banner图'));
        }
      }
      callback();
    };
    return {
      meetingTableKey: true,//删除表格中内容时，刷新表格内容
      subStatus: false,
      meetingDialog: false,
      upload: {
        host: '',
        formData: {}
      },
      uploadData: {
        img_url: '',
        main_img: [],
        banner_img: '',
      },
      meetingTable: [],
      detail: {
        name: '',
        date: '',
        content: '',
        img_url: '',
        banner_img: '',
        meeting: [],
        is_banner: 0,
        sub_organizer: [],
        view_count: 0,
      },
      meetingDialogData: {
        project: [],
      },
      rules: {
        title: [{required: true, message: '请填写活动名称', trigger: ['blur', 'change']}],
        date: [{required: true, message: '请选择活动时间', trigger: 'blur'}],
        industry_id: [{required: true, message: '请选择行业领域', trigger: 'change'}],
        mode: [{required: true, message: '请选择活动形式', trigger: 'blur'}],
        img_url: [{required: true, message: '请上传活动封面图', trigger: 'change'}],
        banner_img: [{validator: bannerValidate, trigger: 'blur'}],
        content: [{required: true, message: '请填写会议简介', trigger: 'blur'}],
      },
      meetingDialogDataRule: {
        date: [{required: true, message: '请选择会议时间', trigger: 'blur'}],
        subject: [{required: true, message: '请填写会议名称', trigger: 'blur'}],
        create_meeting: [{required: true, message: '请选择是否开启线上会议室功能', trigger: 'change'}],
        is_live: [{required: true, message: '请选择是否开启直播功能', trigger: 'change'}],
        main_img: [{required: true, message: '请上传会议封面图', trigger: 'change'}],
        project: [{validator: createMeetingValidate, trigger: ['blur', 'change']}],
        host: [{validator: hostValidate, trigger: ['blur', 'change']}],
        presenter: [{validator: createMeetingValidate, trigger: ['blur', 'change']}],
        judges: [{validator: createMeetingValidate, trigger: ['blur', 'change']}],
        record_url: [{validator: record_urlValidate, trigger: 'blur'}],
      },
      city: [],//省市区
      industry_title: [],//专业领域
      projectList: [],
      userList: [],
      modeList: [
        {
          value: 1,
          label: '线下'
        },
        {
          value: 2,
          label: '线上'
        },
        // {
        //   value: '3',
        //   label: '线上线下同步'
        // },
      ],
      subOrganizer: [{value: 1, label: '聚英创集团'}, {value: 2, label: '聚英创科技'}, {value: 3, label: '环球英才交流促进会'}, {
        value: 4,
        label: '环球英才网'
      }],
    }
  },
  methods: {
    create_meeting(val) {
      if (val === 1) {
        let date = this.meetingDialogData.date;
        if (!date) {
          this.$message.error('请选择会议时间，如果开启线上会议，会议开始时间必须大于当前时间，会议时长不可超过24小时');
          this.meetingDialogData.create_meeting = 0;
          return;
        }
        let now_time = (new Date()).getTime();
        let start_time = (new Date(date[0])).getTime();
        if (start_time < now_time) {
          this.$message.error('开启线上会议，会议开始时间必须大于当前时间');
          this.meetingDialogData.create_meeting = 0;
          return;
        }
        let end_time = (new Date(date[1])).getTime();
        if (end_time - start_time > 24*60*60*1000) {
          this.$message.error('开启线上会议，会议时长不可超过24小时');
          this.meetingDialogData.create_meeting = 0;
          return;
        }
      }
    },
    getDetail() {
      let that = this;
      let param = that.$route.query;
      if (param.id) {
        let id = param.id;
        this.axios({
          url: '/roadshow/detail?id=' + id,
          method: 'get',
        }).then(function (response) {
          if (response.data.code === 200) {
            let data = response.data.data.detail;
            if (data.meeting && data.meeting.length > 0) {
              data.meeting.forEach((v, k) => {
                v.index = k;
              });
            }
            that.detail = data;
          }

        });
      }

    },
    async submit() {

      let that = this;
      let validateFlag = false;
      that.$refs.detail.validate((valid) => {
        if (valid) {
          validateFlag = true;

        }
      });
      if (!validateFlag) {
        return false;
      }
      //上传文件
      if (that.uploadData.img_url instanceof File) {
        that.detail.img_url = await uploadFileToAliOss.uploadFile(that.uploadData.img_url, 'roadshow/');
        that.uploadData.img_url = '';
      }
      if (that.uploadData.banner_img instanceof File) {
        that.detail.banner_img = await uploadFileToAliOss.uploadFile(that.uploadData.banner_img, 'roadshow/');
        that.uploadData.banner_img = '';
      }
      if (that.detail.meeting) {
        for (let i in that.detail.meeting) {
          if (that.detail.meeting[i].main_img_file instanceof File) {
            that.detail.meeting[i].main_img = await uploadFileToAliOss.uploadFile(that.detail.meeting[i].main_img_file, 'roadshow/');
            that.detail.meeting[i].main_img_file = '';
          }
        }
      }
      this.axios({
        method: 'post',
        data: that.detail,
        url: '/roadshow/saveData',
      }).then(function (response) {
        let type = 'success';
        if (response.data.code !== 200) {
          type = 'error';
        }
        that.$message({
          message: response.data.msg,
          type: type,
          duration: 1000,
          onClose: () => {
            if (response.data.code === 200) {
              that.$router.push('/roadshow');
            }
          }
        })
      });


    },
    searchUsers(query) {
      if (query !== '') {
        this.loading = true;
        let that = this;
        setTimeout(function () {
          that.axios({
            method: 'get',
            url: '/users/userSearch?search=' + query,
            loading: false
          }).then(function (response) {
            that.loading = false;
            that.userList = response.data.data.list;
          });
        }, 200);
      } else {
        this.userList = [];
      }
    },
    getProjectList() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/projectlib/selectList',
        loading: false,
      }).then(response => {
        if (response.data.code === 200) {
          that.projectList = response.data.data.list;
        }
      });
    },
    getUserList() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/users/userSearch',
        loading: false,
      }).then(response => {
        if (response.data.code === 200) {
          that.userList = response.data.data.list;
        }
      });
    },
    meetingHandleClick(row, type) {
      let init = {
        subject: '',
        date: '',
        create_meeting: 0,
        is_live: 0,
        main_img: '',
        main_img_file: '',//待上传文件，提交表单时，需要将文件移如到this.uploadData中上传
        project: [],
        host: '',//主持人
        judges: [],//评委
        presenter: [],//主讲人
        record_url: '',
        index: '',
        is_add: false,
        id: '',
      };
      if (type === 'add') {
        init.index = this.detail.meeting.length;
        init.is_add = true;
        this.meetingDialogData = init;
        this.meetingDialog = true;
      } else if (type === 'edit') {
        let meeting = init;
        for (let i in meeting) {
          meeting[i] = this.detail.meeting[row.index][i];
        }
        meeting.is_add = false;
        this.meetingDialogData = meeting;
        this.meetingDialog = true;
      } else if (type === 'delete') {
        let index = row.index;
        this.detail.meeting.splice(index, 1);
        for (let i in this.detail.meeting) {
          this.detail.meeting[i].index = i;
        }
        this.meetingTableKey = !this.meetingTableKey;
      }
    },
    meetingSave() {
      let that = this;
      this.$refs.meetingDialog.validate((valid) => {
        if (valid) {
          let index = that.meetingDialogData.index || that.meetingDialogData.index === 0 ? that.meetingDialogData.index : that.detail.meeting.length;
          let init = {
            subject: that.meetingDialogData.subject,
            date: that.meetingDialogData.date,
            create_meeting: that.meetingDialogData.create_meeting,
            is_live: that.meetingDialogData.is_live,
            main_img: that.meetingDialogData.main_img,
            main_img_file: that.meetingDialogData.main_img_file,
            project: that.meetingDialogData.project,
            host: that.meetingDialogData.host,//主持人
            judges: that.meetingDialogData.judges,//评委
            presenter: that.meetingDialogData.presenter,//主讲人
            record_url: that.meetingDialogData.record_url,
            index: index,
            is_add: that.meetingDialogData.is_add,
            id: that.meetingDialogData.id,
          };
          if (that.meetingDialogData.is_add) {
            that.detail.meeting.push(init);
          } else {
            that.detail.meeting[index] = init;
          }
          this.meetingTableKey = !this.meetingTableKey;
          this.meetingDialog = false;
          this.$refs.meetingDialog.resetFields();
        } else {
          console.log('表单验证失败');
        }
      });
    },


    uploadChange(file, type) {
      if (!/.(jpg|jpeg|png)$/.test(file.name)) {
        this.$message.error('请上传格式为jpg、jpeg、png的图片文件');
        return '';
      }
      if (file.size > 5242880) {
        this.$message.error('请上传文件大小不超过5MB的图片');
        return;
      }
      let url = '';
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      }
      if (type === 'img_url' || type === 'banner_img') {
        this.detail[type] = url;
        this.uploadData[type] = file.raw;
      } else if (type === 'main_img') {//会议图片
        this.meetingDialogData.main_img = url;
        this.meetingDialogData.main_img_file = file.raw;
        this.$forceUpdate();
      }
    },
    getIndustryTitle() {
      let that = this;
      this.axios({
        method: 'get',
        url: '/project/type',
        loading: false,
      }).then(response => {
        if (response.data.code === 200) {
          that.industry_title = response.data.data.list;
        }
      });
    },
  },
  mounted() {
  },
  created() {
    this.city = [...city]
    this.getIndustryTitle();
    this.getProjectList();
    this.getDetail();
    // uploadFileToAliOss.webDirectOss('test/');
  }
}
</script>
<style>
</style>