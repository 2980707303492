<template>
    <div>
        <el-card class="box-card">
            <div slot="header">
                <span>添加标签</span>
            </div>
            <div class="news-form">
                <el-form :model="ruleForm" :rules="rules" label-width="100px" label-position="left">
                    <el-form-item label="单位福利" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="活动区域" prop="type">-->
<!--                        <el-select v-model="ruleForm.type" placeholder="请选择标签类型">-->
<!--                            <el-option label="单位福利" value="0"></el-option>-->
<!--                            <el-option label="职位类别" value="1"></el-option>-->
<!--                            <el-option label="工作经验" value="2"></el-option>-->
<!--                            <el-option label="学历要求" value="3"></el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item>
                        <el-button type="primary" @click="submit('ruleForm')">发布</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>



    export default {
        name: "labelAdd",
        data() {
            return {
                ruleForm: {
                    title: '',
                    type: 0
                },
                rules: {
                    title: [{required: true, message: '请输入标题', trigger: 'blur'}],
                    // type: [{required: true, message: '请输入活动区域', trigger: 'change'}],
                }
            }
        },
        methods: {
            submit() {

                // this.$refs[formName].validate((valid) => {
                //     if (valid) {
                        this.sendHttpRequest(this.ruleForm)
                //     } else {
                //         return false;
                //     }
                // });
            },
            sendHttpRequest(data) {
                this.axios.post('/label', {data}).then(response => {
                    if (response.data) {
                        this.$message({
                            message: '创建成功',
                            type: 'success',
                            onClose: () => {
                                this.$router.go(0);
                            }
                        });
                    }
                })

            },
        },

        created() {

        },

    }
</script>

