<template>
  <div class="container">
    <div class="login-info">
      后台登录
    </div>
    <div class="login-form">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-ruleForm">
        <el-form-item prop="username">
          <el-input prefix-icon="iconfont icon-xingmingyonghumingnicheng
" v-model="ruleForm.username" autofocus></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input prefix-icon="iconfont icon-mima"
                    type="password" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        ruleForm: {
          username: '',
          password: ''
        },
        rules: {
          username: [
            {required: true, message: '请输入用户名', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'}
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.login()
          } else {
            return false;
          }
        });
      },
      login() {
        this.axios.post('/login', {
          name: this.ruleForm.username,
          password: this.ruleForm.password
        }).then(response => {
          if (response.data.code === 1004) {
            this.$message({
              message: response.data.msg,
              type: 'error'
            });
          } else {
            this.$cookies.set('token', response.data.access_token, response.data.expires_in);
            this.$message({
              message: '登录成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.$router.push('/');
              }
            });
          }
        })
      }
    }
  }
</script>

<style scoped>
  .container {
    width: 100%;
    height: 100vh;
    background-color: RGB(42, 58, 74);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-info {
    margin-bottom: 30px;
    color: #ffffff;
  }

  .login-form {
    width: 450px;
  }

  .el-form-item {
    margin-bottom: 35px;
  }

  .login-ruleForm {
    width: 100%;
  }

  .login-form > .login-ruleForm input {
    background-color: red !important;
  }

  .el-button {
    width: 100%;
  }

  /deep/ .iconfont {
    color: RGB(39, 48, 69);
  }
</style>
