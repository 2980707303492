<template>
  <el-row>
    <el-table :data="news" style="width: 100%" >
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column align="center" prop="title" label="标题" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="ip_area" label="ip" width="200" min-width="100" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" prop="tags" label="分类"  width="300" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-for="(tag,index) in scope.row['tag_name']" :key="index">{{tag}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="审核状态" width="200" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.approval=='0'">未审核</div>
          <div v-else>审核失败</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="time" label="发布时间" width="170" min-width="150" show-overflow-tooltip></el-table-column>

      <el-table-column align="center" label="操作"  width="250" min-width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-more" circle
                     @click="showDetailNews(scope.$index,scope.row.id)" v-if="scope.row.approval=='0'"></el-button>
          <el-button type="primary" icon="el-icon-edit" circle
                     @click="editNews(scope.$index, scope.row.id)"></el-button>

        </template>
      </el-table-column>

    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="perPage"
                   :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
    </el-pagination>
    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-radio v-model="radio" label="1">通过</el-radio>
      <el-radio v-model="radio" label="2">拒绝</el-radio>
      <div style="margin-top: 10px">
        <el-input type="textarea" v-model="desc" v-if="radio==2"  placeholder="请输入拒绝理由"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="approval">确 定</el-button>
  </span>
    </el-dialog>
  </el-row>

</template>

<script>


export default {
  name: "NewsApproval",
  data() {
    return {
      news: [],
      currentPage: 0,
      perPage: 10,
      total: 0,
      centerDialogVisible: false,
      a_id:'',
      radio:'',
      desc:'',
    }
  },
  methods: {
    getAllNews() {
      this.axios.get(`/newsApproval?type=2&page=${this.currentPage}`).then(response => {
        const data = response.data.data
        data.map(item => {
          item['type'] = '资讯'
        })

        this.news = data
        this.currentPage = response.data.current_page;
        this.perPage = response.data.per_page;
        this.total = response.data.total;
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getAllNews()
    },
    deleteNews(index, id) {
      this.$confirm(`是否确定删除该${this.title}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/news/' + id).then(response => {
          let type = 'success';
          if (response.data.code !== 1) {
            type = 'error';
          }
          this.$message({
            message: response.data.msg,
            type: type,
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      });
    },
    showDetailNews(index, id) {
      this.centerDialogVisible=true;
      this.a_id=id;
    },
    editNews(index, id) {
      this.$router.push({
        path: '/news/approval_see',
        query: {
          id
        }
      })
    },
    approval(){
      this.axios.post(`/newsApproval`, {a_id:this.a_id,approval:this.radio,reason:this.desc}).then(response => {
        console.log(response)
        if (response.data.code){
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        }else{
          this.$message({
            message: response.data.msg,
            type: 'error',
            duration: 1000,
          })
        }
      })
    },
  },
  created() {
    this.getAllNews()
  },
}
</script>

<style scoped>
.el-tag {
  margin-right: 10px;
}
</style>
