<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>发送邮件</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left"
               class="demo-ruleForm">
        <el-form-item label="邮件主题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>

        <el-form-item label="邮件内容" prop="content">
          <div id="editor" class="editor"></div>
        </el-form-item>
        <el-form-item label="邮件接收人" prop="recipient">
          <el-input v-model="ruleForm.recipient"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <div>
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
    </div>

  </div>
</template>

<script>
import E from "wangeditor";
    export default {
        name: "UserEmail",

        data() {
          var validatePass = (rule, value, callback) => {
              var regEmail=/^([a-zA-Z]|[0-9])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
              if (value === '') {
                callback(new Error('请输入邮箱'));
              } else {
                if (!regEmail.test(value)) {
                  callback(new Error('请输入合法的邮箱'))
                }else{
                  callback();
                }
              }
          };
            return {
                ruleForm: {
                    title: '',
                    content: '',
                   recipient:''
                },
                rules: {
                    title: [
                        {required: true, message: '请输入邮件主题', trigger: 'blur'},
                    ],
                  content: [
                        {required: true, message: '请输入邮件内容', trigger: 'blur'},
                    ],
                  recipient:[
                        {validator: validatePass, trigger: 'blur'},
                   ]
                },
              editorObj:''
            }
        },
        methods: {
            submitForm(formName) {
              this.ruleForm.content=this.editorObj.txt.html();
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.sendHttpRequest();
                    } else {
                        return false;
                    }
                });
            },

            sendHttpRequest() {
              this.axios.post('/mail', {
                data: this.ruleForm
              }).then(response => {
                this.$message({
                  message: response.data.msg,
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.ruleForm.recipient='';
                    this.ruleForm.content='';
                    this.ruleForm.title='';
                    this.editorObj.txt.html('');
                  }
                })
              })

            }
        },
      mounted() {
        const editor = new E('#editor')
        editor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/editor/image'
        editor.config.uploadFileName = 'file'
        this.editorObj = editor
        editor.create()
        editor.txt.html(this.ruleForm.content)
      }
    }
</script>

<style scoped>
  .box-card {
    margin-bottom: 30px;
  }
</style>
