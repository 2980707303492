<template>
    <div>
        <p class="xs-title">路演列表</p>
        <el-table :data="roadshow" style="width: 100%">
            <el-table-column prop="title" label="活动名称" width="500" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="industry" label="所属领域" width="200"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="start_time" label="活动时间" width="200"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="报名情况" width="150">
                <template v-slot="scope">
                    <el-button type="text" @click="showSignUpInfo(scope.$index, scope.row.id)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="推流地址" width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="push_url( scope.row.id)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
                    <el-button size="mini" @click="statistics( scope.row.id, scope.row.title)">流量数据</el-button>
                    <el-button size="mini" type="danger" @click="deleteProject(scope.$index, scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="limit"
                       :current-page="currentPage" :hide-on-single-page="true" @current-change="getCurrentData">
        </el-pagination>

        <el-dialog title="推流地址" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
            <el-table :data="gridData">
                <el-table-column property="subject" label="直播/视频标题" width="150"></el-table-column>
                <el-table-column property="start_time" label="直播开始时间" width="150">
                    <template v-slot="scope">
                        {{formattedDate(scope.row.start_time)}}
                    </template>
                </el-table-column>
                <el-table-column property="push_url" label="推流地址"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="echarts_title" :visible.sync="statisticsVisible" :fullscreen="true"
                   @close="echartsIntervalClose = !echartsIntervalClose">
            <echarts-activity :type="2" :id="echarts_id" :interval-close="echartsIntervalClose"></echarts-activity>
        </el-dialog>
    </div>
</template>

<script>
import EchartsActivity from "@/components/statistics/activity.vue";

export default {
    name: "roadshow",
    components: {EchartsActivity},
    data() {
        return {
            dialogTableVisible: false,
            gridData: {},
            search: {
                name: '',
                industry_id: '',
                follow_status: '',
                stage: 0,
                source: '',
                is_expert: '',
                site: '',
                status: '',
            },
            roadshow: [],
            total: 0,
            currentPage: 1,
            limit: 10,
            industry_title: [],//专业领域

            statisticsVisible: false,
            echarts_id: 0,
            echarts_title: '',
            echartsIntervalClose: false,
        }
    },
    methods: {
        statistics(id, title) {
            this.echarts_id = id;
            this.echarts_title = title + ' 实时数据统计(分钟)';
            this.statisticsVisible = true;
        },
        formattedDate (timestamp) {
            const date = new Date(timestamp * 1000);
            // 格式化日期
            const formatter = new Intl.DateTimeFormat('zh-CN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
            return formatter.format(date);
        },
        handleEdit(id) {
            this.$router.push({
                path: `roadshow/form?id=${id}`,
            });
        },
        deleteProject(index, id) {
            let that = this;
            this.axios({
                url: '/roadshow/delete',
                data: {id: id},
                method: 'post',
            }).then(function (response) {
                if (response.data.code === 200) {
                    that.getList();
                }
            });
        },
        push_url(roadshow_id) {
            this.axios.get(`/roadshow/getPushUrl?id=${roadshow_id}`).then(response => {
                if (response.data.code === 200) {
                    this.gridData = response.data.data
                    this.dialogTableVisible = true
                } else {
                    this.$message({
                        message: response.data.msg,
                        type: 'error',
                        duration: 2000
                    })
                }
            })
        },

        getList() {
            this.axios.get('/roadshow?page=' + this.currentPage +
                '&limit=' + this.limit
            ).then(response => {
                this.roadshow = response.data.data.list;
                this.total = response.data.data.count;
            })
        },
        getCurrentData(val) {
            this.currentPage = val;
            this.getList()
        },
        banner(row) {
            if (!row.banner_img) {
                this.$message.error('当前活动未上传banner图，请先上传banner图后再进行操作。');
                row.is_banner = row.is_banner === 1 ? 0 : 1;
                return;
            }
            this.axios({
                url: '/roadshow/bannerStatus',
                data: {id: row.id, status: row.is_banner},
                method: 'post',
            }).then(function (response) {
                if (response.data.code !== 200) {
                    this.$message.error(response.data.msg);
                }
            });

        },
        // 查看报名情况
        showSignUpInfo(index, id) {
            this.axios.get('/roadshow/sign_count?id=' + id).then(response => {
                if (response.data === 0) {
                    this.$message({
                        message: '该活动暂无报名信息',
                        type: 'success',
                        duration: 2000
                    })
                } else {
                    this.$router.push({
                        path: 'roadshow/sign-up',
                        query: {
                            id
                        }
                    })
                }
            })
        },
    },
    created() {
        this.getList();
    }
}
</script>

<style>

</style>
