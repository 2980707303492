<template>
  <el-row>
    <p class="xs-title">{{title}}</p>
    <div>

      <el-select v-model="project" filterable placeholder="请选择" @change="getAllType" v-if="typeId==2 || typeId==11 || typeId==14">
        <el-option
            v-for="item in project_data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="tag" filterable placeholder="请选择" @change="getAllTag" v-if="typeId==7">
        <el-option
            v-for="item in tag_data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>


      <el-select v-model="select_id" filterable placeholder="请选择">
        <el-option
            v-for="item in select_data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-button @click="getPosition"  v-if="typeId==2 || typeId==11 || typeId==14">查看</el-button>
      <el-button @click="getAll">添加</el-button>
    </div>
    <el-table :data="data" row-key="id">
      <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>

      <el-table-column align="center" prop="name" label="名称" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">

          <el-button type="danger" icon="el-icon-delete" circle @click="deleteNews(scope.row.id)"></el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-dialog
        title="职位详情"
        :visible.sync="dialogVisible"
        width="30%">

      <el-descriptions title="" :column="1">
        <el-row>
          <el-col :span="6">
              职位名称
          </el-col>
          <el-col :span="18">
            {{position_data.job_name}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            工作职责
          </el-col>
          <el-col :span="18">
            {{position_data.describe}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            任职要求
          </el-col>
          <el-col :span="18">
            {{position_data.requirements}}
          </el-col>
        </el-row>
      </el-descriptions>

    </el-dialog>
  </el-row>


</template>

<script>

import Sortable from 'sortablejs';
export default {
  name: "commonRecommend",
  data() {
    return {
      data: [],
      select_data:[],
      select_id:'',
      project:'',
      project_data:{},
      position_data:{},
      dialogVisible:false,
      tag_data:{},
      tag:'',
    }
  },
  methods: {
    tag_type(){
      this.axios.get(`/recommend/tag`).then(response => {
        this.tag_data = response.data
      })
    },
    getAllTag(){
      this.axios.get(`/recommend/type?type=${this.typeId}&tag=${this.tag}`).then(response => {
        this.select_data = response.data
      })
    },
    project_type() {
      this.axios.get(`/recommend/project`).then(response => {
        this.project_data = response.data
      })
    },
    getAllNews() {
      this.axios.get(`/recommend?type=${this.typeId}`).then(response => {
        this.data = response.data
      })
    },
    getPosition(){

      if (this.select_id){
        this.axios.get(`/recommend/position?type=${this.select_id}`).then(response => {
          console.log(response);
          this.position_data=response.data;
          this.dialogVisible=true;

        })
      }else{
        this.$message({
          message: `请选择${this.title}`,
          type: 'error',
          duration: 1000,
          onClose: () => {
          }
        });
      }


    },
    deleteNews(id) {
      this.$confirm(`是否确定删除该${this.title}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get('/recommendDel?id=' + id).then(response => {
          let type = 'success';
          if (response.data.code !== 1) {
            type = 'error';
          }
          console.log(type);
          this.$message({
            message: response.data.msg,
            type: type,
            duration: 1000,
            onClose: () => {
              this.$router.go(0);
            }
          })
        })
      });
    },
    getAll(){
      if (this.select_id){
        this.axios.post('/recommend', {
          p_id:this.select_id,
          type:this.typeId
        }).then(response => {
          let type = 'success';
          if (response.data.code !== 1) {
            type = 'error';
          }
          this.$message({
            message: response.data.msg,
            type: type,
            duration: 1000,
            onClose: () => {
              this.getAllNews();
              this.getAllType()
            }
          })
        })
      }else{
        this.$message({
          message: `请选择${this.title}`,
          type: 'error',
          duration: 1000,
          onClose: () => {
          }
        });
      }

    },
    getAllType(){
      this.axios.get(`/recommend/type?type=${this.typeId}&project=${this.project}`).then(response => {
        this.select_data = response.data
      })
    },
    rowDrop () {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector('.el-table__body-wrapper tbody');
      console.log(tbody);
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.data.splice(oldIndex, 1)[0];
          _this.data.splice(newIndex, 0, currRow);
          _this.handleSee_c();
        }
      });
    },
    handleSee_c(){
      this.axios.post('/recommend/recommend_data',this.data).then(response => {
        console.log(response);
        this.$message({
          message: response.data.msg,
          type: 'success',
          duration: 1000,

        })
      })
    }
  },
  created() {
    this.getAllNews();
    this.getAllType();
    this.project_type();
    this.tag_type();
  },
  props: {
    typeId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.rowDrop();
  },
}
</script>

<style scoped>
.el-select{
  width: 400px;
}
</style>
