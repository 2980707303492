const professionalTypes = [
  {
    label: '工科类',
    name: '工科类',
    children: [
      {
        label: '电气工程类',
        name: '电气工程类'
      },
      {
        label: '电气信息类',
        name: '电气信息类'
      },
      {
        label: '地矿类',
        name: '地矿类'
      },
      {
        label: '材料类',
        name: '材料类'
      },
      {
        label: '机械类',
        name: '机械类'
      },
      {
        label: '仪器仪表类',
        name: '仪器仪表类'
      },
      {
        label: '能源动力类',
        name: '能源动力类'
      },
      {
        label: '土建类',
        name: '土建类'
      },
      {
        label: '水利类',
        name: '水利类'
      },
      {
        label: '测绘类',
        name: '测绘类'
      },
      {
        label: '环境与安全类',
        name: '环境与安全类'
      },
      {
        label: '化工与制药类',
        name: '化工与制药类'
      },
      {
        label: '交通运输类',
        name: '交通运输类'
      },
      {
        label: '海洋工程类',
        name: '海洋工程类'
      },
      {
        label: '轻工纺织食品类',
        name: '轻工纺织食品类'
      },
      {
        label: '航空航天类',
        name: '航空航天类'
      },
      {
        label: '武器类',
        name: '武器类'
      },
      {
        label: '工程力学类',
        name: '工程力学类'
      },
      {
        label: '生物工程类',
        name: '生物工程类'
      },
      {
        label: '农业工程类',
        name: '农业工程类'
      },
      {
        label: '林业工程类',
        name: '林业工程类'
      },
      {
        label: '公安技术类',
        name: '公安技术类'
      }
    ]
  },
  {
    label: '文学类',
    name: '文学类',
    children: [
      {
        label: '中国语言文学类',
        name: '中国语言文学类'
      },
      {
        label: '外国语言文学类',
        name: '外国语言文学类'
      },
      {
        label: '新闻传播学类',
        name: '新闻传播学类'
      },
      {
        label: '艺术类',
        name: '艺术类'
      },
    ]
  },
  {
    label: '理科类',
    name: '理科类',
    children: [
      {
        label: '数学类',
      },
      {
        label: '物理学类',
        name: '物理学类'
      },
      {
        label: '化学类',
        name: '化学类'
      },
      {
        label: '生物科学类',
        name: '生物科学类'
      },
      {
        label: '天文学类',
        name: '天文学类'
      },
      {
        label: '地质学类',
        name: '地质学类'
      },
      {
        label: '地理科学类',
        name: '地理科学类'
      },
      {
        label: '地球物理学类',
        name: '地球物理学类'
      },
      {
        label: '大气科学类',
        name: '大气科学类'
      },
      {
        label: '海洋科学类',
        name: '海洋科学类'
      },
      {
        label: '力学类',
        name: '力学类'
      },
      {
        label: '电子信息科学类',
        name: '电子信息科学类'
      },
      {
        label: '材料科学类',
        name: '材料科学类'
      },
      {
        label: '环境科学类',
        name: '环境科学类'
      },
      {
        label: '心理学类',
        name: '心理学类'
      },
      {
        label: '统计学类',
        name: '统计学类'
      }
    ]
  },
  {
    label: '农学类',
    name: '农学类',
    children: [
      {
        label: '植物生产类',
        name: '植物生产类'
      },
      {
        label: '草业科学类',
        name: '草业科学类'
      },
      {
        label: '森林资源类',
        name: '森林资源类'
      },
      {
        label: '环境生态类',
        name: '环境生态类'
      },
      {
        label: '动物生产类',
        name: '动物生产类'
      },
      {
        label: '动物医学类',
        name: '动物医学类'
      },
      {
        label: '水产类',
        name: '水产类'
      },
    ]
  },
  {
    label: '医学类',
    name: '医学类',
    children: [
      {
        label: '基础医学类',
        name: '基础医学类'
      },
      {
        label: '预防医学类',
        name: '预防医学类'
      },
      {
        label: '临床医学与医学技术类',
        name: '临床医学与医学技术类'
      },
      {
        label: '口腔医学类',
        name: '口腔医学类'
      },
      {
        label: '中医学类',
        name: '中医学类'
      },
      {
        label: '法医学类',
        name: '法医学类'
      },
      {
        label: '护理学类',
        name: '护理学类'
      },
      {
        label: '药学类',
        name: '药学类'
      }
    ]
  },
  {
    label: '管理学',
    name: '管理学',
    children: [
      {
        label: '管理科学与工程类',
        name: '管理科学与工程类'
      },
      {
        label: '工商管理类',
        name: '工商管理类'
      },
      {
        label: '公共管理类',
        name: '公共管理类'
      },
      {
        label: '农业经济管理类',
        name: '农业经济管理类'
      },
      {
        label: '图书档案学类',
        name: '图书档案学类'
      }
    ]
  },
  {
    label: '法学类',
    name: '法学类',
    children: [
      {
        label: '法学类',
        name: '法学类'
      },
      {
        label: '马克思主义理论类',
        name: '马克思主义理论类'
      },
      {
        label: '社会学类',
        name: '社会学类'
      },
      {
        label: '政治学类',
        name: '政治学类'
      },
      {
        label: '公安学类',
        name: '公安学类'
      },
    ]
  },
  {
    label: '其他类',
    name: '其他类',
    children: [
      {
        label: '哲学类',
        name: '哲学类'
      },
      {
        label: '经济学类',
        name: '经济学类'
      },
      {
        label: '教育学类',
        name: '教育学类'
      },
      {
        label: '体育学类',
        name: '体育学类'
      },
      {
        label: '历史学类',
        name: '历史学类'
      },
      {
        label: '未划分专业',
        name: '未划分专业'
      },
      {
        label: '其他类',
        name: '其他类'
      },
    ]
  }
]

const gender = [
  {
    value: 'MALE(男)',
    label: '男'
  },
  {
    value: 'FEMALE(女)',
    label: '女'
  }
]

const degree = [
  {
    label: '本科',
    value: 'BACHELOR(学士)'
  },
  {
    label: '硕士',
    value: 'MASTER(硕士)'
  },
  {
    label: '博士',
    value: 'DOCTOR(博士)'
  }
]

const projectTypes = [
  {
    label: '芯片制造 Chip Manufacturing',
    value: '芯片制造 Chip Manufacturing'
  },
  {
    label: '电子硬件 Electronic hardware',
    value: '电子硬件 Electronic hardware'
  },
  {
    label: '信息软件 Information Software',
    value: '信息软件 Information Software'
  },
  {
    label: '互联网+ Internet Plus Model',
    value: '互联网+ Internet Plus Model'
  },
  {
    label: '人工智能 Artificial Intelligence',
    value: '人工智能 Artificial Intelligence'
  },
  {
    label: '虚拟现实 Virtual Reality',
    value: '虚拟现实 Virtual Reality'
  },
  {
    label: '大数据 Big Data',
    value: '大数据 Big Data'
  },
  {
    label: '电子商务 Electronic Commerce',
    value: '电子商务 Electronic Commerce'
  },
  {
    label: '生物技术 Biotechnology',
    value: '生物技术 Biotechnology'
  },
  {
    label: '医药制药 Pharmaceuticals',
    value: '医药制药 Pharmaceuticals'
  },
  {
    label: '医疗器械 Medical Apparatus and Instruments',
    value: '医疗器械 Medical Apparatus and Instruments'
  },
  {
    label: '文化创意 Cultural Creativity',
    value: '文化创意 Cultural Creativity'
  },
  {
    label: '新材料 New Material',
    value: '新材料 New Material'
  },
  {
    label: '新能源 New Energy',
    value: '新能源 New Energy'
  },
  {
    label: '环保技术 Environmental Protection Technology',
    value: '环保技术 Environmental Protection Technology'
  },
  {
    label: '商务管理 Commercial Management',
    value: '商务管理 Commercial Management'
  },
  {
    label: '先进制造 Manufacturing Industry',
    value: '先进制造 Manufacturing Industry'
  },
  {
    label: '现代农业 Modern Agriculture',
    value: '现代农业 Modern Agriculture'
  },
  {
    label: '现代服务业 Modern Service Industry',
    value: '现代服务业 Modern Service Industry'
  },
]

const projectFields = [
  {
    label: '能源·资源·环境',
    value: '能源·资源·环境'
  },
  {
    label: '信息科技',
    value: '信息科技'
  },
  {
    label: '生物医药',
    value: '生物医药'
  },
  {
    label: '工程制造',
    value: '工程制造'
  },
  {
    label: '化学化工',
    value: '化学化工'
  },
  {
    label: '现代农业',
    value: '现代农业'
  },
  {
    label: '教育文化',
    value: '教育文化'
  },
  {
    label: '经济·金融·管理',
    value: '经济·金融·管理'
  }
]

const projectOwner = [
  {
    label: '本人 Myself',
    value: '本人 Myself'
  },
  {
    label: '合伙 Partnership',
    value: '合伙 Partnership'
  },
  {
    label: '机构 Institution',
    value: '机构 Institution'
  },
]

const projectStage = [
  {
    label: '科研开发阶段 Research and development stage',
    value: '科研开发阶段 Research and development stage'
  },
  {
    label: '项目孵化阶段 Project incubation stage',
    value: '项目孵化阶段 Project incubation stage'
  },
  {
    label: '中试阶段 Pilot test stage',
    value: '中试阶段 Pilot test stage'
  },
  {
    label: '产业化阶段 Industrialization stage',
    value: '产业化阶段 Industrialization stage'
  }
]

export {
  professionalTypes,
  gender,
  degree,
  projectTypes,
  projectFields,
  projectOwner,
  projectStage
}
