<template>
  <div>
    <el-card>
      <div slot="header">
        <span>标签列表</span>
      </div>
      <el-table :data="tags" style="width: 100%;margin-bottom: 20px;" row-key="value" border default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="label" label="标签名称"></el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column label="前端录入展示">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.level"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="recommend(scope.row.value)"
                :active-value="1"
                :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="最后一次更新时间"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" circle @click="updateTagDialog(scope.row)"></el-button>
            <el-button type="danger" icon="el-icon-delete" circle @click="deleteTagDialog(scope.row.value)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog title="修改标签" :visible.sync="updateTagDialogVisible">
      <el-form :model="updateTag" :rules="updateTagRule" ref="updateTag" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标签名称" prop="tag_name">
          <el-input v-model="updateTag.tag_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateTagSubmit('updateTag')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    name: "CommonTags",
    data() {
      return {
        tags: [],
        news_type: null,
        updateTagDialogVisible: false,
        updateTag: {},
        updateTagRule: {
          tag_name: {required: true, message: '请输入标签名称', trigger: 'blur'}
        }
      }
    },
    methods: {
      recommend(id){
        this.axios.get('/tags/recommend/' + id).then(response => {
          console.log(response);
          // let type = 'success';
          // if (response.data.code !== 1) {
          //   type = 'error';
          // }
          // this.$message({
          //   message: response.data.msg,
          //   type: type,
          //   duration: 1000,
          //   onClose: () => {
          //     this.$router.go(0);
          //   }
          // })
        })
      },
      async getAllTags() {
        const {data} = await this.axios.get(`/tags?type=${this.news_type}&floor=3`)
        this.tags = Object.values(data)
      },
      updateTagDialog(tag) {
        this.updateTagDialogVisible = true
        this.updateTag = tag
      },
      deleteTagDialog(id) {
        this.$confirm('是否确定删除该标签?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteTagHttp(id)
        }).catch(() => {
          return true
        });
      },
      async deleteTagHttp(id) {
        const {data} = await this.axios.delete(`/tags/${id}?type=${this.news_type}`)
        let type = 'success'
        if (data['status'] === 0) {
          type = 'error'
        }
        this.$message({
          type: type,
          message: data['message'],
          onClose: () => {
            this.$router.go(0)
          }
        })
      },
      updateTagSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.updateTagHttp(this.updateTag)
          } else {
            return false
          }
        })
      },
      async updateTagHttp(tag) {
        console.log(tag);
        const {data} = await this.axios.patch(`/tags/${tag['value']}`,tag)
        let type = 'success'
        if (data['status'] === 0) {
          type = 'error'
        }
        this.$message({
          type: type,
          message: data['message'],
          onClose: () => {
            this.updateTagDialogVisible = false
            this.$router.go(0)
          }
        })
      }

    },
    created() {
      this.news_type = this.newsType
      this.getAllTags()
    },
    props: {
      newsType: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
