<template>
  <div>
    <p class="xs-title">
      征集情况
      <span style="position: absolute;right:0;margin-right: 20px;">
            <el-button @click="exportUserSignUp()">数据导出
            </el-button>
            </span>
    </p>
    <el-table :data="userActivitiesInfo.data" style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="60"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="contact" label="联系方式"></el-table-column>
      <el-table-column prop="mail" label="邮箱"></el-table-column>
      <el-table-column prop="project_name" label="项目名称"></el-table-column>
      <el-table-column prop="project_bp" label="项目文件名称"></el-table-column>
      <el-table-column label="操作">

        <template slot-scope="scope">
          <el-button @click="preview(scope.$index, scope.row)">
          在线预览
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="userActivitiesInfo.total"
                   :page-size="userActivitiesInfo.per_page"
                   :current-page="userActivitiesInfo.current_page" :hide-on-single-page="true"
                   @current-change="getCurrentData">
    </el-pagination>


    <el-dialog title="发送签到提醒短信" :visible.sync="signSMSDialog" :close-on-click-modal="false" width="750px">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <el-form :model="signSMSDialogData" label-width="100px" :rules="signSMSDialogDataRule" ref="venueDialog"
                 style="width: 500px;">
          <el-form-item label="会议时间" prop="venue_name">
            <el-date-picker
                style="width: 100%;"
                v-model="signSMSDialogData.time"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="会议主题" prop="venue_name">
            <el-input v-model="signSMSDialogData.title" placeholder="请填写会议主题"></el-input>
          </el-form-item>
          <el-form-item label="会议地点" prop="venue_name">
            <el-autocomplete
                style="width: 100%;"
                v-model="signSMSDialogData.address"
                :fetch-suggestions="querySearch"
                placeholder="请填写会议地点"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="签到时间" prop="venue_name">
            <el-time-select
                style="width: 100%;"
                v-model="signSMSDialogData.signtime"
                :picker-options="{
                                    start: '06:00',
                                    step: '00:15',
                                    end: '22:00'
                                  }"
                placeholder="选择时间">
            </el-time-select>
          </el-form-item>
        </el-form>
        <div style="width: 155px;">
          【环球英才】您已成功报名{{ signSMSDialogData.time }}举行的{{ signSMSDialogData.title }}，
          地点：{{ signSMSDialogData.address }}，签到时间：{{ signSMSDialogData.signtime }}，
          请带上您的签到码并准时参加活动，我们期待您的到来！有问题请致电：010-68942688
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="signSMSDialog = false">取 消</el-button>
        <el-button type="primary" @click="sendSignSMS">发送短信</el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>


export default {
  name: "SignUp",
  data() {
    return {
      num: 0,
      userActivitiesInfo: {},
      currentPage: 1,
      fapi: null,
      rule: [],
      option: null,
      signSMSDialog: false,
      signSMSDialogData: {},
      signSMSDialogDataRule: {},
      restaurants: [
        {value: '中关村大街22号中科大厦A座一层“聚英会堂”'},
        {value: '中关村大街22号中科大厦A座12层'},
      ],
    }
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    sendSignSMS() {
      const activityId = this.$route.query.id;
      let that = this;
      that.axios.post('/activities/sign-up-info/sendSms', {
        id: activityId,
        sms_param: that.signSMSDialogData
      }).then(response => {
        let type = 'success'
        if (response['data']['code'] === 0) {
          type = 'error'
        }
        that.$message({
          message: response['data']['msg'],
          type: type,
          duration: 2000,
        })
        that.signSMSDialog = false;
      });
    },
    signSMS() {
      const activityId = this.$route.query.id;
      let that = this;
      //获取当前活动信息
      this.axios.get('/activities/' + activityId).then(response => {
        let signtime = that.reduceDateTime(response.data.sub_start_time);
        that.signSMSDialogData = {
          time: response.data.sub_start_time,
          title: response.data.sub_title,
          address: response.data.sub_address,
          signtime: signtime,
        };
        that.signSMSDialog = true;
      })
    },
    reduceDateTime(dateStr, ttl) {
      ttl = ttl ? ttl : 0 - 1800 * 1000;
      let dt = new Date(dateStr.replace(/-/, "/"));
      let ndt = new Date(dt.getTime() + ttl);
      return parseInt(ndt.getHours()) + ':' + parseInt(ndt.getMinutes());
    },
    exportUserSignUp() {
      const activityId = this.$route.query.id;
      window.location.href = process.env.VUE_APP_BASE_URL + '/export/collect?id=' + activityId +'&type=1';
    },
    getActivitySignUpInfo() {
      const activityId = this.$route.query.id;
      this.axios.get('/roadshow/collect?id=' + activityId + '?page=' + this.currentPage).then(response => {
        this.userActivitiesInfo = response.data.data
      })
    },
    getCurrentData(val) {
      this.currentPage = val;
      this.getActivitySignUpInfo()
    },

    handleDelete(index, id) {
      let type = 'success'
      this.axios.delete('/activities/sign-up-info/' + id).then(response => {
        if (response['data']['code'] === 0) {
          type = 'error'
        }
        this.$message({
          message: response['data']['msg'],
          type: type,
          duration: 2000,
          onClose: () => {
            this.$router.go(0);
          }
        })
      })
    },
    handleAudit(index, id, status) {
      let that = this;
      for (const key in that.$refs) {
        if (that.$refs[key] === undefined) {
          continue;
        }
        if (key.indexOf('popover-') !== -1 && id !== key) {
          that.$refs[key].doClose();
        }
      }
      that.axios.post('/activities/sign-up-info/audit', {id: id, status: status}).then(response => {
        if (response.data.code === 1) {
          if (that.userActivitiesInfo.data[index].id === id) {
            that.userActivitiesInfo.data[index].status = status;
          } else {
            for (let i in that.userActivitiesInfo.data) {
              if (that.userActivitiesInfo.data[i].id === id) {
                that.userActivitiesInfo.data[i].status = status;
              }
            }
          }
        }
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      });
      this.getActivitySignUpInfo();

    },
    preview(index,data){
      let flieArr = data.project_url.split('.');
      let suffix = flieArr[flieArr.length - 1];
      console.log(suffix);
      if (suffix=='pdf'){
        window.open(data.project_url,'_blank');
      }else{
        window.open('https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent(data.project_url),'_blank');
      }

    }

  },
  created() {
    this.getActivitySignUpInfo()
  }
}
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
  display: flex;
  flex-direction: column;
}

/deep/ .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
