<template>
  <div>
    <el-row :gutter="12">
      <el-col>
        <el-card shadow="always">
          编辑项目
        </el-card>
      </el-col>
    </el-row>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="left"
             class="demo-ruleForm">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>个人信息</span>
        </div>

        <el-form-item label="姓名" prop="name">
          <div class="xs-position-name">
            <el-input placeholder="请输入姓名" v-model="ruleForm.name"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="性别" prop="gender">
          <el-select v-model="ruleForm.gender" placeholder="请选择">
            <el-option v-for="item in gender" :value="item.value" :key="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="个人免冠照" prop="nameicon">
          <el-upload
            class="avatar-uploader"
            :action="uploadImageUrl"
            :show-file-list="false"
            :on-success="uploadImageSuccess"
          >
            <img v-if="ruleForm.nameicon" :src="ruleForm.nameicon" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="身份证/护照号" prop="passportno">
          <div class="xs-position-name">
            <el-input placeholder="请输入身份证/护照号" v-model="ruleForm.passportno"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="出生日期" prop="dateofbirth">
          <div class="block">
            <el-date-picker
              v-model="ruleForm.dateofbirth"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="国籍" prop="nationality">
          <div class="xs-position-name">
            <el-input placeholder="请输入国籍" v-model="ruleForm.nationality"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="出生地" prop="birthplace">
          <div class="xs-position-name">
            <el-input placeholder="请输入出生地" v-model="ruleForm.birthplace"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="毕业院校" prop="graduateschool">
          <div class="xs-position-name">
            <el-input placeholder="请输入毕业院校" v-model="ruleForm.graduateschool"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="最高学位" prop="highestdegree">
          <el-select v-model="ruleForm.highestdegree" placeholder="请选择">
            <el-option v-for="item in degree" :label="item.label" :key="item.value" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="专业领域" prop="professionalfield">
          <div class="block">
            <el-cascader
              v-model="ruleForm.professionalfield"
              :options="ProfessionalTypes"
              :props="{ expandTrigger: 'hover' }"
            ></el-cascader>
          </div>
        </el-form-item>

        <el-form-item label="工作年限" prop="workinglife">
          <div class="xs-position-name">
            <el-input placeholder="请输入工作年限" v-model="ruleForm.workinglife"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="现居住国" prop="country">
          <div class="xs-position-name">
            <el-input placeholder="请输入现居住国" v-model="ruleForm.country"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="现住址" prop="address">
          <div class="xs-position-name">
            <el-input placeholder="请输入现住址" v-model="ruleForm.address"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="手机号" prop="telephone">
          <div class="xs-position-name">
            <el-input placeholder="请输入手机号" v-model="ruleForm.telephone"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="微信" prop="wechat">
          <div class="xs-position-name">
            <el-input placeholder="请输入微信" v-model="ruleForm.wechat"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="电子邮箱" prop="email">
          <div class="xs-position-name">
            <el-input placeholder="请输入电子邮箱" v-model="ruleForm.email"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="备用邮箱">
          <div class="xs-position-name">
            <el-input placeholder="请输入备用邮箱" v-model="ruleForm.sparemailbox"></el-input>
          </div>
        </el-form-item>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>个人履历</span>
        </div>
        <el-form-item label="学习或工作经历" prop="resume">
          <el-table :data="ruleForm.resume" style="width: 100%">
            <el-table-column align="center" prop="date" label="起止年月">
              <template scope="scope">
                <el-input size="small" v-model="scope.row.time" placeholder="请输入内容"
                          @change="handleEdit(scope.$index, scope.row)"></el-input>
                <span>{{scope.row.date}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="name" label="学习或工作单位">
              <template scope="scope">
                <el-input size="small" v-model="scope.row.company" placeholder="请输入内容"
                          @change="handleEdit(scope.$index, scope.row)"></el-input>
                <span>{{scope.row.date}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="address" label="学习专业或从事行业">
              <template scope="scope">
                <el-input size="small" v-model="scope.row.date" placeholder="请输入内容"
                          @change="handleEdit(scope.$index, scope.row)"></el-input>
                <span>{{scope.row.date}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="address" label="学位或职务">
              <template scope="scope">
                <el-input size="small" v-model="scope.row.name" placeholder="请输入内容"
                          @change="handleEdit(scope.$index, scope.row)"></el-input>
                <span>{{scope.row.date}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="address" label="操作">
              <template scope="scope">
                <el-button type="primary" icon="el-icon-circle-close" @click="deleteResume(scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button class="add-resume" type="primary" @click="addResume">添加履历</el-button>
        </el-form-item>

        <el-form-item label="工作经历和业绩" prop="previouswork">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="ruleForm.previouswork">
          </el-input>
        </el-form-item>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>团队成员</span>
        </div>
        <el-card class="box-card" v-for="(teamItem,index) in ruleForm.team" :key="index">

          <i class="el-icon-circle-close" @click="removeTeamNumber(index)"></i>

          <el-form-item label="姓名" class="xs-form-item">
            <div class="xs-position-name">
              <el-input placeholder="请输入姓名" v-model="teamItem.name"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="性别">
            <el-select v-model="teamItem.gender" placeholder="请选择">
              <el-option v-for="item in gender" :value="teamItem.gender" :key="item.value"
                         :label="item.label"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="个人免冠照">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="uploadImageUrl"
              :on-success="response => uploadImageSuccessInTeamNumber(response,index)"
            >
              <img v-if="teamItem.usericon" :src="teamItem.usericon" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="身份证/护照号">
            <div class="xs-position-name">
              <el-input placeholder="请输入身份证/护照号" v-model="teamItem.passportno"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="出生日期">
            <div class="block">
              <el-date-picker
                v-model="teamItem.dateofbirth"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="国籍">
            <div class="xs-position-name">
              <el-input placeholder="请输入国籍" v-model="teamItem.nationality"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="出生地">
            <div class="xs-position-name">
              <el-input placeholder="请输入出生地" v-model="teamItem.birthplace"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="毕业院校">
            <div class="xs-position-name">
              <el-input placeholder="请输入毕业院校" v-model="teamItem.graduateschool"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="最高学位">
            <el-select v-model="teamItem.highestdegree" placeholder="请选择">
              <el-option v-for="item in degree" :label="item.label" :key="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="专业领域">
            <div class="block">
              <el-cascader
                v-model="teamItem.professionalfield"
                :options="ProfessionalTypes"
                :props="{ expandTrigger: 'hover' }"
              ></el-cascader>
            </div>
          </el-form-item>

          <el-form-item label="工作年限">
            <div class="xs-position-name">
              <el-input placeholder="请输入工作年限" v-model="teamItem.workinglife"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="现居住国">
            <div class="xs-position-name">
              <el-input placeholder="请输入现居住国" v-model="teamItem.country"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="现住址">
            <div class="xs-position-name">
              <el-input placeholder="请输入现住址" v-model="teamItem.address"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="手机号">
            <div class="xs-position-name">
              <el-input placeholder="请输入手机号" v-model="teamItem.telephone"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="微信">
            <div class="xs-position-name">
              <el-input placeholder="请输入微信" v-model="teamItem.wechat"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="电子邮箱">
            <div class="xs-position-name">
              <el-input placeholder="请输入电子邮箱" v-model="teamItem.email"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="团队角色">
            <div class="xs-position-name">
              <el-input placeholder="请输入团队角色" v-model="teamItem.workinglife"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="在团队中担任的主要工作">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入内容"
              v-model="teamItem.mwork">
            </el-input>
          </el-form-item>
        </el-card>

        <el-button class="add-resume" type="primary" @click="addTeamNumber">添加成员</el-button>

      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>项目概述</span>
        </div>

        <el-form-item label="项目名称">
          <div class="xs-position-name">
            <el-input placeholder="请输入项目名称" v-model="ruleForm.projectname"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="项目领域">
          <el-select v-model="ruleForm.industry" placeholder="请选择">
            <el-option v-for="item in projectFields" :value="item.value" :key="item.value"
                       :label="item.label"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="项目摘要">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="ruleForm.projectdesc.projectdesc">
          </el-input>
        </el-form-item>

        <el-form-item label="融资要求">
          <div>
            <span>融资金额(元)</span>
            <el-input placeholder="请输入" v-model="ruleForm.projectdesc.financingamount"></el-input>
          </div>
          <div>
            <span>项目预期总投入(元)</span>
            <el-input placeholder="请输入" v-model="ruleForm.projectdesc.projectexpected"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="项目类别">
          <el-select v-model="ruleForm.projectdesc.projectcategoryRadio" placeholder="请选择">
            <el-option
              v-for="item in projectTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="成果持有者">
          <el-select v-model="ruleForm.projectdesc.achievementholder" placeholder="请选择">
            <el-option
              v-for="item in projectOwner"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="专利情况">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="ruleForm.projectdesc.patentstatus">
          </el-input>
        </el-form-item>

        <el-form-item label="项目所处阶段">
          <el-select v-model="ruleForm.projectdesc.development" placeholder="请选择">
            <el-option
              v-for="item in projectStage"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="项目路演PPT">
          <el-upload style="width: 300px"
                     :limit='1'
                     :action="uploadURL.ppt"
                     :show-file-list="false"
                     :on-success="uploadPPTSuccess"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <a v-if="ruleForm.ppt" :href="ruleForm.ppt" target="_blank" class="el-button el-button--primary el-button--small xs-preview">预览</a>
          </el-upload>
        </el-form-item>

        <el-form-item label="融资商业计划书">
          <el-upload style="width: 300px"
                     :action="uploadURL.word"
                     :limit='1'
                     :show-file-list="false"
                     :on-success="uploadWordSuccess"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <a v-if="ruleForm.word" :href="ruleForm.word" target="_blank" class="el-button el-button--primary el-button--small xs-preview">预览</a>
          </el-upload>
        </el-form-item>

      </el-card>
    </el-form>

    <div>
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      <el-button type="info" @click="addDraft('ruleForm')">添加草稿</el-button>
    </div>
  </div>
</template>

<script>
    import {
        degree,
        gender,
        professionalTypes,
        projectTypes,
        projectFields,
        projectOwner,
        projectStage
    } from "../../data/data";

    export default {
        name: "ProjectEdit",
        data() {
            return {
                ruleForm: {
                    name: '',
                    gender: '',
                    nameicon: '',
                    passportno: '',
                    dateofbirth: '',
                    nationality: '',
                    birthplace: '',
                    graduateschool: '',
                    highestdegree: '',
                    workinglife: '',
                    country: '',
                    address: '',
                    telephone: '',
                    wechat: '',
                    email: '',
                    sparemailbox: '',
                    resume: [],
                    previouswork: '',
                    projectname: '',
                    industry: '',
                    professionalfield: '',
                    team: '',
                    projectdesc: '',
                    ppt: '',
                    word: ''
                },
                rules: {
                    name: [
                        {required: true, message: '请输入个人姓名', trigger: 'blur'},
                    ],
                    gender: [
                        {required: true, message: '请选择性别', trigger: 'change'}
                    ],
                    nameicon: [
                        {required: true, message: '请输入个人照片', trigger: 'change'}
                    ],
                    passportno: [
                        {required: true, message: '请输入身份证或护照', trigger: 'blur'}
                    ],
                    dateofbirth: [
                        {required: true, message: '请选择出生日期', trigger: 'change'}
                    ],
                    nationality: [
                        {required: true, message: '请输入国籍', trigger: 'blur'}
                    ],
                    birthplace: [
                        {required: true, message: '请输入出生地', trigger: 'blur'}
                    ],
                    graduateschool: [
                        {required: true, message: '请输入毕业院校', trigger: 'blur'}
                    ],
                    highestdegree: [
                        {required: true, message: '请选择最高学历', trigger: 'change'}
                    ],
                    professionalfield: [
                        {required: true, message: '请选择专业领域', trigger: 'change'}
                    ],
                    workinglife: [
                        {required: true, message: '请输入工作年限', trigger: 'blur'}
                    ],
                    country: [
                        {required: true, message: '请输入现居住国', trigger: 'blur'}
                    ],
                    address: [
                        {required: true, message: '请输入现住址', trigger: 'blur'}
                    ],
                    telephone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'}
                    ],
                    wechat: [
                        {required: true, message: '请输入微信', trigger: 'blur'}
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'blur'}
                    ],
                    resume: [
                        {required: true, message: '请输入履历', trigger: 'blur'}
                    ],
                    previouswork: [
                        {required: true, message: '请输入工作经历和业绩', trigger: 'blur'}
                    ],
                },
                industryFields: [
                    {
                        id: 1,
                        name: '能源·资源·环境'
                    },
                    {
                        id: 2,
                        name: '信息科技'
                    },
                    {
                        id: 3,
                        name: '生物医药'
                    },
                    {
                        id: 4,
                        name: '工程制造'
                    },
                    {
                        id: 5,
                        name: '化学化工'
                    },
                    {
                        id: 6,
                        name: '现代农业'
                    },
                    {
                        id: 7,
                        name: '教育文化'
                    },
                    {
                        id: 8,
                        name: '经济·金融·管理'
                    }
                ],
                gender: gender,
                ProfessionalTypes: professionalTypes,
                degree: degree,
                projectTypes: projectTypes,
                projectFields: projectFields,
                projectOwner: projectOwner,
                projectStage: projectStage,
                uploadImageUrl: '',
                baseUrl: '',
                uploadURL: {
                    ppt: '',
                    word: ''
                }
            }
        },
        methods: {
            addResume() {
                this.ruleForm['resume'].push([])
            },

            changeStatus() {
                const obj = document.getElementById('salaryStatus')
                obj.blur()
                if (this.ruleForm.salary_status == '0') {
                    this.ruleForm.salary_status = '1'
                } else {
                    this.ruleForm.salary_status = '0'
                }
            },

            handleInput(type) {
                this.ruleForm[type] = this.ruleForm[type].replace(/[^\d]/g, '')
            },

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                  if (valid) {
                    this.sendHttpRequest(1);
                  } else {
                    return false;
                  }
                });
            },

            addDraft(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.sendHttpRequest(0);
                    } else {
                        return false;
                    }
                });
            },

            sendHttpRequest(status) {
                const id = this.$route.query.id;
                this.ruleForm.status = status;
                this.ruleForm['uid'] = id;

                this.axios.patch(`/projects/${id}`, {
                    data: this.ruleForm
                }).then(response => {
                    let type = 'success';
                    if (response.data.code === 0) {
                        type = 'error';
                    }
                    this.$message({
                        message: response.data.msg,
                        type: type,
                        duration: 1000,
                        onClose: () => {
                            this.$router.push('/positions');
                        }
                    })
                })
            },

            getProjectInfo() {
                const id = this.$route.query.id;
                this.axios.get('/projects/' + id).then(response => {
                    let resume = response.data.resume.split('。')
                    let resumeData = []

                    for (let i = 0; i < resume.length; i++) {
                        if (resume[i] !== '') {
                            let item = {}

                            let resumeItem = resume[i].split(',')
                            item['time'] = resumeItem[0]
                            item['company'] = resumeItem[1]
                            item['name'] = resumeItem[2]

                            resumeData.push(item)
                        }
                    }
                    this.ruleForm = response.data
                    this.ruleForm['resume'] = resumeData
                    this.ruleForm['team'] = JSON.parse(response.data['team'])
                    this.ruleForm['projectdesc'] = JSON.parse(response.data['projectdesc'])

                })
            },

            deleteResume(index) {
                this.ruleForm.resume.splice(index, 1)
            },

            addTeamNumber() {
                this.ruleForm['team'].push([])
            },

            removeTeamNumber(index) {
                this.ruleForm['team'].splice(index, 1)
            },

            uploadImageSuccess(response) {
                this.ruleForm.nameicon = response
            },

            uploadImageSuccessInTeamNumber(response, index) {
                this.ruleForm.team[index]['usericon'] = response
            },

            uploadPPTSuccess(response) {
                this.ruleForm.ppt = response
            },

            uploadWordSuccess(response) {
                this.ruleForm.word = response
            },

        },
        created() {
            this.getProjectInfo()
            this.baseUrl = process.env.VUE_APP_BASE_URL
            this.uploadImageUrl = this.baseUrl + '/project/image'
            this.uploadURL.ppt = this.baseUrl + '/project/ppt'
            this.uploadURL.word = this.baseUrl + '/project/word'
        }
    }
</script>

<style scoped lang="less">
  .el-row {
    margin-bottom: 20px;
  }

  .xs-position-name {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      margin-right: 30px;
    }

    .xs-salary {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      div:last-child {
        margin-left: 30px;
      }
    }
  }

  .box-card {
    margin-bottom: 30px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .xs-form-item {
    margin-right: 50px;
  }

  .xs-preview {
    display: inline-block;
    width: 80px;
    text-decoration: none;
  }
</style>

<style scoped>

  .add-resume {
    margin-top: 30px;
  }

  .el-icon-circle-close {
    cursor: pointer;
    font-size: 30px;
    float: right;
  }

  .el-icon-circle-close:hover {
    cursor: pointer;
  }
</style>
