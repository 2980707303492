<template>
  <div>
    <el-card>
      <div slot="header">
        <span>{{title}}</span>
      </div>
      <el-form :model="tag" :rules="tagRules" ref="tag" label-width="100px">
        <el-form-item label="标签层级" prop="parentId">
          <el-row>
            <el-col :span="10">
              <el-cascader v-model="tag.parentId" :options="tagOptions"
                           :props="{ expandTrigger: 'hover',checkStrictly: true }" ref="elcascader" @change="cancelCascade"></el-cascader>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="名称" prop="name" width="300">
          <el-row>
            <el-col :span="10">
              <el-input v-model="tag.name"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addTag('tag')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>

  import {changeTagsToString} from "../../utils/tags";

  export default {
    name: "CommonTagsAdd",
    data() {
      return {
        tag: {
          parentId: [],
          name: '',
          news_type: null
        },
        tagRules: {
          parentId: {required: true, message: '请选择分类', trigger: 'change'},
          name: {required: true, message: '请输入标签名称', trigger: 'blur'}
        },
        tagOptions: [
          {
            label: '顶级分类',
            value: 0
          }
        ]
      }
    },
    methods: {
      addTag(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.tag['parentId'] = changeTagsToString(this.tag['parentId'])
            this.axios.post('/tags', {data: this.tag}).then(response => {
              if (response.data) {
                this.$message({
                  message: '创建成功',
                  type: 'success',
                  onClose: () => {
                    this.$router.push('/news/tags')
                  }
                })
              } else {
                this.$message({
                  message: '创建失败,请稍后重试或联系管理员',
                  type: 'error'
                })
              }
            })
          } else {
            return false
          }
        })
      },
      async getAllTags() {
        const {data} = await this.axios.get(`/tags?type=${this.tag['news_type']}&floor=2`)
        this.tagOptions.push(...Object.values(data))
      },
      cancelCascade() {
        this.$refs.elcascader.dropDownVisible = false
      }
    },
    created() {
      this.tag.news_type = this.newsType
      this.getAllTags()
    },
    props: {
      title: {
        type: String,
        required: true
      },
      newsType: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  .el-cascader {
    width: 100%;
  }
</style>

<style>

  .el-cascader-panel .el-radio {
    position: absolute;
    z-index: 10;
    padding: 0 10px;
    width: 132px;
    height: 34px;
    line-height: 34px;
  }

  .el-cascader-panel .el-radio__input {
    visibility: hidden;
  }

  .el-cascader-panel .el-input-node__postfix {
    top: 10px;
  }

</style>
