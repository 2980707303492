<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <span>添加公司类型</span>
      </div>
      <div class="news-form">
        <el-form :model="ruleForm" :rules="rules" label-width="100px" label-position="left">
          <el-form-item label="公司类型" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit('ruleForm')">发布</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>



export default {
  name: "labelAdd",
  data() {
    return {
      ruleForm: {
        title: '',
        type: 4
      },
      rules: {
        title: [{required: true, message: '请输入公司类型', trigger: 'blur'}],

      }
    }
  },
  methods: {
    submit() {


      this.sendHttpRequest(this.ruleForm)

    },
    sendHttpRequest(data) {
      this.axios.post('/label', {data}).then(response => {
        if (response.data) {
          this.$message({
            message: '创建成功',
            type: 'success',
            onClose: () => {
              this.$router.go(0);
            }
          });
        }
      })

    },
  },

  created() {

  },

}
</script>

