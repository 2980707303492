<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>修改文章</span>
    </div>
    <div class="news-form">
      <el-form :model="news" ref="news" :rules="rules" label-width="100px" label-position="left">
        <el-form-item label="标题" prop="title">
          <el-input v-model="news.title" maxlength="60" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="来源" prop="author"  v-if="news.type!=3">
          <el-input v-model="news.author"></el-input>
        </el-form-item>

        <el-form-item label="标签" prop="tag_id"  v-if="news.type!=3">
          <el-cascader
              v-model="news['tag_id']"
              :options="bindTags"
              :props="{expandTrigger: 'hover',checkStrictly: true }"
              clearable
              ref="elCascadeTag"
              @change="cancelCascadeTag"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="行业分类" prop="industry_type"  v-if="news.type!=3">
          <el-select v-model="news.industry_type" placeholder="请选择">
            <el-option
                v-for="item in industry"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="banner" prop="banner">
          <el-upload
              :action="uploadImageUrl"
              list-type="picture-card"
              :show-file-list="false"
              :on-preview="handlePictureCardPreview"
              :on-success="uploadImageSuccess">
            <img v-if="news.banner" :src="news.banner" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="top_banner" prop="top_banner">
          <el-upload
              :action="uploadImageUrl"
              list-type="picture-card"
              :show-file-list="false"
              :on-preview="handlePictureCardPreview"
              :on-success="topImageSuccess">
            <img v-if="news.top_banner" :src="news.top_banner" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="发布时间" prop="time">
          <el-date-picker v-model="news.time" type="datetime" placeholder="选择日期时间"
                          value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>

<!--        <el-form-item label="地区">-->
<!--          <el-cascader-->
<!--              v-model="news.area"-->
<!--              :options="cities"-->
<!--              :props="{ checkStrictly: true,expandTrigger: 'hover' }"-->
<!--              clearable-->
<!--              ref="elCascadeArea"-->
<!--              @change="cancelCascadeArea"-->
<!--          ></el-cascader>-->
<!--        </el-form-item>-->

        <el-form-item label="简介"  v-if="news.type!=3">
          <el-input type="textarea" :rows="5" v-model="news.desc"></el-input>
        </el-form-item>

        <el-form-item label="内容">
          <div id="editor" class="editor"></div>
        </el-form-item>

        <el-form-item label="相关项目">

          <el-select v-model="news.related_items" :multiple-limit="3" filterable multiple
                     placeholder="请选择相关项目，不填标题检索">
            <el-option
                v-for="item in items"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关文章">
          <el-select v-model="news.related_articles" :multiple-limit="5" filterable multiple
                     placeholder="请选择相关文章，不填标题检索">
            <el-option
                v-for="item in articles"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关专家关联">
          <el-switch
              v-model="news.switch_experts"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0">
          </el-switch>
        </el-form-item>

        <el-form-item label="相关专家" v-if="news.switch_experts">
          <el-select v-model="news.related_experts" :multiple-limit="2" filterable multiple
                     placeholder="请选择相关专家，不填标题检索">
            <el-option
                v-for="item in experts"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传PDF">
          <el-upload :action="uploadPDFUrl" :on-remove="handleRemove" :on-success="uploadPDFSuccess"
                     :file-list="fileList" :on-change="handleChange">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item label="是否发布">
          <el-switch v-model="news.status"></el-switch>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit('news')">更新{{ title }}</el-button>
        </el-form-item>

      </el-form>
    </div>
  </el-card>
</template>

<script>

import E from "wangeditor"
import cities from '../../assets/citys.json'



export default {
  name: "CommonEdit",
  data() {


    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
      news: {},
      editorObj: null,
      inputVisible: false,
      inputValue: '',
      newType: null,
      bindTags: [],
      cities: [],
      uploadPDFUrl: process.env.VUE_APP_BASE_URL + '/news/pdf',
      uploadImageUrl: process.env.VUE_APP_BASE_URL + '/image',
      fileList: [],
      rules: {
        title: {required: true, message: '请输入标题', trigger: 'blur'},
        author: {required: true, message: '请输入来源', trigger: 'blur'},
        tag_id: {required: true, message: '请选择标签', trigger: 'change'},
        banner: {required: true, message: '请选择banner图', trigger: 'change'},
        time:{required: true, message: '请选择发布时间', trigger: 'change'}
      },
      dialogImageUrl: '',
      dialogVisible: false,
      items: {},
      articles: {},
      experts: {},
      industry: {}
    }
  },
  methods: {
    getNews() {
      const id = this.$route.query.id
      this.axios.get('/news/' + id).then(response => {
        const data = response.data
        if (data.pdf) {
          this.fileList = [{'name': data.title, url: data.pdf}]
        }

        this.news = data
        // this.news['area'] = JSON.parse(data['area'])
        // if (data['tag_id']){
        //   const tagIdList = (data['tag_id']).split(',')
        //   const tagIdIem = tagIdList.map(item => {
        //     return item.split('-').map(items => {
        //       return items - 0;
        //     })
        //   })
        //   this.news['tag_id'] = tagIdIem[0]
        // }
        this.news['tag_id']=Number(this.news['tag_id']);
        if (!this.news['tag_id']){
          this.news['tag_id']='';
        }
        if (this.news['industry_type']) {
          this.news['industry_type'] = Number(this.news['industry_type']);
        }
        this.editorObj.txt.html(this.news.content)
      })
    },

    async getAllTagsHttp() {
      if (this.newType == 3) {
        const {data} = await this.axios.get(`/tags?type=2&floor=3`)
        this.bindTags = Object.values(data)
      } else {
        const {data} = await this.axios.get(`/tags?type=${this.newType}&floor=3`)
        this.bindTags = Object.values(data)
      }

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    related_type() {
      this.axios.get('/newsRelated').then(response => {
        console.log(response);
        this.items = response.data.items;
        this.articles = response.data.articles;
        this.experts = response.data.experts;
      })

    },
    uploadImageSuccess(response) {
      this.news.banner = response
    },
    topImageSuccess(response) {
      this.news.top_banner = response
    },
    submit(formName) {

      // this.news['area'] = JSON.stringify(this.news['area'])
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.news['tag_id']!='0'){
            this.news['tag_id'] = this.news['tag_id'][this.news['tag_id'].length-1]
          }
          this.news['content'] = this.editorObj.txt.html()
          this.sendHttpRequest(this.news)
        } else {
          return false;
        }
      });
    },

    sendHttpRequest(data) {
      const id = this.$route.query.id
      this.axios.patch('/news/' + id, {data}).then(response => {
        if (response.data) {
          let status = 'success'
          if (response.data['code'] === 0) {
            status = 'error'
          }
          this.$message({
            message: response.data['msg'],
            type: status,
            onClose: () => {
              if (this.news.type==3){
                this.$router.push('/news/topic');
              }else{
                this.$router.push('/news');
              }
            }
          });
        }
      })
    },

    handleRemove() {
      this.news.pdf = ''
    },

    uploadPDFSuccess(res) {
      this.news.pdf = res
    },

    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },

    cancelCascadeTag() {
      this.$refs.elCascadeTag.dropDownVisible = false
    },

    cancelCascadeArea() {
      this.$refs.elCascadeArea.dropDownVisible = false
    },
    projectType() {
      this.axios.get('/projectType').then(response => {
        this.industry = response.data
      })
    },
  },
  created() {
    this.newType = this.typeId
    this.getAllTagsHttp()
    this.getNews()
    this.related_type();
    this.projectType();
    this.cities = [...cities]
  },
  mounted() {
    const editor = new E('#editor')
    editor.config.uploadImgServer = process.env.VUE_APP_BASE_URL + '/news/image'
    editor.config.uploadFileName = 'file'

    this.editorObj = editor
    editor.create()
    editor.txt.html(this.news.content)
  },
  props: {
    title: {
      type: String,
      required: true
    },
    typeId: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-cascader {
  width: 100%;
}

</style>

<style>
.avatar {
  width: 100% !important;
  height: 100% !important;
}

.editor .w-e-text-container .w-e-text p {
  text-indent: 2em !important;
}

.el-picker-panel, .el-message-box__wrapper, .el-cascader__dropdown {
  z-index: 99999 !important;
}

.editor .w-e-text-container .w-e-text p {
  text-indent: 2em !important;
}

.el-cascader-panel .el-checkbox {
  position: absolute;
  z-index: 10;
  padding: 0 10px;
  width: 132px;
  height: 34px;
  line-height: 34px;
}

.el-cascader-panel .el-checkbox__input {
  visibility: hidden;
}

.el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}
.el-select{
  width:  100%;
}
.el-select-dropdown{
  width: 1000px;
}
.el-select__tags-text {
  display: inline-block;
  width: 180px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.el-tag__close.el-icon-close{
  margin-bottom: 12px;
}
</style>
